// ConsultationCardContainer.js

import React from "react";
import { Card, CardBody, Table } from 'reactstrap';
import { getDoctorNotes, getLaboratoryResult, getPrescription } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import LaboratoryTestResultList from "../../Appointment/LaboratoryTestResultList";

const ConsultationCardContainer = ({ appointment_id, patient_id, item }: any) => {

    const apiId = item || appointment_id;
    const pat_id = patient_id;
    const { data: prescription }: any = useQuery([`prescription${apiId}`, apiId], () => getPrescription(apiId), {
        keepPreviousData: true,
    })

    const { data: note }: any = useQuery([`note${apiId}`, apiId], () => getDoctorNotes(apiId), {
        keepPreviousData: true
    })



    const {
		data: labTestsResult,
		error,
		isLoading,
		isFetching,
	} = useQuery([`labTestsResult${apiId}`], () =>
		getLaboratoryResult(apiId)
	);

    return (
        <>

            <Card className="widget-card consultation-full-wrap">
                <CardBody className="widget-inner-wrap">
                    <div dangerouslySetInnerHTML={{ __html: note?.data?.[0]?.notes }}></div>
                </CardBody>
            </Card>
            <Card className="widget-card">
                <CardBody className="widget-inner-wrap">
                    <div className="widget-head-outer">
                        <div className='widget-heading'>
                            <small>Today's</small>
                            <h6>Appointments</h6>
                        </div>
                        <div className="icon-container">
                            <span>
                                <i className="icon xl icon-options"></i>
                            </span>
                        </div>
                    </div>

                    <div className="table-outer">

                        <Table striped>
                            <thead>
                                <tr>
                                    <th><b>Medicine</b></th>
                                    <th><b>Dosage</b></th>
                                    <th><b>Duration</b></th>
                                </tr>
                            </thead>
                            {prescription?.prescriptions?.map((item: any, index: any) =>
                            (<tbody>
                                <tr>
                                    <td>{item?.medicine}</td>
                                    <td>{item?.dosage}</td>
                                    <td>{item?.duration}</td>
                                </tr>


                            </tbody>))}
                        </Table>
                    </div>
                </CardBody>
            </Card>
            {labTestsResult?.[0]?.test_result && (
            <LaboratoryTestResultList
                patient_id={pat_id}
                appointment_id={
                    apiId
                }
            />
            )}

        </>);
};
export default ConsultationCardContainer;
