import React, { useEffect, useState } from "react";
import { Table, Col, Card, CardBody } from "reactstrap";
import { fetchLabTests, getLaboratory } from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { api } from "../../../../Api/Api";
import LaboratoryTestResultList from "./LaboratoryTestResultList";
import { toast } from "react-toastify";
import AlertModal from "../../../../Components/Containers/AlertModal/AlertModal";
import ModalContainer from '../../../../Components/Containers/ModalContainer/ModalContainer'
import DeleteAlertModal from '../../../../Components/Containers/AlertModal/DeleteAlertModal'
import { deleteConfirm } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";

export default function LaboratoryTestResultAdd(props: any) {
	const { appointment_id, doctor_id, patient_id } = props;
	const [tests, setTests] = useState<string[]>([]);
	const { isOpen, setIsOpen } = props;
	const [fetch, setFetch] =  useState(false);
	const [deleteId, setDeleteId] = useState();
	const [openDeleteModal, setDeleteModal] = useState<boolean>(false)
	// const { data: patientlist, refetch: patientrefetch }: any = useQuery(
	// 	[`PatientAppoinment${appointment_id}`, appointment_id, deleteCount],
	// 	() => fetchLabTests(appointment_id),
	// 	{
	// 		keepPreviousData: true,
	// 	}
	// );




	useEffect(() => {
		const getLabTests = async () => {
			try {
				const response = await fetchLabTests(appointment_id);
				if (
					response &&
					response?.Labtest &&
					Array?.isArray(response?.Labtest)
				) {
					const labTestsData = response?.Labtest;
					const testNames = labTestsData?.map((test: any) => test);
					console.log(testNames,"testNames");
					setTests(testNames);
				}
			} catch (error) {
				console.error("Failed to fetch lab tests", error);
			}
		};
		getLabTests();
	}, [isOpen, appointment_id, fetch]);

	const handleDeleteTest = (id: number) => {
		api.delete(`/Labtest/${id}`, {}, true)
			.then(async function ([success, response]: any) {
				toast.success("Test Deleted Successfully");
				setFetch(!fetch);
			})
			.catch((err) => {
				toast.error("Unexpected Error Occurred");
			});
	};

	return (
		<>
			{" "}
			<Col xxl={12}>
				<Card className="widget-card todays-appointment">
					<CardBody className="widget-inner-wrap">
						<div className="table-outer">
							<Table striped>
								<thead>
									<tr>
										<th>
											<b>Test Name</b>
										</th>
										<th className="text-end">
											<b>Action</b>
										</th>
									</tr>
								</thead>
								<tbody>
									{tests && tests.map((test: any) => (
										<tr key={test.id}>
											<td>{test?.labMaster?.name}</td>
											<td
												className="text-end"
												onClick={() =>
													deleteConfirm(test.id, setDeleteModal, setDeleteId)
												}
												title="Delete"
											>
												<i className="xl icon icon-delete"></i>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			</Col>
			<ModalContainer isOpen={openDeleteModal} setIsopen={setDeleteModal} title="">
                <DeleteAlertModal
                    setModal={setDeleteModal}
                    onDeleteClick={(id: number) => handleDeleteTest(id)}
                    delete_id={deleteId}
                />
            </ModalContainer>
		</>
	);
}
