import * as Yup from "yup";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import { toast } from "react-toastify";

export const AddAwardValidationSchema = Yup.object().shape({
	doctor_id: Yup.string().required("Skill is required"),
	title: Yup.string().required("Title is required"),
	image_name: Yup.string().required("Image is required"),
});

export const handleFileChange = (event: any, setFile: Function) => {
	setFile(event?.target?.files?.[0]);
};

export const addAwardSubmitHandler = (
	values: any,
	{ setFieldValue, setSubmitting }: any,
	file: any,
	refetch: Function,
	setIsModalOpen: Function
) => {
	const formData = new FormData();
	formData.append("doctor_id", values?.doctor_id);
	formData.append("title", values?.title);
	formData.append("image_name", file);
	axios({
		method: "post",
		url: `${BaseUrl}/awards`,
		data: formData,
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
		},
	})
		.then(function (response) {
			if (response?.status === 200) {
				toast?.success("Added successfully");
				setIsModalOpen(false);
				refetch();
			}
		})
		.catch(function (response) {
			setSubmitting(false);
			const camelCaseMessage = (message: string) => {
				return message
					.toLowerCase() // Convert entire string to lowercase
					.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
						return index === 0
							? word.toUpperCase()
							: word.toUpperCase();
					});
			};

			toast.error(camelCaseMessage(response?.response?.data?.message), {
				toastId: "Invalid-award-file-format",
			});
		});
};
