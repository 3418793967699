import { Button, Input, InputGroup } from "reactstrap";
import FullCalendar from "../../../../Components/FullCalendar";
import { useQuery } from "react-query";
import { getAllAppointments } from "../../../../Api/DoctorApis";
import { useState } from "react";
import {
  getAdminAppointmentList,
  handleDateClick,
} from "../../Admin/Appointment/AppointmentMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAppointment from "../../Admin/Appointment/AddAppointment";
import {
  getDepartmentFullList,
  getDoctorFullListBooking,
} from "../../../../Api/AdminApis";

const OptametryAppointmentIndex = () => {
  const [viewDates, setViewDates] = useState({ start: "", end: "" });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: appointmentList, refetch: appointmentListRefetch }: any =
    useQuery(
      `staff_appointment${viewDates?.start + viewDates?.end}`,
      () => getAllAppointments(viewDates),
      {
        staleTime: 20000,
        enabled: viewDates?.start !== "" && viewDates?.end !== "",
      }
    );
  const [selectedDate, setSelectedDate] = useState<string>("");
  const {
    data: adminAppointmentList,
    isLoading: isAdminAppointmentLoading,
    refetch: adminAppointmentListRefetch,
  }: any = useQuery(
    `adminAppointment${viewDates?.start + viewDates?.end}`,
    () => getAdminAppointmentList(viewDates),
    {
      keepPreviousData: true,
      enabled: viewDates?.start !== "" && viewDates?.end !== "",
    }
  );

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "department",
    () => getDepartmentFullList(),
    { staleTime: 15000 }
  );

  // Doctor List
  const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
    "doctorFullData",
    () => getDoctorFullListBooking(),
    { staleTime: 15000 }
  );

  return (
    <>
      {/* <div className="search-bar-full-wrap">
        <div className="search-bar">
          <InputGroup>
            <Input placeholder="Search here..." />
            <span className="icon-container">
              <i className="icon svg-icon icon-search"></i>
            </span>
          </InputGroup>
        </div>
        <Button
          color="secondary"
          onClick={() => setIsModalOpen(true)}
          className="sm"
          outline
          title="Add Appointment"
        >
          <i className="icon icon-plus xl"></i>Appointment
        </Button>
      </div> */}
      <div className="table-outer-wrap">
        <div className="table-wrap appointment-table-wrap">
          <FullCalendar
            onDateClick={(e: any) =>
              handleDateClick(e, setSelectedDate, setIsModalOpen)
            }
            urlTo={`/optometry/patient-profile`}
            data={adminAppointmentList}
            setViewDates={setViewDates}
            refetch={adminAppointmentListRefetch}
          />
        </div>
      </div>
      {/* <ModalContainer
        isOpen={isModalOpen}
        setIsopen={setIsModalOpen}
        title="Add Appointment"
      >
        <AddAppointment
          setIsModalOpen={setIsModalOpen}
          selectedDate={selectedDate}
          refetch={adminAppointmentListRefetch}
          doctorList={doctorList}
          doctorLoading={doctorLoading}
          departmentList={departmentList}
          departmentLoading={departmentLoading}
        />
      </ModalContainer> */}
    </>
  );
};

export default OptametryAppointmentIndex;
