import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import { toast } from "react-toastify";
import { AddPatientInitialValues } from "./FormikValidation";
import { calculate_age, capitalizeWord, setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import moment from "moment";

type AddInitialValues = {
  name: string;
  dob: string;
  mobile: string;
  gender: string;
  address: string;
  email: string;
  appoinment: string;
  departmentId: string;
  doctorId: string;
};

export const PatientSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  api
    .put(
      `/patient/${id}`,
      {
        name: values?.name,
        dob: values?.dob,
        address: values?.address,
        mobile: values?.mobile,
        gender: values?.gender,
        email: values?.email,
        departmentId: values?.departmentId,
        status: 1,
        doctorId: values?.doctorId,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Patient Details Updated ");
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });

  // Add
  // else {
  //     api.post("/patient", values, true)
  //         .then(async function ([success, response]: any) {
  //             toast.success('Patient Added ')
  //             setIsOpen(false)
  //             refetch()
  //         })
  //         .catch((err) => {
  //             actions?.setSubmitting(false)
  //             if (err?.response?.data?.errors) {
  //                 setValidationErrors(err?.response?.data?.errors, actions)
  //             }
  //             else {
  //                 toast.error('Unexpected Error Occurred')
  //             }
  //         });
  // }
};

// initializing add patient form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);

    setAddModalInitialValues({
      name: modalEditData?.[0]?.name,
      mobile: modalEditData?.[0]?.mobile,
      dob: modalEditData?.[0]?.dob,
      email: modalEditData?.[0]?.email,
      gender: modalEditData?.[0]?.gender,
      doctorId: modalEditData?.[0]?.doctor_id,
      address: modalEditData?.[0]?.address,
      departmentId: modalEditData?.[0]?.department_id,
      appoinmentId: modalEditData?.[0]?.appointment_id,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddPatientInitialValues);
  }
};

// extract patient data for table
export const extractPatientData = (setpatientData: Function, data: any) => {
  setpatientData(
    data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name,
        dob: item?.dob,
        email: item?.email,
        mobile: item?.mobile,
        gender: capitalizeWord(item?.gender),
        doctorId: item?.doctor_id,
        address: item?.address,
        departmentId: item?.department_id,
      };
    })
  );
};

// extract patient data for table
export const extractDoctorPatientData = (setpatientData: Function, data: any) => {
  setpatientData(
    data?.data?.map((item: any,  i: number) => {
      const slno = data.meta.from + i;
      return {
        slno: slno,
        id: item?.id,
        pid:`S${item?.id}`,
        name: item?.patient_name,
        dob: calculate_age(item?.dob),
        mobile: item?.patient_mobile,
        gender: capitalizeWord(item?.patient_gender),
        address: item?.address,
        departmentId: item?.department_id,
      };
    })
  );
};

// patient table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  patientData: any
) => {
  setModalEditData([]);
  setModalEditData(
    patientData?.data?.filter((itm: any) => itm?.id == selectedId)
  );
  setIsOpen(true);
};

// add patient open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};
export { AddPatientInitialValues };
