import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Col, Card, CardBody, Button, Form } from "reactstrap";
import TextError from "../../../../Components/FormikError/TextError";
import {
  AddNoteValidationSchema,
  AddNoteinitialValues,
} from "./Prescription/PrescriptionMethods/FormikValidation";
import { submitClickHandler } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";
import ReactQuill from "react-quill";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";

export default function PrescriptionNoteadd(props: any) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNotesEditorDisabled, setIsNotesEditorDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
    setIsNotesEditorDisabled(!isEditMode); // Updated to toggle disable state correctly
  };

  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card text-editor-card">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Add</small>
                <h6>Notes</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>
            <Formik
              validationSchema={AddNoteValidationSchema}
              initialValues={AddNoteinitialValues}
              enableReinitialize
              onSubmit={(values, actions) => {
                setIsNotesEditorDisabled(true); // Disable the notes editor when "Ok" is clicked
                submitClickHandler(
                  values,
                  actions,
                  props,
                  isEditMode,
                  props?.noteReftech,
                  props?.notes?.notes?.id,
                  setIsLoading
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                setFieldValue,
                setFieldError,
                errors,
              }) => {
                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="rict-text-editor-wrap">
                        <ReactQuill
                          value={values?.note || props?.notes?.notes}
                          onChange={(e: any) => setFieldValue("note", e)}
                          readOnly={isNotesEditorDisabled}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image"],
                              ["clean"],
                            ],
                          }}
                          theme="snow"
                        />
                        <ErrorMessage name="note" component={TextError} />
                      </div>
                      <div className="button-flex-between-wrap">
                        <Button
                          color="secondary"
                          className="sm"
                          onClick={handleEditClick}
                          disabled={!isEditMode}
                        >
                          Edit
                        </Button>
                        <CustomButton
                         color="secondary"
                         className="sm"
                         label={"Submit"}
                         isLoading={isLoading}
                         handleSubmit={handleSubmit}
                        />
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
