import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Form,
	Input,
	InputGroup,
} from "reactstrap";
import { fetchLabTests, getLaboratory } from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { api } from "../../../../Api/Api";
import { setValidationErrors } from "../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";

export default function LaboratoryTestAdd(props: any) {
	const { appointment_id, doctor_id, patient_id } = props;
	const [selectedTests, setSelectedTests] = useState<string[]>([""]);
	const { isOpen, setIsOpen } = props;
	const [tests, setTests] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const {
		data: labTests,

		refetch,
	} = useQuery(["labTests"], () => getLaboratory(1, ""), {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		const getLabTests = async () => {
			try {
				const response = await fetchLabTests(appointment_id);
				if (
					response &&
					response?.Labtest &&
					Array?.isArray(response?.Labtest)
				) {
					const labTestsData = response?.Labtest;
					const testNames = labTestsData?.map(
						(test: any) => test?.labMaster?.name
					);
					setTests(testNames);
				}
			} catch (error) {
				console.error("Failed to fetch lab tests", error);
			}
		};
		getLabTests();
	}, [isOpen]);
	const handleAddTest = () => {
		setSelectedTests([...selectedTests, ""]);
	};

	const handleRemoveTest = (index: number) => {
		const updatedTests = [...selectedTests];
		updatedTests.splice(index, 1);
		setSelectedTests(updatedTests);
	};

	const handleTestChange = (index: number, value: string) => {
		const updatedTests = [...selectedTests];
		updatedTests[index] = value;
		setSelectedTests(updatedTests);
	};

	const SubmitLaboratoryTests = (
		selectedTests: string[],
		appointment_id: number,
		// doctor_id: number,
		patient_id: number,
		setIsOpen: Function,
		refetch: Function
	) => {
		setIsLoading(true);
		const selectedTestsInt: number[] = selectedTests.map(Number);

		api.post(
			"/Labtest",
			{
				lab_master_id: selectedTestsInt,
				appointment_id,
				// doctor_id,
				patient_id,
			},
			true
		)
			.then(async function ([success, response]: any) {
				toast.success("Tests Submitted Successfully");
				setIsOpen(!isOpen);
				refetch();
				setIsLoading(false);
				setSelectedTests([""]);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					toast.error(
						"Failed To Submit Tests: " + err.response.data.errors
					);
					setIsLoading(false);
				}
			});
	};

	return (
		<>
			{" "}
			<Col xxl={12}>
				<Card className="widget-card text-editor-card">
					<CardBody>
						<div className="widget-head-outer">
							<div className="widget-heading">
								<small>Add</small>
								<h6>Laboratory Test</h6>
							</div>
						</div>
						<div className="prescription-form-wrap cons-payment-wrap">
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									SubmitLaboratoryTests(
										selectedTests,
										appointment_id,
										// doctor_id,
										patient_id,
										setIsOpen,
										refetch
									);
								}}
							>
								{selectedTests.map((selectedTest, index) => (
									<div key={index} className="form-wrap">
										<div className="input-wrap full-width">
											<Input
												type="select"
												placeholder="Test name"
												value={selectedTest}
												onChange={(e) =>
													handleTestChange(
														index,
														e.target.value
													)
												}
												required
											>
												<option value="">
													Select Test
												</option>
												{labTests?.data

													?.filter(
														(test: any) =>
															!tests.includes(
																test.name
															) &&
															!selectedTests.some((selectedTestId, selectedIndex) =>
															selectedIndex < index ? selectedTestId === test.id.toString() : false
														)
													)
													.map((test: any) => (
														<option
															key={test.id}
															value={test.id}
														>
															{test.name}
														</option>
													))}
											</Input>
										</div>

										{selectedTests.length > 1 && (
											<Button
												color="default"
												outline
												className="sm prescription-delete-btn"
												onClick={() =>
													handleRemoveTest(index)
												}
											>
												<i className="icon icon-close-red xl"></i>
											</Button>
										)}
									</div>
								))}
								<Button
									color="default"
									outline
									className="sm prescription-add-btn"
									onClick={handleAddTest}
								>
									<i className="icon icon-plus-squared xl"></i>
								</Button>

								<div className="button-flex-end-wrap">
									<CustomButton
										label="Submit"
										isLoading={isLoading}
										color="secondary"
										className="sm"
									/>
								</div>
							</Form>
						</div>
					</CardBody>
				</Card>
			</Col>
		</>
	);
}
