import { useState } from "react";
import { InputGroup, Input, Label } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import { useQuery } from "react-query";
import {
	getDepartmentFullList,
	getDoctorFullListBooking,
	getappointmentList,
} from "../../../../Api/AdminApis";

import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import Confirmappointment from "./AddBooking";
import {
	extractAppoinmentData,
	onEditappoinmentClickHandler,
} from "./AddBooking/AddBookingMethods/AddBookingMethods";

const BookingIndex = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [appointmentData, setAppointmentData] = useState<any>([]);
	const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
	const [search_keyword, setSearchKeyword] = useState("");
	const [start_date, setStartDate] = useState("");
	const [currentPage, setCurrentPage] = useState("");

	const {
		data: appointment,
		error: appointmentError,
		isLoading: appointmentLoading,
		isFetching: appointmentIsFetching,
		refetch: appointmentListRefetch,
	}: any = useQuery(
		[
			`appointment${currentPage + search_keyword + start_date}`,
			search_keyword,
			start_date,
			currentPage,
		],
		() => getappointmentList(search_keyword, start_date, currentPage),
		{
			onSuccess: (data) =>
				extractAppoinmentData(setAppointmentData, data),
			keepPreviousData: true,
		}
	);

	// Department List
	const { data: departmentList, isLoading: departmentLoading }: any =
		useQuery("department", () => getDepartmentFullList(), {
			staleTime: 20000,
			keepPreviousData: true,
		});

	// Doctor List
	const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
		"doctorfullData",
		() => getDoctorFullListBooking(),
		{ staleTime: 20000, keepPreviousData: true }
	);

	const handleDelete = () => {};

	return (
		<>
			<div className="search-bar-full-wrap align-end">
				<div className="search-bar">
					<InputGroup>
						<Input
							placeholder="Search By Name / Mobile "
							name="search"
							onChange={(e: any) => {
								setCurrentPage("1");
								setSearchKeyword(e.target.value);
							}}
						/>
						<span className="icon-container">
							<i className="icon svg-icon icon-search"></i>
						</span>
					</InputGroup>
				</div>
				<div className="right-side-wrap">
					<Label>Appointment Date</Label>
					<Input
						type="date"
						name="start_date"
						onChange={(e: any) => {
							setCurrentPage("1");
							setStartDate(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="table-outer-wrap">
				<div className="table-wrap">
					<CardsWithTable
						data={appointmentData}
						tableTitle="Booking Details"
						tableFields={[
							"Sl No",
							"Name",
							"Date & Time",
							"Mobile",
							"Department",
							"Doctor",
							"Message",
						]}
						Arraykeys={[
							"slno",
							"name",
							"date_time",
							"mobile",
							"department_name",
							"doctor_name",
							"message",
						]}
						viewType="table"
						toURL={"#"}
						actions={["confirm"]}
						isLoading={
							appointmentLoading ||
							(appointmentData?.length === 0 &&
								appointmentIsFetching)
						}
						error={appointmentError}
						onEditClick={(selectedId: any) =>
							onEditappoinmentClickHandler(
								selectedId,
								setModalEditData,
								setIsOpen,
								appointment
							)
						}
						paginationData={appointment?.meta}
						setCurrentPage={setCurrentPage}
						onDeleteClick={handleDelete}
						ActionsLabel="Confirmation"
					/>
				</div>
			</div>

			<ModalContainer
				isOpen={isOpen}
				setIsopen={setIsOpen}
				title="Confirm Booking "
			>
				<Confirmappointment
					setIsOpen={setIsOpen}
					appointmentLoading={appointmentLoading}
					appointmentError={appointmentError}
					modalEditData={modalEditData}
					refetch={appointmentListRefetch}
					doctorList={doctorList}
					doctorLoading={doctorLoading}
					departmentList={departmentList}
					departmentLoading={departmentLoading}
				/>
			</ModalContainer>
		</>
	);
};

export default BookingIndex;
