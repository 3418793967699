import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { capitalizeFirst } from "../../Utils/helpers/helpers";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function RenderPieChart({ dashboardData }: any) {
  let data_changed: any = [];
  const total = dashboardData?.reduce(
    (sum: any, entry: any) => sum + entry.value,
    0
  );

  // Calculate the percentage for each entry
  const percentages = dashboardData?.map((entry: any) => ({
    name: capitalizeFirst(entry.name),
    percentage: entry?.value ? (entry?.value / total) * 100 : 0,
    value: entry?.value ? entry?.value : 0,
  }));

  return (
    <>
      <PieChart width={400} height={300}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={percentages}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Pie
          data={percentages}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {percentages?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
          ))}
        </Pie>
        <Tooltip labelClassName="tooltip-piechart-text" />
      </PieChart>
      <div className="legengs-wrap">
        <div className="flex-legends-item">
          <div className="circle" style={{ backgroundColor: "#00C49F" }}></div>
          <p>
            Female&nbsp;
            <span className="percentage">
              {percentages?.[1]?.percentage
                ? percentages?.[1]?.percentage.toFixed(0)
                : 0}
              %
            </span>
          </p>
        </div>

        <div className="flex-legends-item">
          <div className="circle" style={{ backgroundColor: "#0088FE" }}></div>
          <p>
            Male&nbsp;
            <span className="percentage">
              {percentages?.[0]?.percentage
                ? percentages?.[0]?.percentage?.toFixed(0)
                : 0}
              %
            </span>
          </p>
        </div>
        <div className="flex-legends-item">
          <div className="circle" style={{ backgroundColor: "#FFBB28" }}></div>
          <p>
            Other&nbsp;
            <span className="percentage">
              {percentages?.[2]?.percentage
                ? percentages?.[2]?.percentage?.toFixed(0)
                : 0}
              %
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
