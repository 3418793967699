import { Badge } from "reactstrap";
import { clearData } from "../../lib/keystorage";
import { USER_ROLES } from "../AuthenticationConstants";
import constants from "../Constants/Constants";
import moment from "moment";

function GetPropertyValue(obj1: any, dataToRetrieve: any) {
  return dataToRetrieve.split("?.").reduce(function (o: any, k: any) {
    return o && o[k];
  }, obj1);
}

function getStatusStyle(id: any) {
  return parseInt(id) === constants?.STATUS?.ACTIVE ? (
    <span className="">Active</span>
  ) : (
    <span className="">Inactive</span>
  );
}

function getAppointmentStatusStyle(id: any) {
  return id === constants?.STATUS_APPOINMENTS?.CONFIRM ? (
    <Badge color="success-100">Confirmed</Badge>
  ) : id === constants?.STATUS_APPOINMENTS?.PENDING ? (
    <Badge color="primary-100">Pending</Badge>
  ) : id === constants?.STATUS_APPOINMENTS?.CANCELLED ? (
    <Badge color="danger">Cancelled</Badge>
  ) : id === constants?.STATUS_APPOINMENTS?.FINISHED ? (
    <Badge color="gray-60">Finished</Badge>
  ) : id === constants?.STATUS_APPOINMENTS?.LWBS ? (
    <Badge color="warning-100">LWBS</Badge>
  ) : (
    ""
  );
}
function getMetaStatusStyle(id: any) {
  return id === constants?.META_STATUS?.ACTIVE ? (
    <Badge color="success-100">Active meta</Badge>
  ) : id === constants?.META_STATUS?.INACTIVE ? (
    <Badge color="gray-60">Inactive meta</Badge>
  ) : (
    ""
  );
}

function getAppointmentCardStatusStyle(id: any) {
  return id === constants?.STATUS_APPOINMENTS?.CONFIRM
    ? "confirmed"
    : id === constants?.STATUS_APPOINMENTS?.PENDING
      ? "waiting"
      : id === constants?.STATUS_APPOINMENTS?.CANCELLED
        ? "cancelled"
        : id === constants?.STATUS_APPOINMENTS?.FINISHED
          ? "finished"
          : id === constants?.STATUS_APPOINMENTS?.LWBS
            ? "notseen"
            : "";
}

export const Logout = async (navigate: Function) => {
  await clearData();
  navigate("/");
};

export const capitalizeWord = (inputString: string) => {
  return inputString
    ?.split(" ")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
};

function processJsonResponse(data: any, fields = ["id", "name"]) {
  let dataProcessedResponse = data?.map(function (obj: any) {
    obj["value"] = obj[fields[0]];
    obj["label"] = obj[fields[1]];
    delete obj[fields[0]];
    delete obj[fields[1]];
    return obj;
  });
  return dataProcessedResponse;
}

// Sets error message for all fields from response
const setValidationErrors = (errors: any, actions: any) => {
  Object?.keys(errors)?.map((fieldName) =>
    errors?.[fieldName]?.map((errorMessage: string, index: number) =>
      actions?.setFieldError(fieldName, errorMessage)
    )
  );
};

// checks authentication
const AuthChecker = (navigate: any, path: string) => {
  if (localStorage.getItem("AUTH_TOKEN") !== null) {
    if (localStorage.getItem("ROLE") === USER_ROLES?.ADMIN) {
      navigate("/admin/dashboard", { replace: true });
    }

    if (localStorage.getItem("ROLE") === USER_ROLES?.DOCTOR) {
      navigate("/doctor/dashboard", { replace: true });
    }

    if (localStorage.getItem("ROLE") === USER_ROLES?.STAFF) {
      navigate("/staff/dashboard", { replace: true });
    }
    if (localStorage.getItem("ROLE") === USER_ROLES?.LAB) {
      navigate("/lab/dashboard", { replace: true });
    }
    if (localStorage.getItem("ROLE") === USER_ROLES?.OPTOMETRY) {
      navigate("/optometry/dashboard", { replace: true });
    }
  } else {
    navigate(path, { replace: true });
  }
};

// Formats date to yyyy-mm-dd format
export const DateFormatter = (inputDate: any) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const DobDateFormatter = (inputDate: any) => {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

const capitalizeFirst = (str: any) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

function CurrentTab(pathname: any) {
  return [
    "/admin/dashboard",
    "/doctor/dashboard",
    "/staff/dashboard",
    "/staff/dashboard/appointment/upcoming/1",
    "/staff/dashboard/appointment/today/1",
    "/staff/dashboard/appointment/previous/1",
    "/doctor/dashboard/appointment",
    "/lab/dashboard",
    "/optometry/dashboard",
  ]?.includes(pathname)
    ? "Dashboard"
    : pathname === "/admin/appointment" ||
      pathname === "/admin/appointment/1" ||
      pathname === "/admin/appointment/2" ||
      pathname === "/doctor/appointment" ||
      pathname === "/staff/appointment"||
      pathname === "/lab/appointment"||
      pathname === "/optometry/appointment"
      ? "Appointment"
      : pathname === "/admin/patients" ||
        pathname === "/staff/patients" ||
        pathname === "/doctor/patients" ||
        pathname.startsWith("/doctor/patients") ||
        pathname === "/lab/Labmaster" ||
        pathname === "/doctor/patients" ||
        pathname === "/optometry/optometrytest"
        ? "Patients"
        : pathname === "/admin/doctors" ||
          pathname.startsWith("/admin/doctors/table") ||
          pathname.startsWith("/admin/doctors/card")
          ? "Doctors"
          : pathname === "/staff/settings/1" ||
            pathname === "/staff/settings/2" ||
            pathname === "/staff/settings/3" ||
            pathname === "/staff/settings/4" ||
            pathname === "/staff/settings/5" ||
            pathname === "/staff/settings/6"

            ? "Settings"
            : pathname === "/doctor/settings"      
              ? "Settings"
              : pathname === "/optometry/settings/1"
              ? "Settings"
              : pathname === "/lab/settings/1" ||
                pathname === "/lab/settings/6"  
              ? "Settings"
              : pathname === "/admin/settings/1" ||
                pathname === "/admin/settings/2" ||
                pathname === "/admin/settings/3" ||
                pathname === "/admin/settings/4" ||
                pathname === "/admin/settings/5" ||
                pathname === "/admin/settings/6"
                ? "Settings"
                : pathname === "/admin/feedback/:currentPage" ||
                  pathname === "/admin/feedback"
                  ? "Feedback"
                  : pathname === "/admin/feedback/3/1"
                    ? "Feedback"
                    : pathname === "/admin/feedback/1/1"
                      ? "Feedback"
                      : pathname === "/admin/feedback/0/1"
                        ? "Feedback"
                        : pathname === "/admin/payments"
                          ? "Payments"
                          : pathname === "/admin/staff"
                            ? "Staff"
                            : pathname === "/admin/designation"
                              ? "Designation"
                              : pathname === "/admin/booking"
                                ? "Bookings"
                                : pathname === "/admin/websitesettings"
                                  ? "My Website"
                                  : pathname === "/admin/department"
                                    ? "Department"
                                    : pathname?.includes("/doctor/dashboard/appointment")
                                      ? "Dashboard"
                                      : pathname === "/staff/booking"
                                        ? "Booking"
                                        : pathname === "/admin/booking"
                                          ? "Booking"
                                          : pathname === "/admin/optometry" 
                                            ? "Optometry"
                                            : pathname === "/admin/laboratory" 
                                            ? "Laboratory"
                                            : pathname === "/lab/labreports" 
                                            ? "Lab Results"
                                            : pathname === "/optometry/optometryreports" 
                                            ? "Observations"
                                              : pathname.startsWith("/admin/doctors-profile")
                                                ? "Doctor"
                                                : pathname.startsWith("/admin/patient-profile/") ||
                                                  pathname.startsWith("/doctor/patient-profile/")
                                                  ? "Patient"
                                                  : ["/doctor/patient-prescription"].includes(pathname)
                                                    ? "Patient Prescription"
                                                    : "";
}
function convertISODateToDate(iso_date: any) {
  var date = new Date(iso_date);
  let months = {
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    month_names_short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };
  return (
    months.month_names_short[date.getMonth()] +
    " " +
    date.getDate() +
    "," +
    date.getFullYear()
  );
}
function validateDatetotime(iso_date: any) {
  var date = new Date(iso_date);
  var hours = date.getHours();
  var minutes: any = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}

function convertISODateToDateTime(iso_date_time: any) {
  return (
    convertISODateToDate(iso_date_time) +
    " " +
    validateDatetotime(iso_date_time)
  );
}
function calculate_age(dob1: string) {
  var date = moment(dob1, "DD-MM-YYYY").format("YYYY-MM-DD");
  const today = new Date();
  const birthDate = new Date(date);

  // Calculate age in years
  const ageInYears = today.getFullYear() - birthDate.getFullYear();

  // Calculate age in months
  const monthsDiff = today.getMonth() - birthDate.getMonth();
  const ageInMonths = ageInYears * 12 + monthsDiff;

  return ageInYears === 0 ? ageInMonths + " Months" : ageInYears + " Yrs";
}

function shift_time(week_day: any) {
  return week_day === 7
    ? "Sunday"
    : week_day === 1
      ? "Monday"
      : week_day === 2
        ? "Tuesday"
        : week_day === 3
          ? "Wednesday"
          : week_day === 4
            ? "Thursday"
            : week_day === 5
              ? "Friday"
              : week_day === 6
                ? "Saturday"
                : "";
}

// Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time) to yyyy-mm-dd format
const ISODateToDateConverter = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

var SI_SYMBOL = ["", "K", "M", "B", "T", "P", "E"];

function nFormatter(number: any) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

const customDayHeaders: any = {
  Sunday: "SUN",
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
};

export {
  GetPropertyValue,
  getStatusStyle,
  getAppointmentStatusStyle,
  processJsonResponse,
  setValidationErrors,
  capitalizeFirst,
  CurrentTab,
  AuthChecker,
  convertISODateToDate,
  convertISODateToDateTime,
  validateDatetotime,
  calculate_age,
  getAppointmentCardStatusStyle,
  ISODateToDateConverter,
  nFormatter,
  shift_time,
  getMetaStatusStyle,
  customDayHeaders,
};
