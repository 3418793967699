import { extractDoctorData } from "../Pages/Private/Admin/Appointment/AddAppointment/AddAppointmentMethods";
import { USER_ROLES } from "../Utils/AuthenticationConstants";
import constants from "../Utils/Constants/Constants";
import { processJsonResponse } from "../Utils/helpers/helpers";
import { api } from "./Api";

// departments listing without Pagination
export async function getDepartmentFullList() {
	const [, data] = await api.get(
		`/departments?isActive=1&search_keyword=`,
		true
	);
	return processJsonResponse(data?.data, ["id", "name"]);
}

export async function getDoctorsFullList() {
	const [, data] = await api.get(`/doctors?isActive=1`, true);
	return extractDoctorData(data?.data);
}
// designations listing without Pagination
export async function getDesignationFullList() {
	const [, data] = await api.get(
		`/designation?isActive=1&search_keyword=`,
		true
	);
	return processJsonResponse(data?.data, ["id", "name"]);
}

// designations listing without Pagination
export async function getDoctorFullList() {
	const [, data] = await api.get(`/doctors?isActive=1&search_keyword=`, true);
	let doctor_array: any = [];
	data?.data?.map((item: any) => {
		doctor_array?.push({
			value: item?.id,
			label: item?.user?.name,
		});
	});
	return doctor_array;
}

// doctors listing with Pagination
export async function getDoctorList(
	currentPage: string | undefined,
	search: string
) {
	const [, data] = await api.get(
		`/doctors?page=${currentPage}&search=${search}`,
		true
	);
	return data;
}
// designations listing without Pagination
export async function getDepartment(page: number, searchQuery: string) {
	const [, data] = await api.get(
		`/departments?page=${page}&search=${searchQuery}`,
		true
	);
	return data;
}

export async function getLaboratory(page: number, searchQuery: string) {
	const [, data] = await api.get(
		`/Labmaster?page=${page}&search=${searchQuery}`,
		true
	);
	return data;
}

export async function getDisease(page: number, searchQuery: string) {
	const [, data] = await api.get(
		`/Disease?page=${page}&search=${searchQuery}`,
		true
	);
	return data;
}



export async function getLaboratoryResult(appointmentId?: any) {
	const [, data] = await api.get(`/Labresult/${appointmentId}`, true);
	return data?.["LabResult"];
}

export async function getAdminLaboratoryResult(
	pageNo?: number,
	searchQuery?: string,
	date?: any,
	labtestname?:string
) {
	const [, data] = await api.get(
		`/Labresult?page=${pageNo || ""}&search=${
			searchQuery || ""
		}&start_date=${date || ""}&labtestname=${labtestname}`,
		true
	);
	return data;
}

export async function fetchLabTests(appointment_id: any) {
	const [, data] = await api.get(`/Labtest/${appointment_id}`, true);
	return data;
}

export async function getLabDashboard(status: any) {
	const [, data] = await api.get(`/labdashboard?status=${status}`, true);
	return data;
}
export async function getLabDashboardAppoinment(status: any) {
	const [, data] = await api.get(
		`/labdashboard/view?status=${status}`,
		true
	);
	return data;
}
//optometry

export async function getOptometryDashboard(status: any) {
	const [, data] = await api.get(
		`/optometrydashboard?status=${status}`,
		true
	);
	return data;
}
export async function getOptometryDashboardAppoinment(status: any) {
	const [, data] = await api.get(
		`/optometrydashboard/view?status=${status}`,
		true
	);
	return data;
}

export async function getAdminOptometryDashboardAppoinment(
	pageNo?: string,
	searchQuery?: string,
	date?: any 
) {
	const [, data] = await api.get(
		`/optometryView?page=${pageNo || ""}&search=${
			searchQuery || ""
		}&start_date=${date || ""}`,
		true
	);
	return data;
}

export async function getPatientOptometry(patientID: any) {
	const [, data] = await api.get(`/optometry/${patientID}`, true);
	return data;
}
// designations listing without Pagination
export async function getDesignation(page: number, searchQuery: string) {
	const [, data] = await api.get(
		`/designation?page=${page}&search=${searchQuery}`,
		true
	);
	return data;
}

// patient listing without Pagination
export async function getPatientList(pageNo?: string, searchQuery?: string) {
	const [, data] = await api.get(
		`/patient?page=${pageNo || ""}&search=${searchQuery || ""}`,
		true
	);
	return data;
}

export async function getPatientListByPhone(phone: string) {
	const [, data] = await api.get(`/patient?search=${phone || ""}`, true);
	let selectData = data?.data?.map((item: any) => ({
		label: item.name,
		value: item.id,
	}));
	return { selectData: selectData, fullData: data?.data };
}
// patient listing without Pagination
export async function getStaff(currentPage: string, searchQuery: string, usertype: string) {
	const [, data] = await api.get(
		`/staff?page=${currentPage}&search=${searchQuery}&usertype=${usertype}`,
		true
	);
	return data;
}

//patient profile
export async function PatientProfileDetails(keyword: any) {
	const [, data] = await api.get(`/patient/${keyword}`, true);
	return data?.data;
}

//patient profile Appoinment
export async function PatientProfileAppoinmentDetails(id: any) {
	const [, data] = await api.get(`/patient?patient_id=${id}`, true);
	return data;
}

export async function getFeedback(
	status: any,
	currentPage: any,
	searchQuery: string
) {
	const [, data] = await api.get(
		`/feedback?status=${status}&page=${currentPage}&search=${searchQuery}`,
		true
	);
	return data;
}

export async function getPatienProfile(status: any) {
	const [, data] = await api.get(`/feedback?status=${status}`, true);
	return data;
}

export async function getPatientDocuments(patientID: any) {
	const [, data] = await api.get(`/documents/${patientID}`, true);
	return data;
}

export async function getDoctorNotes(id: any) {
	const [, data] = await api.get(`/notes/${id}`, true);
	return data;
}

export async function getappointmentList(
	search_keyword: any,
	date: any,
	currentPage: string
) {
	const [, data] = await api.get(
		`/appointments?search=${search_keyword}&start_date=${date}&page=${currentPage}`,
		true
	);
	return data;
}

export async function getAwardsList(currentPage: string) {
	const [, data] = await api.get(`/hospitalawards?page=${currentPage}`, true);
	return data;
}

export async function getBannerList(currentPage: string) {
	const [, data] = await api.get(`/banner?page=${currentPage}`, true);
	return data;
}

export async function getServicesList(currentPage: string) {
	const [, data] = await api.get(`/service?page=${currentPage}`, true);
	return data;
}

//Documents
export async function getDocumentsList(currentPage: string) {
	const [, data] = await api.get(`/documents`, true);
	return data;
}

// prescription
export async function getPrescription(appoinment_id: any) {
	const [, data] = await api.get(`/prescription/${appoinment_id}`, true);
	return data;
}

export async function getTestimonialList(currentPage: string) {
	const [, data] = await api.get(`/testimonial?page=${currentPage}`, true);
	return data;
}
export async function getPatientSearchList(search_keyword: string) {
	const [, data] = await api.get(`/patient?search=${search_keyword}`, true);
	return processJsonResponse(data?.data, ["id", "name"]);
}

// designations listing without Pagination
export async function getDoctorFullListBooking() {
	const [, data] = await api.get(`/doctors?isActive=1&search_keyword=`, true);
	let de_strured_arary: any = [];
	data?.data?.map((item: any, i: any) => {
		de_strured_arary?.push({
			value: item?.id,
			label: item?.user?.name,
		});
	});
	return de_strured_arary;
}

// designations listing without Pagination
export async function getDoctorListBooking(depart: any) {
	const [, data] = await api.get(
		`/doctors?isActive=1&dept=${depart}&search_keyword=`,
		true
	);
	let de_strured_arary: any = [];
	data?.data?.map((item: any, i: any) => {
		de_strured_arary?.push({
			value: item?.id,
			label: item?.user?.name,
		});
	});
	return de_strured_arary;
}

export async function getDiseaseHistory(patient_id: any) {
	const [, data] = await api.get(`/diseasehistory/${patient_id}`, true);
	return data;
}

export async function getDoctorDashboard(status: any) {
	const [, data] = await api.get(`/dashboard/doctor?status=${status}`, true);
	return data;
}

export async function getSlots(doctor_id: any) {
	const [, data] = await api.get(
		`/public/doctorslots?doctor_id=${doctor_id}`,
		true
	);
	return data?.data;
}

export async function getStaffDashboard(status: any) {
	const [, data] = await api.get(`/staffdashboard?status=${status}`, true);
	return data;
}
export async function getStaffDashboardAppoinment(status: any) {
	const [, data] = await api.get(
		`/staffdashboard/view?status=${status}`,
		true
	);
	return data;
}

export async function getDashboardAppoinment(
	status: any,
	pageNo: string | undefined = "1",
	searchQuery: string
) {
	const userRole = localStorage.getItem("ROLE");

	const api_name =
		userRole === USER_ROLES.DOCTOR
			? "/dashboard/doctor/view"
			: userRole === USER_ROLES.STAFF
			? "/staffdashboard/view"
			: "";

	const [, data] = await api.get(
		`${api_name}?status=${status}&page=${pageNo}&search=${searchQuery}`,
		true
	);
	console.log(data,"datadatadata")
	return data;
}

export async function getpatientAppoinment(
	status: any,
	pageNo?: string,
	searchQuery?: string
) {
	const [, data] = await api.get(
		`/dashboard/doctor/view?status=${status}&page=${pageNo || ""}&search=${
			searchQuery || ""
		}`,
		true
	);
	return data;
}

// patient listing without Pagination
export async function getMeta(currentPage: string) {
	const [, data] = await api.get(`/meta?page=${currentPage}`, true);
	return data;
}

// patient listing without Pagination
export async function getSIngleMeta(active: any) {
	const [, data] = await api.get(`/meta?status=${active}`, true);
	return data;
}
