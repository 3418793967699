import { Row, Col, Button, Table, Card, CardBody } from "reactstrap";
import StatusCards from "./StatusCards";
import DashboardWidgetCard from "../../../../Components/CustomElements/Cards/DashboardWidgetCard";
import {
  Appoinments_Staff,
  doctorsData,
  staffupcomingappointmentsTableHeaders,
} from "./Widgets/WidgetCardMethods";
import RenderBarChart from "../../../../Components/Charts/BarChart";
import {
  calculate_age,
  capitalizeFirst,
  convertISODateToDateTime,
  nFormatter,
} from "../../../../Utils/helpers/helpers";
import {
  getDepartmentFullList,
  getDoctorFullListBooking,
  getStaffDashboard,
  getStaffDashboardAppoinment,
} from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAppointment from "../../Admin/Appointment/AddAppointment";

const StaffDashboard = () => {
  const [tabfilter, setTabFilter] = useState("week");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: dashboardData }: any = useQuery(
    [`dashboard${tabfilter}`, tabfilter],
    () => getStaffDashboard(tabfilter),
    {
      staleTime: 20000,
    }
  );

  const { data: dashboardDataappoinment, refetch: DashboardAppointments }: any =
    useQuery([`dashboardview`], () => getStaffDashboardAppoinment("today"), {
      staleTime: 20000,
    });

  // Doctor List
  const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
    "doctorFullData",
    () => getDoctorFullListBooking(),
    { staleTime: 15000 }
  );

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "department",
    () => getDepartmentFullList(),
    { staleTime: 15000 }
  );

  const statistics = [
    {
      title: "Total Patients",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-appointments-card"></i>,
      count: nFormatter(dashboardData?.total_patients),
    },

    {
      title: "Total Confirmed Appointments",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-new-patients"></i>,
      count: nFormatter(dashboardData?.total_confirmed_appointments),
    },

    {
      title: "Upcoming Bookings",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-appointments-card"></i>,
      count: nFormatter(dashboardData?.total_upcoming_appointments),
    },
  ];

  return (
    <>
      <div className="dashboard-wrapper">
        {/* welcome wrap */}
        <section className="mb-4">
          <Row className="gy-4 welcome-wrap">
            <Col lg={9} md={8} sm={12}>
              <h5>Welcome</h5>
              <small>Sabitha Eye Hospital Staff Dashboard</small>
            </Col>
            <Col lg={3} md={4} sm={12} className="text-end">
              <Button
                color="secondary"
                onClick={() => setIsModalOpen(true)}
                className="sm"
                outline
                title="Add Appointment"
              >
                <i className="icon icon-plus xl"></i>Appointment
              </Button>
            </Col>
          </Row>
        </section>

        {/* cards */}
        <StatusCards statistics={statistics} />

        {/* widgets */}
        <section>
          <Row className="gy-4">
            {/* today's appointments */}
            <Col lg={12} md={12} sm={12}>
              <Card className="widget-card">
                <CardBody className="widget-inner-wrap">
                  <div className="widget-head-outer left-attached">
                    <div className="widget-heading ">
                      <small>Today's</small>
                      <h6>Appointments</h6>
                    </div>
                    <span className="count-squared">
                      {dashboardDataappoinment?.data?.length}
                    </span>
                  </div>

                  <div className="table-outer">
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Schedule Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardDataappoinment?.data?.map(
                          (item: any, index: number) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{capitalizeFirst(item?.name)}</td>
                                  <td>{calculate_age(item?.dob)}</td>
                                  <td>{item?.mobile}</td>
                                  <td>
                                    {convertISODateToDateTime(item?.date_time)}
                                  </td>
                                  <td>{item?.doctor_id}</td>
                                </tr>
                              </>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="view-link">
                    <Link to={"/staff/dashboard/appointment/today/1"}>
                      View All <i className="icon icon-arrow-right"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* today's available doctors */}
            {/* <DashboardWidgetCard
              subtitle="Today's Available"
              title="Doctors"
              data={doctorsData}
              lg={6}
            /> */}

            {/* upcoming appointments */}
            <DashboardWidgetCard
              subtitle="Upcoming"
              title="Appointments"
              headerData={staffupcomingappointmentsTableHeaders}
              count={nFormatter(dashboardData?.total_upcoming_appointments)}
              tableData={Appoinments_Staff(
                dashboardData?.upcoming_appointments
              )}
              lg={6}
              url={"/staff/dashboard/appointment/upcoming/1"}
            />

            {/* Patient Statistics */}

            <Col lg={12} md={12} sm={12}>
              <Card className="widget-card ">
                <CardBody className="widget-inner-wrap">
                  <div className="widget-head-outer">
                    <div className="widget-heading">
                      <h6>Overall Appointments</h6>
                    </div>
                    <div className="button-container">
                      <div>
                        <Button
                          outline
                          onClick={() => setTabFilter("week")}
                          className={`btn-primary`}
                          active={tabfilter === "week"}
                        >
                          Weekly
                        </Button>
                      </div>
                      <div>
                        <Button
                          outline
                          onClick={() => setTabFilter("month")}
                          className={`btn-primary`}
                          active={tabfilter === "month"}
                        >
                          Monthly
                        </Button>
                      </div>
                      <div>
                        <Button
                          outline
                          onClick={() => setTabFilter("year")}
                          className={`btn-primary`}
                          active={tabfilter === "year"}
                        >
                          Year
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="bar-chart">
                    <RenderBarChart
                      height={500}
                      width={1000}
                      data={dashboardData?.graph_data}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </div>

      <ModalContainer
        isOpen={isModalOpen}
        setIsopen={setIsModalOpen}
        title="Add Appointment"
      >
        <AddAppointment
          setIsModalOpen={setIsModalOpen}
          selectedDate={new Date()}
          refetch={DashboardAppointments}
          doctorList={doctorList}
          doctorLoading={doctorLoading}
          departmentList={departmentList}
          departmentLoading={departmentLoading}
        />
      </ModalContainer>
    </>
  );
};

export default StaffDashboard;
