import React, { useState } from "react";
import { Badge, Button, Table } from "reactstrap";
import { CustomTableType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import { Link } from "react-router-dom";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../Containers/AlertModal/DeleteAlertModal";
import {
  getAppointmentStatusStyle,
  getStatusStyle,
} from "../../../Utils/helpers/helpers";
import { MetaSatusSubmitHandler } from "../../../Pages/Private/Admin/Meta/AddMeta/AddMetaMethods/AddMetaModalMethods";

const CustomTable = ({
  data,
  tableFields,
  Arraykeys,
  actions,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  url,
  meta,
  Refetch,
  ActionsLabel = "Actions",
}: CustomTableType) => {
  const [openMoadl, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState();
  const [status, setStatus] = useState();
  const [meta_status, setMetaStatus] = useState();

  const delete_Confirm = (id: any, status: any) => {
    setModal(true);
    setDeleteId(id);
    setStatus(status);
    setMetaStatus(status);
  };

  return (
    <>
      <div className="table-wrap">
        <Table striped>
          <thead>
            <tr>
              {tableFields?.map((itm: any, i: number) => (
                <th key={i}>{itm}</th>
              ))}
              {meta === true && (
                <>
                  <th>Status</th>
                </>
              )}

              <th>{ActionsLabel}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="empty-data" colSpan={tableFields?.length + 1}>
                  <CustomSpinner />
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((itm: any, i: number) => {
                return (
                  <tr key={i}>
                    {Arraykeys?.map((el: any, num: number) => (
                      <td key={num}>

                        {itm?.status === "confirmed" || itm?.status === "finished" || itm?.status === "cancelled" ? (
                          url ? (
                            <Link
                              to={`${url}/${itm.id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {itm?.[el] || "-"}
                            </Link>
                          ) : (
                            itm?.[el]
                          )
                        ) : (
                          itm?.type === "staff" || itm?.type === "department" || itm?.type === "meta" || itm?.type === "designation" ?
                            (<Link
                              to={'#'}
                              style={{ textDecoration: "none", color: "inherit" }}

                            >
                              {itm?.[el] || "-"}
                            </Link>) :
                            (<Link
                              to={`${url}/${itm.id}`}
                              style={{ textDecoration: "none", color: "inherit" }}
                              onClick={() => onEditClick(itm?.id)}
                            >
                              {itm?.[el] || "-"}
                            </Link>)


                        )}
                      </td>
                    ))}

                    {meta === true && (
                      <>
                        <td>
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => delete_Confirm(itm?.id, itm?.status)}
                          >
                            <Badge> {getStatusStyle(itm?.status)}</Badge>
                          </span>
                        </td>
                      </>
                    )}

                    <td>
                      <div className="action-flex-wrap start">
                        {actions?.includes("edit") && (
                          <Link to="#" onClick={() => onEditClick(itm?.id)} title="Edit">
                            <i className="icon icon-edit xl"></i>
                          </Link>
                        )}
                        {actions?.includes("delete") && (
                          <Link to="#" onClick={() => delete_Confirm(itm?.id, "")} title="Delete">
                            <i className="icon icon-delete xl"></i>
                          </Link>
                        )}
                        {actions?.includes("confirm") && (
                          itm?.status === "confirmed" || itm?.status === "finished" || itm?.status === "cancelled" ? (
                            getAppointmentStatusStyle(itm?.status)
                          ) : (
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => onEditClick(itm?.id)}
                            >
                              {getAppointmentStatusStyle(itm?.status)}
                            </span>
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : error ? (
              <tr>
                <td className="empty-data" colSpan={8}>Error Occurred</td>
              </tr>
            ) : (
              <td colSpan={8} className="empty-text">
                <span className="empty-text">No Data</span>
              </td>
            )}
          </tbody>
        </Table>
      </div>
      <ModalContainer isOpen={openMoadl} setIsopen={setModal} title="">
        <DeleteAlertModal
          setModal={setModal}
          onDeleteClick={onDeleteClick}
          delete_id={delete_id}
          meta={meta}
          metaStatusClick={MetaSatusSubmitHandler}
          status={status}
          Refetch={Refetch}
          meta_status={meta_status}
        />
      </ModalContainer>
    </>
  );
};

export default CustomTable;
