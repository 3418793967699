import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

export const handleChangePassword = async (
  newPassword: any,
  oldPassword: any,
  confirmedPassword: any,
  setError: (error: string) => void
): Promise<void> => {
  if (newPassword === oldPassword) {
    setError("New password cannot be the same as the old password.");
    return;
  }

  if (newPassword !== confirmedPassword) {
    setError("Passwords do not match.");
    return;
  }

  try {
    const [success, response]: any = await api.put(
      `/changepassword`,
      {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmedPassword
      },
      true
    );

    if (success) {
      toast.success("Password Updated");
    } else {
      const errorMessage = response && response.errors ? response.errors : "Failed To Update Password";
      toast.error(errorMessage);
    }
  } catch (errors:any) {
    toast.error(errors.message); 
  }
};
