import React, { useMemo, useState } from "react";
import { Row, Col, Button, Table, Card, CardBody, Label } from "reactstrap";
import DashboardWidgetCard from "../../../../Components/CustomElements/Cards/DashboardWidgetCard";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Select from "react-select";
import { InputGroup, Input, Badge } from "reactstrap";
import RenderBarChart from "../../../../Components/Charts/BarChart";
import {
	getAdminOptometryDashboardAppoinment,
	getDepartmentFullList,
	getDoctorFullListBooking,
	getOptometryDashboard,
	getOptometryDashboardAppoinment,
	getPatientList,
} from "../../../../Api/AdminApis";
import {
	calculate_age,
	capitalizeFirst,
	convertISODateToDateTime,
	nFormatter,
} from "../../../../Utils/helpers/helpers";
import { handlePatientSearch } from "../Patient/PatientListMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAppointment from "../../Admin/Appointment/AddAppointment";
import DocumentsTile from "../../../../Components/CustomElements/DocumentsTile/DocumentsTile";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { extractOptoPatientData } from "./AddPatientModalMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";

// const {
//   data: patient,
//   error: patientError,
//   isLoading: patientLoading,
//   refetch: patientListRefetch,
// }: any = useQuery(
//   [`Patient${currentPage + searchQuery}`, currentPage, searchQuery],
//   () => getPatientList(currentPage, searchQuery),
//   {
//     keepPreviousData: true,
//   }
// );

const OptometryIndex = () => {
	const [tabfilter, setTabFilter] = useState("week");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState("1");
	const [start_date, setStartDate] = useState("");

	const { data: dashboardData }: any = useQuery(
		[`dashboard${tabfilter}`, tabfilter],
		() => getOptometryDashboard(tabfilter),
		{
			staleTime: 20000,
			keepPreviousData: true,
		}
	);

	const {
		data: dashboardDataappoinment,
		refetch: DashboardAppointments,
	}: any = useQuery(
		[
			`admindashboardview${currentPage + searchQuery + start_date}`,
			currentPage,
			searchQuery,
			start_date,
		],
		() =>
			getAdminOptometryDashboardAppoinment(
				currentPage,
				searchQuery,
				start_date
			),
		{
			staleTime: 20000,
		}
	);
	const patientData = useMemo(
		() => extractOptoPatientData(dashboardDataappoinment),
		[dashboardDataappoinment]
	);
	const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
		"doctorFullData",
		() => getDoctorFullListBooking(),
		{ staleTime: 15000 }
	);

	// Department List
	const { data: departmentList, isLoading: departmentLoading }: any =
		useQuery("department", () => getDepartmentFullList(), {
			staleTime: 15000,
		});
	return (
		<>
			<div className="search-bar-full-wrap align-end">
				<div className="search-bar">
					<InputGroup>
						<Input
							placeholder="Search By Name / Mobile"
							onChange={(e) =>
								handlePatientSearch(
									e,
									setSearchQuery,
									setCurrentPage
								)
							}
						/>
						<span className="icon-container">
							<i className="icon svg-icon icon-search"></i>
						</span>
					</InputGroup>
				</div>
				<div className="right-side-wrap">
				<div className="appointment-input-wrap">
					<Label>Appointment Date</Label>
					<Input
						type="date"
						name="start_date"
						onChange={(e: any) => {
							setCurrentPage("1");
							setStartDate(e.target.value);
						}}
					/>
					</div>
				</div>
			</div>
			<CardsWithTable
				data={patientData}
				tableTitle="Optometry Details"
				tableFields={[
					"Sl No",
					"Patient OP",
					"Name",
					"Email",
					"Mobile",
					"File Name",
				]}
				Arraykeys={["slno","id", "name", "email", "mobile", "file_name"]}
				viewType="table"
				paginationData={dashboardDataappoinment?.meta}
				setCurrentPage={setCurrentPage}
				toURL={"/admin/patient-profile"}
				actions={[]}
				isLoading={false}
				error={undefined}
				onEditClick={() => {}}
				onDeleteClick={() => {}}
				ActionsLabel=""
			/>
		</>
	);
};

export default OptometryIndex;
