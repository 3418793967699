import React, { useState } from "react";
import { InputGroup, Input } from "reactstrap";
import {
	extractDoctorPatientData,
	extractPatientData,
	onEditClickHandler,
} from "./Addpatient/AddPatientMethods/AddPatientModalMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddPatient from "./Addpatient";
import { useQuery } from "react-query";
import {
	getDepartmentFullList,
	getDoctorFullList,
	getPatientList,
	getpatientAppoinment,
} from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import { useNavigate, useParams } from "react-router-dom";
import { handleDoctorPatientSearch } from "./DoctorPatientMethods";

const DoctorPatientList: React.FC = () => {
	const { pageNo = "1" } = useParams();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [patientData, setPatientData] = useState<any>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal

	const {
		data: patient,
		error: patientError,
		isLoading: patientLoading,
		isFetching: patientIsFetching,
		refetch: patientListRefetch,
	}: any = useQuery(
		[`Patient${pageNo + searchQuery}`, pageNo, searchQuery],
		() => getpatientAppoinment("patients", pageNo, searchQuery),
		{
			onSuccess: (data: any) => {
				extractDoctorPatientData(setPatientData, data);
			},
			keepPreviousData: true,
		}
	);

	// Department List
	const {
		data: departmentList,
		error: departmentError,
		isLoading: departmentLoading,
	}: any = useQuery("department", () => getDepartmentFullList(), {});

	// Doctor List
	const {
		data: doctorList,
		error: doctorError,
		isLoading: doctorLoading,
	}: any = useQuery("doctor", () => getDoctorFullList(), {});

	return (
		<>
			<div className="search-bar-full-wrap nowrap">
				<div className="search-bar">
					<InputGroup>
						<Input
							placeholder="Search Name / Mobile"
							onChange={(e) =>
								handleDoctorPatientSearch(
									e,
									setSearchQuery,
									navigate
								)
							}
						/>
						<span className="icon-container">
							<i className="icon svg-icon icon-search"></i>
						</span>
					</InputGroup>
				</div>
				<div className="button-wrap"></div>
			</div>
			<CardsWithTable
				data={patientData}
				tableTitle="Patient Details"
				tableFields={[
					"Sl No",
					"Patient OP",
					"Name",
					"Age",
					"Mobile",
					"Gender",
					"Address",
				]}
				Arraykeys={["slno","pid", "name", "dob", "mobile", "gender", "address"]}
				viewType="table"
				toURL={"/doctor/patient-profile"}
				actions={[]}
				isLoading={
					patientLoading ||
					(patientData?.length === 0 && patientIsFetching)
				}
				error={patientError}
				onEditClick={(selectedId: any) =>
					onEditClickHandler(
						selectedId,
						setModalEditData,
						setIsOpen,
						patient
					)
				}
				onDeleteClick={() => {}}
				paginationData={patient?.meta}
				setCurrentPage={(page_no: string) =>
					navigate(`/doctor/patients/${page_no}`)
				}
				ActionsLabel=""
			/>

			{/* <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add Patients"
      >
        <AddPatient
          setIsOpen={setIsOpen}
          patientLoading={patientLoading}
          patientError={patientError}
          modalEditData={modalEditData}
          refetch={patientListRefetch}
          patientList={undefined}
          departmentList={departmentList}
          doctorList={doctorList}
          doctorLoading={doctorLoading}
          doctorError={doctorError}
          departmentLoading={departmentLoading}
          departmentError={departmentError}
        />
      </ModalContainer> */}
		</>
	);
};

export default DoctorPatientList;
