import { useState, useRef } from "react";
import {
  Button, Col,
  Card,
  CardBody
} from "reactstrap";
import { useQuery } from "react-query";
import { getTestimonialList } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import {
  StatusClickHandler,
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddTestimonials/AddTestimonialsMethods/AddTestimonialsMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddTestimonial from "./AddTestimonials";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";

const Testimonials = () => {
  const myDivRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const {
    data: testimonial,
    error: testimonialError,
    isLoading: testimonialLoading,
    refetch: testimonialListRefetch,
  }: any = useQuery(
    [`testimonial${currentPage}`, currentPage],
    () => getTestimonialList(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>Testimonials</h6>
              </div>
              <div className="button-container">
                <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="secondary"
                  className="sm"
                  outline
                  title="Add Testimonial"
                >
                  <i className="icon icon-plus xl"></i> Add
                </Button>
              </div>
            </div>
            <CardsWithIcon
              type={"testimonials"}
              data={testimonial?.data}
              tableTitle="Testimonial"
              Arraykeys={["title", "created_at", "description", "image"]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={["edit", "delete"]}
              isLoading={testimonialLoading}
              error={testimonialError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  testimonial
                )
              }
              onDeleteClick={(selectedId: string | number) => handleDeleteConfirmation(selectedId)}
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(
                  selectedId,
                  testimonialListRefetch,
                  newStatus
                )
              }
            />
          </CardBody>
        </Card>
      </Col>
      {testimonial?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={testimonial?.meta?.total}
            itemsPerPage={testimonial?.meta?.per_page}
            activePage={testimonial?.meta?.current_page}
            disableScrollAfterPageChange
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef)
              setCurrentPage(pageNo)
            }}
          />
        </div>
      )}
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add  Testimonials"
      >
        <AddTestimonial
          setIsOpen={setIsOpen}
          refetch={testimonialListRefetch}
          modalEditData={modalEditData}
          testimonialError={testimonialError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, testimonialListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default Testimonials;
