import axios from "axios";
import { api } from "../../../../../../Api/Api";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

export function handleDiseaseHistoryClick(
  id: any,
  name: any,
  refetchDiseaseData: any
) {
  api
    .post(
      "/diseasehistory",
      {
        patient_id: id,
        name: name,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Disease Has Been Added.");
      refetchDiseaseData();
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
        // Handle errors
      }
    });
}

export async function handleDocumentsClick(
  id: any,
  file_name: any,
  file_path: string,
  refetchDocumentData:any
) {
  try {
    // Create a FormData object to send in the request
    const formData = new FormData();
    formData.append("patient_id", id);
    formData.append("file_name", file_name);
    formData.append("file_path", file_path);

    const res = await axios.post(`${BaseUrl}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    });

    if (res) {
      toast.success("Document Uploaded");
      refetchDocumentData();
    }
  } catch (error: any) {
    toast.error("Unexpected Error Occurred");
  }
}

export async function handleOptometryClick(
  id: any,
  file_name: any,
  file_path: string,
  refetchDocumentData:any
) {
  try {
    // Create a FormData object to send in the request
    const formData = new FormData();
    formData.append("patient_id", id);
    formData.append("file_name", file_name);
    formData.append("file_path", file_path);

    const res = await axios.post(`${BaseUrl}/optometry`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    });

    if (res) {
      toast.success("Document Uploaded");
      refetchDocumentData();
    }
  } catch (error: any) {
    toast.error("Unexpected Error Occurred");
  }
}
export function handleDiseaseDeleteClick(id: any, refetchDiseaseData:any) {
  api
    .delete(`/diseasehistory/${id}`, {}, true)
    .then(async function ([success, response]: any) {
      toast.success("Disease Has Been Deleted.");
      refetchDiseaseData()
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
        // Handle errors
      }
    });
}

export function handleDiseaseUpdateClick(id: any, updatename: any,refetchDiseaseData:any) {
  const name = updatename;
  api
    .put(`/diseasehistory/${id}`, { name: name }, true)
    .then(async function ([success, response]: any) {
      toast.success("Disease Has Been Updated.");
      refetchDiseaseData()
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
        // Handle errors
      }
    });
}
