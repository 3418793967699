import { Formik, ErrorMessage } from "formik";
import { Col, Form, Input, Row, Label} from "reactstrap";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import {
  AddPatientInitialValues,
  ForgotValidationSchema,
} from "./AddPatientMethods/FormikValidation";
import Select from "react-select";
import {
  PatientSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddPatientMethods/AddPatientModalMethods";
import { useEffect, useState } from "react";
import { AddPatientProps } from "../../../../../Type";
import { getDoctorListBooking } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";

const AddPatient = ({
  setIsOpen,
  departmentList,
  departmentLoading,
  departmentError,
  // doctorLoading,
  doctorError,
  // doctorList,
  modalEditData,
  refetch,
}: AddPatientProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddPatientInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

   // Doctor List
 const [depart, SetDepart]=useState("")
 const { data: doctorList, isLoading: doctorLoading, refetch: refetchDoctorList }: any = useQuery(
   ["doctorListDocBooking", depart],
   () => getDoctorListBooking(depart),
   { staleTime: 15000 }
   
 );

  return (
    <Formik
      validationSchema={ForgotValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        PatientSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <>
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={6}>
                    <Label>Patient's Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.name}
                      name="name"
                      placeholder="Full Name"
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </Col>

                  <Col lg={6}>
                  <Label>Date of Birth</Label>
                    <Input
                      type="date"
                      onChange={handleChange}
                      value={values?.dob}
                      name="dob"
                      placeholder="Dob"
                    />
                    <ErrorMessage name="dob" component={TextError} />
                  </Col>

                  <Col lg={6}>
                  <Label>Email</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.email}
                      name="email"
                      placeholder="Email"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </Col>

                  <Col md={6}>
                  <Label>Gender</Label>
                    <Input
                      id="exampleSelect"
                      name="gender"
                      type="select"
                      onChange={handleChange}
                      value={values?.gender}
                    >
                      <option>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Input>
                    <ErrorMessage name="gender" component={TextError} />
                  </Col>

                  <Col md={6}>
                  <Label>Phone Number</Label>
                    <Input
                      type="tel"
                      onChange={handleChange}
                      name="mobile"
                      value={values?.mobile}
                      placeholder="Phone Number"
                    />
                    <ErrorMessage name="mobile" component={TextError} />
                  </Col>
                  <Col lg={6}>
                    <Label>Address</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.address}
                      name="address"
                      placeholder="Address"
                    />
                    <ErrorMessage name="address" component={TextError} />
                  </Col>

                  <Col md={6}>
                    <Label>Department</Label>
                    <Select
                      placeholder="Select Department"
                      name="departmentId"
                      options={departmentList}
                      value={departmentList?.filter(
                        (itm: any) => itm?.value === values?.departmentId
                      )}
                      onChange={(e: any) =>{
                        setFieldValue("departmentId", e?.value);
                        SetDepart(e?.value);
                        refetchDoctorList();
                      }}
                      isLoading={departmentLoading}
                      loadingMessage={() => "Loading"}
                      noOptionsMessage={() => "Departments appears here"}
                      isSearchable
                    />
                    <ErrorMessage name="departmentId" component={TextError} />
                  </Col>

                  <Col md={6}>
                    <Label>Doctor</Label>
                    <Select
                      placeholder="Select Doctor"
                      name="doctorId"
                      options={doctorList}
                      value={doctorList?.filter(
                        (itm: any) => itm?.value === values?.doctorId
                      )}
                      onChange={(e: any) => setFieldValue("doctorId", e?.value)}
                      isLoading={doctorLoading}
                      loadingMessage={() => "Loading"}
                      noOptionsMessage={() => "Doctors appears here"}
                      isSearchable
                    />
                    <ErrorMessage name="doctorId" component={TextError} />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="secondary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddPatient;
