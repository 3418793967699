// ConsultationCardContainer.js

import React from "react";
import { Card, CardBody, Table } from 'reactstrap';
import { getDoctorNotes, getPrescription } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";

const WithoutConsultationCardContainer = ({ appointment_id, item }: any) => {

    const apiId = item || appointment_id;

    const {
        data: prescription,
        error: prescriptionError,
        isLoading: prescriptionLoading,
        isFetching: prescriptionIsFetching,
        refetch: prescriptionListRefetch
    }: any = useQuery([`prescription`, item], () => getPrescription(apiId), {})

    const {
        data: note,
        error: noteError,
        isLoading: noteLoading,
        isFetching: noteIsFetching,
        refetch: noteListRefetch
    }: any = useQuery([`note`, item], () => getDoctorNotes(apiId), {})

    return (
        <>

            <Card className="widget-card patient-empty-records-card">
                <CardBody className="widget-inner-wrap">
                    <h6>No records found</h6>
                </CardBody>
            </Card>
        </>);
};
export default WithoutConsultationCardContainer;
