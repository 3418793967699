import { SidebarMenuItemType } from "../../../Type";

const AdminSidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className="icon xl icon-dashboard"></i>,
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    icon: <i className="icon xl icon-bookings"></i>,
    label: "Appointment",
    path: "/admin/appointment",
  },
  {
    icon: <i className="icon xl icon-appointment"></i>,
    label: "Bookings",
    path: "/admin/booking",
  },
  {
    icon: <i className="icon xl icon-refraction"></i>,
    label: "Optometry",
    path: "/admin/optometry",
  },
  {
    icon: <i className="icon xl icon-lab"></i>,
    label: "Laboratory",
    path: "/admin/laboratory",
  },
  {
    icon: <i className="icon xl icon-patient"></i>,
    label: "Patients",
    path: "/admin/patients",
    AllPaths: ['/admin/patient-profile']
  },
  {
    icon: <i className="icon xl icon-doctor"></i>,
    label: "Doctors",
    path: "/admin/doctors/table/1",
    AllPaths: ['/admin/doctors-profile', '/admin/doctors/card']
  },
  {
    icon: <i className="icon xl icon-feedback"></i>,
    label: "Feedback",
    path: "/admin/feedback/3/1",
    AllPaths: ['/admin/feedback/1', '/admin/feedback/0']
  },
  // {
  //   icon: <i className="icon xl icon-payment"></i>,
  //   label: "Payments",
  //   path: "/admin/payments",
  // },
  {
    icon: <i className="icon xl icon-staff"></i>,
    label: "Staffs",
    path: "/admin/staff",
  },
  {
    icon: <i className="icon xl icon-settings"></i>,
    label: "Settings",
    path: "/admin/settings/1",
    AllPaths: ['/admin/settings']
  },
  {
    icon: <i className="icon xl icon-website"></i>,
    label: "My Website",
    path: "/admin/websitesettings",
  },
];

export { AdminSidebarMenuItems };
