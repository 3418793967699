import React from "react";
import {
  Row,
  Col, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import DesignationIndex from "../Admin/Designation";
import DepartmentIndex from "../Admin/Department";
import LaboratoryIndex from "../Admin/Laboratory"
import { USER_ROLES } from "../../../Utils/AuthenticationConstants";
import ChangePassword from "./ChangePassword/ChangePassword";
import BasicProfile from "./Profile/Profile";
import MetaIndex from "../Admin/Meta";
import { useNavigate, useParams } from "react-router-dom";
import { toggleSettingsTab } from "./SettingsMethods";
import LaboratoryMasterIndex from "../Admin/Laboratory/LabMaster/LabMaster";
import DiseaseManagement from "../Admin/Laboratory/LabMaster/DiseaseManagement";

const DoctorSettings = ({ ROLE }: { ROLE?: string }) => {
  const navigate = useNavigate()
  const { activeTab = "1" } = useParams()

  return (
    <>
      <div className="search-bar-full-wrap">
        {/* tabs */}
        <div className="top-widget-tabs">
          <Nav pills>
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                onClick={() => toggleSettingsTab("1", navigate, ROLE)}
                style={{ cursor: "pointer" }}
              >
                General
              </NavLink>
            </NavItem>
            {(localStorage.getItem("ROLE") === USER_ROLES.ADMIN ||
              localStorage.getItem("ROLE") === USER_ROLES.STAFF) && (
              <>
                {/* <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => toggleSettingsTab("2")}
                  >
                    Users
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    active={activeTab === "3"}
                    onClick={() => toggleSettingsTab("3", navigate, ROLE)}
                    style={{ cursor: "pointer" }}
                  >
                    Designation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "4"}
                    onClick={() => toggleSettingsTab("4", navigate, ROLE)}
                    style={{ cursor: "pointer" }}
                  >
                    Department
                  </NavLink>
                </NavItem>
              </>
            )}
            {localStorage.getItem("ROLE") === USER_ROLES.ADMIN && (
              <>
                <NavItem>
                  <NavLink
                    active={activeTab === "5"}
                    onClick={() => toggleSettingsTab("5", navigate, ROLE)}
                    style={{ cursor: "pointer" }}
                  >
                    Meta
                  </NavLink>
                </NavItem>
              </>
            )}
             {(localStorage.getItem("ROLE") === USER_ROLES.ADMIN ||
              localStorage.getItem("ROLE") === USER_ROLES.LAB) && (
              <>
               <NavItem>
                  <NavLink
                    active={activeTab === "6"}
                    onClick={() => toggleSettingsTab("6", navigate, ROLE)}
                    style={{ cursor: "pointer" }}
                  >
                    Laboratory
                  </NavLink>
                </NavItem>
              </>
             )}
              {(localStorage.getItem("ROLE") === USER_ROLES.ADMIN ||
              localStorage.getItem("ROLE") === USER_ROLES.DOCTOR) && (
              <>
               <NavItem>
                  <NavLink
                    active={activeTab === "7"}
                    onClick={() => toggleSettingsTab("7", navigate, ROLE)}
                    style={{ cursor: "pointer" }}
                  >
                    Disease Management
                  </NavLink>
                </NavItem>
              </>
             )}
          </Nav>
        </div>
      </div>

      <div className="settings-outer-wrap">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="gy-4">
              <BasicProfile />
              <Col md={6}>
                <ChangePassword />
              </Col>
            </Row>
          </TabPane>

          {/* Users tab content */}
          {/* <TabPane tabId="2">
            <Users />
          </TabPane> */}

          <TabPane tabId="3">
            <DesignationIndex />
          </TabPane>

          <TabPane tabId="4">
            <DepartmentIndex />
          </TabPane>
          <TabPane tabId="5">
            <MetaIndex />
          </TabPane>
          <TabPane tabId="6">
            <LaboratoryMasterIndex />
          </TabPane>
          <TabPane tabId="7">
            <DiseaseManagement />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default DoctorSettings;
