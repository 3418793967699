import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Label,
  Button,
} from "reactstrap";
import { CardsWithIconType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import CustomPagination from "../../Pagination/Pagination";
import { convertISODateToDateTime } from "../../../Utils/helpers/helpers";
import { StatusClickHandler } from "../../../Pages/Private/Admin/WebsiteSettings/Awards/AddAwards/AddAwardsMethods/AddAwardsMethods";

const CardsWithIcon = ({
  data,
  paginationData,
  setCurrentPage,
  viewType,
  toURL,
  tableTitle,
  tableFields,
  actions,
  Arraykeys,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  type,
  onStatusClick,
}: CardsWithIconType) => {
  return (
    <>
      <Row className="gy-4">
        {isLoading ? (
          <div>
            <CustomSpinner />
          </div>
        ) : error ? (
          <div>Error Occurred</div>
        ) : data?.length === 0 ? (
          <div>No Data Available</div>
        ) : (
          data?.map((itm: any, i: number) => {
            return (
              <>
                <Col md={4}>
                  <Card className="widget-card settings-card bordered">
                    <CardBody className="widget-inner-wrap">
                      <div className="content-wrap">
                        <Row className="user-card-head gy-4">
                          <Col md={12}>
                            <div className="action-flex-wrap end">
                              <Link to="#" title="Edit" onClick={() => onEditClick(itm?.id)}>
                                <i className="icon icon-edit xl"></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={() => onDeleteClick(itm?.id)}
                                title="Delete"
                              >
                                <i className="icon icon-delete xl"></i>
                              </Link>
                            </div>
                          </Col>
                          <div className="my-website-card-details ">
                            <Row className="gy-4 ">
                              <Col lg={12}>
                                {type === "services" ? (
                                  <div className="add-services-thumb-wrap">
                                  
                                  <div
                                    className="image-thumbnail-wrap"
                                    style={{
                                      backgroundColor: `${itm?.color} `,
                                    }}
                                  >
                                    <img
                                      src={
                                        itm?.image_url === null
                                          ? "/images/settingscardimage.webp"
                                          : itm?.image_url
                                      }
                                      alt="thumbnail"
                                    />
                                  </div>
                                  </div>
                                ) : (
                                  <div className="add-banner-thumb-wrap">
                                  <div className="image-thumbnail-wrap">
                                    <img
                                      src={
                                        itm?.image_url === null
                                          ? "/images/settingscardimage.webp"
                                          : itm?.image_url
                                      }
                                      alt="thumbnail"
                                    />
                                  </div>
                                  </div>
                                )}
                              </Col>
                              <Col lg={12}>
                                <div className="detail-wrap">
                                  {/* {tableFields?.map((el: any, num: number) => (
                                    <>
                                      <small className="head">{[el]}</small> */}

                                  {Arraykeys?.map((el: any, num: number) => (
                                    <>
                                      <small className="subhead">
                                        {itm?.[el]}{" "}
                                      </small>
                                    </>
                                  ))}

                                  {/* </>
                                  ))} */}
                                </div>
                              </Col>

                              {type === "banner" && (
                                <>
                                  <Col md={12}>
                                    <div className="booking-btn">
                                      <Button
                                        color="secondary"
                                        className="sm"
                                        outline
                                      >
                                        {itm?.button != null
                                          ? itm?.button
                                          : "Book an Appointment"}
                                      </Button>
                                    </div>
                                  </Col>
                                </>
                              )}
                              
                                <>
                                  <Col lg={12}>
                                    <div className="check-flex-outer">
                                      <div className="check-flex-wrap">
                                        <Input
                                          type="checkbox"
                                          checked={itm?.status == 1}
                                          onChange={() => {
                                            const newStatus =
                                              itm?.status == 1 ? 2 : 1;
                                            onStatusClick(itm.id, newStatus);
                                          }}
                                        />
                                        <Label>Show To Home Page</Label>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                            
                            </Row>
                          </div>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            );
          })
        )}
      </Row>
    </>
  );
};

export default CardsWithIcon;
