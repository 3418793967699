export const handleStaffDashboardTableSearch = (e: any, setSearchQuery: Function, navigate: Function, tab: string | undefined, setSearchVal: Function) => {
    setSearchVal(e?.target?.value)
    if (e?.target?.value?.length > 2) {
        navigate(`/staff/dashboard/appointment/${tab}/1`)
        setSearchQuery(e?.target?.value)
    }
    else {
        setSearchQuery('')
    }
}
export const handleDoctorDashboardTableSearch = (e: any, setSearchQuery: Function, navigate: Function, tab: string | undefined, setSearchVal: Function) => {
    setSearchVal(e?.target?.value)
    if (e?.target?.value?.length > 2) {
        navigate(`/doctor/dashboard/appointment/${tab}/1`)
        setSearchQuery(e?.target?.value)
    }
    else {
        setSearchQuery('')
    }
}