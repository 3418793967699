import { Button, Input, InputGroup } from "reactstrap";
import FullCalendar from "../../../../Components/FullCalendar";
import { useQuery } from "react-query";
import { getAllAppointments } from "../../../../Api/DoctorApis";
import { useState } from "react";
import {
  getAdminAppointmentList,
  handleDateClick,
} from "../../Admin/Appointment/AppointmentMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAppointment from "../../Admin/Appointment/AddAppointment";
import {
  getDepartmentFullList,
  getDoctorFullListBooking,
} from "../../../../Api/AdminApis";

const LabAppointmentIndex = () => {
  const [viewDates, setViewDates] = useState({ start: "", end: "" });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: appointmentList, refetch: appointmentListRefetch }: any =
  useQuery(
    `lab_appointment${viewDates?.start + viewDates?.end}`,
    () => getAdminAppointmentList(viewDates),
    {
      staleTime: 20000,
      enabled: viewDates?.start !== "" && viewDates?.end !== "",
    }
  );
  const [selectedDate, setSelectedDate] = useState<string>("");

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "department",
    () => getDepartmentFullList(),
    { staleTime: 15000 }
  );

  // Doctor List
  const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
    "doctorFullData",
    () => getDoctorFullListBooking(),
    { staleTime: 15000 }
  );

  return (
    <>
      
      <div className="table-outer-wrap">
        <div className="table-wrap appointment-table-wrap">
          <FullCalendar
            onDateClick={(e: any) =>
              handleDateClick(e, setSelectedDate, setIsModalOpen)
            }
            urlTo={`/lab/patient-prescription`}
            data={appointmentList}
            setViewDates={setViewDates}
            refetch={appointmentListRefetch}
          />
        </div>
      </div>
     
    </>
  );
};

export default LabAppointmentIndex;
