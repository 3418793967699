import React, { useState } from "react";
import { Table, Card, CardBody, Button } from "reactstrap";
import { useQuery } from "react-query";
import { getLaboratoryResult } from "../../../../Api/AdminApis";
import LaboratoryTestResultAdd from "./LaboratoryTestResultAdd";
import { api } from "../../../../Api/Api";
import { BaseUrl } from "../../../../Api/BaseUrl";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";
// Define an interface for the laboratory test result
interface LabTestResult {
	id: number;
	test_result: string;
	status: string;
	lab_master_id: {
		id: number;
		name: string;
		range: string;
		unit: string;
		amount: string;
	};
}

export default function LaboratoryTestResultList(props: any) {
	const { patient_id } = props;
	const { appointment_id } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingBill,setisLoadingBill] = useState(false);
	const {
		data: labTestsResult,
	} = useQuery(
		[`labTestsResult${appointment_id}`, props.resultModal,props.deletedResultList],
		() => getLaboratoryResult(appointment_id),
		{
			enabled: !props.resultModal,
		}
	);

	

	const handlePrintReport = () => {
		setIsLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				responseType: "blob",
				Authorization:
					"Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			},
			body: JSON.stringify({
				appointment_id: appointment_id,
				patient_id: patient_id,
			}),
		};
		fetch(`${BaseUrl}/labResultDownload`, requestOptions)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `.pdf`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log(e, ">>>");
			});
	};


	const handlePrintBill = () => {
		setisLoadingBill(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				responseType: "blob",
				Authorization:
					"Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			},
			body: JSON.stringify({
				appointment_id: appointment_id,
				patient_id: patient_id,
			}),
		};
		fetch(`${BaseUrl}/labbill`, requestOptions)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `.pdf`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
				setisLoadingBill(false);
			})
			.catch((e) => {
				console.log(e, ">>>");
			});
	};
	return (
		<Card className="widget-card todays-appointment">
			<CardBody className="widget-inner-wrap">
				<div className="widget-head-outer">
					<div className="widget-heading">
						<small>Finished</small>
						<h6>Laboratory Test Result</h6>
					</div>
				</div>

				<div className="table-outer">
					<Table striped>
						<thead>
							<tr>
								<th>
									<b>Test Name</b>
								</th>
								<th>
									<b>Unit</b>
								</th>
								<th>
									<b>Range</b>
								</th>
								<th>
									<b>Result</b>
								</th>
							</tr>
						</thead>
						<tbody>
							
							{labTestsResult?.map((test: any) => (
								<tr key={test?.id}>
									<td>{test?.lab_master.name}</td>
									<td>{test?.lab_master.unit}</td>
									<td>{test?.lab_master.range}</td>
									<td>{test?.test_result}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				<div className="button-end">
					<CustomButton
						isLoading={isLoadingBill}
						label="Download Bill"
						className="sm submit-btn"
						handleSubmit={handlePrintBill}
					/>
						
					
					<CustomButton
						label="Download Report"
						isLoading={isLoading}
						className="sm submit-btn"
						handleSubmit={handlePrintReport}
					/>
						
				</div>
			</CardBody>
		</Card>
	);
}
