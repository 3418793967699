import React, { useState } from "react";
import { Button, InputGroup, Input } from "reactstrap";
import {
  extractPatientData,
  onEditClickHandler,
} from "./Addpatient/AddPatientMethods/AddPatientModalMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddPatient from "./Addpatient";
import { useQuery } from "react-query";
import {
  getDepartmentFullList,
  getDoctorFullList,
  getPatientList,
} from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";

const OptometryPatientList: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [patientData, setPatientData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState<any>([]);
  const [viewType, setViewType] = useState<"table" | "card">("table");
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [currentPage, setCurrentPage] = useState("");
  //Api for Data
  const {
    data: patient,
    error: patientError,
    isLoading: patientLoading,
    isFetching: patientIsFetching,
    refetch: patientListRefetch,
  }: any = useQuery(["Patient", currentPage,searchQuery], () => getPatientList(currentPage,searchQuery), {
    onSuccess: (data) => {
      extractPatientData(setPatientData, data);
    },
  });

  // Department List
  const {
    data: departmentList,
    error: departmentError,
    isLoading: departmentLoading,
  }: any = useQuery("department", () => getDepartmentFullList(), {});

  // Doctor List
  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
  }: any = useQuery("doctor", () => getDoctorFullList(), {});

  const onDeleteClick = () => {};

  return (
    <>
      <div className="search-bar-full-wrap nowrap">
        <div className="search-bar">
          <InputGroup>
            <Input
              placeholder="Search By Name / Mobile"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="icon-container">
              <i className="icon svg-icon icon-search"></i>
            </span>
          </InputGroup>
        </div>
        <div className="button-wrap">
          {/* <Button
                        onClick={() => setIsOpen(true)}
                        color="secondary"
                        className="sm"
                        outline
                    >
                        <i className="icon icon-plus xl"></i>Add
                    </Button> */}
        </div>
      </div>
        {/* <div className="table-head-outer">
                    <div className="table-heading">
                        <h6>Patients</h6>
                    </div>
                    <div className="icon-container">
                        <a href='#'>
                            <i className="icon icon-options xl"></i>
                        </a>
                    </div>
                </div> */}

          <CardsWithTable
            data={patientData}
            tableTitle="Patient Details"
            tableFields={["Sl No","Patient OP", "Name", "Dob", "Mobile", "Gender", "Address"]}
            Arraykeys={["slno","pid", "name", "dob", "mobile", "gender", "address"]}
            viewType={viewType}
            toURL={"/optometry/patient-profile"}
            actions={[]}
            isLoading={
              patientLoading || (patientData?.length === 0 && patientIsFetching)
            }
            error={patientError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                patient
              )
            }
            onDeleteClick={onDeleteClick}
            paginationData={patient?.meta}
            setCurrentPage={setCurrentPage}
            ActionsLabel={""}
          />
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add Patients"
      >
        <AddPatient
          setIsOpen={setIsOpen}
          patientLoading={patientLoading}
          patientError={patientError}
          modalEditData={modalEditData}
          refetch={patientListRefetch}
          patientList={undefined}
          departmentList={departmentList}
          doctorList={doctorList}
          doctorLoading={doctorLoading}
          doctorError={doctorError}
          departmentLoading={departmentLoading}
          departmentError={departmentError}
        />
      </ModalContainer>
    </>
  );
};

export default OptometryPatientList;
