import React, { useState } from 'react'
import AddExperienceForm from './AddExperienceForm'
import { Button, List } from 'reactstrap'
import ModalContainer from '../../../../../../Components/Containers/ModalContainer/ModalContainer'
import DeleteAlertModal from '../../../../../../Components/Containers/AlertModal/DeleteAlertModal'
import { deleteClickHandler } from '../../DoctorProfile/Education/EducationMethods'
import constants from '../../../../../../Utils/Constants/Constants'
import { deleteConfirm } from '../../DoctorProfile/ProfileTiles/DetailsTimeMethods'
import { AddExperienceTabType } from '../../../../../../Type'
import { handleEdit } from '../AddDoctorMethods/AddDoctorModalMethods'

const AddExperienceTab: React.FC<AddExperienceTabType> = ({ doctor_id, refetch, setIsOpen, data, setActiveTab }) => {

    const [isAddOpen, setIsAddOpen] = useState(false)
    const [editData, setEditData] = useState<any>([])
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();

    return (
        <div className='add-doctor-form-wrap'>
            <div className='scroller'>
                <div>
                <List className='doc-list'>
                    {data?.experiences?.map((ex: any, i: number) => {
                        return (
                            <li key={i}>
                                <span>
                                <h6 title={ex?.institution}>{ex?.institution}</h6>
                                <p>{ex?.fromdate?.substring(0, 4)} - {ex?.todate?.substring(0, 4)}</p>
                                </span>
                                <div className="action-flex-wrap right-absolute">
                                    <span onClick={() => handleEdit(ex, setEditData, setIsAddOpen)} title='Edit'><i className='icon icon-edit-blue xl'></i></span>
                                    <span onClick={() => deleteConfirm(ex?.id, setDeleteModal, setDeleteId)} title="Delete"><i className='icon icon-delete-red xl'></i></span>
                                </div>
                                
                            </li>
                        )
                    })}
                    </List>
                </div>
                {isAddOpen &&
                <AddExperienceForm
                    doctor_id={doctor_id}
                    refetch={refetch}
                    setIsOpen={() => setIsAddOpen(false)}
                    data={editData}
                />}

                {!isAddOpen && <div className="add-more-outer"> <Button color='none' className='add-more-button sm' onClick={() => setIsAddOpen(true)}><i className='icon icon-plus-gray xl' title='Add More'></i></Button> </div>}
            </div>

            

            <div>
                <Button className='next-btn sm' color='secondary' onClick={() => setActiveTab((prev: number) => prev + 1)}>Next</Button>
            </div>

            <ModalContainer isOpen={openDeleteModal} setIsopen={setDeleteModal} title="">
                <DeleteAlertModal
                    setModal={setDeleteModal}
                    onDeleteClick={(id: string) => deleteClickHandler(constants?.DOCTOR_PROFILE_TILE_TYPES?.EXPERIENCE, id, refetch)}
                    delete_id={deleteId}
                />
            </ModalContainer>
        </div>
    )
}

export default AddExperienceTab
