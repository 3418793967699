// ConsultationCardContainer.js

import React, { useEffect, useState } from "react";
import { Card, CardBody, Table } from 'reactstrap';
import { fetchLabTests, getDoctorNotes, getLaboratoryResult, getPrescription } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import LaboratoryTestAdd from "../../Appoinment/LaboratoryTestAdd";
import LaboratoryTestResultAdd from "../../Appoinment/LaboratoryTestResultAdd";
import LaboratoryTestResultList from "../../Appoinment/LaboratoryTestResultList";

const ConsultationCardContainer = ({ appointment_id, patient_id, item }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasData, setHasData] = useState(false);
    const apiId = item || appointment_id;
    const pat_id = patient_id;
    const {
        data: prescription,
        error: prescriptionError,
        isLoading: prescriptionLoading,
        isFetching: prescriptionIsFetching,
        refetch: prescriptionListRefetch
    }: any = useQuery([`prescription`, item], () => getPrescription(apiId), {})

    const {
        data: note,
        error: noteError,
        isLoading: noteLoading,
        isFetching: noteIsFetching,
        refetch: noteListRefetch
    }: any = useQuery([`note`, item], () => getDoctorNotes(apiId), {})


    	useEffect(() => {
		const getLabTests = async () => {
			try {
				const response = await fetchLabTests(
					apiId
				);
				if (
					response?.Labtest.length > 0 
				) {
                    setHasData(true);
				}
			} catch (error) {
				console.error("Failed to fetch lab tests", error);
			}
		};
		getLabTests();
	}, [isOpen]);




    const {
		data: labTestsResult,
		error,
		isLoading,
		isFetching,
	} = useQuery([`labTestsResult${apiId}`, isOpen], () =>
		getLaboratoryResult(apiId)
	);

    return (
        <>

            <Card className="widget-card consultation-full-wrap">
                <CardBody className="widget-inner-wrap">
                    <div dangerouslySetInnerHTML={{ __html: note?.data?.[0]?.notes }}></div>
                </CardBody>
            </Card>
            <Card className="widget-card">
                <CardBody className="widget-inner-wrap">
                    <div className="widget-head-outer">
                        <div className='widget-heading'>
                            <small>Today's</small>
                            <h6>Appointments</h6>
                        </div>
                        <div className="icon-container">
                            <span>
                                <i className="icon xl icon-options"></i>
                            </span>
                        </div>
                    </div>

                    <div className="table-outer">

                        <Table striped>
                            <thead>
                                <tr>
                                    <th><b>Medicine</b></th>
                                    <th><b>Dosage</b></th>
                                    <th><b>Duration</b></th>
                                </tr>
                            </thead>
                            {prescription?.prescriptions?.map((item: any, index: any) =>
                            (<tbody>
                                <tr>
                                    <td>{item?.medicine}</td>
                                    <td>{item?.dosage}</td>
                                    <td>{item?.duration}</td>
                                </tr>


                            </tbody>))}
                        </Table>

                    </div>

                </CardBody>
            </Card>
            <LaboratoryTestAdd
                appointment_id={
                    apiId
                }

                patient_id={pat_id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            />
            {hasData &&
            <LaboratoryTestResultAdd
                appointment_id={
                    apiId
                }

            patient_id={pat_id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            />}

            {labTestsResult?.[0]?.test_result && (
            <LaboratoryTestResultList
                patient_id={pat_id}
                appointment_id={
                    apiId
                }
            />
            )}
        </>);
};
export default ConsultationCardContainer;
