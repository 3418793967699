import React from "react";
import { Table, Card, CardBody, Button } from "reactstrap";
import { useQuery } from "react-query";
import { getLaboratoryResult } from "../../../../Api/AdminApis";
// import LaboratoryTestResultAdd from "./LaboratoryTestResultAdd";
// Define an interface for the laboratory test result
interface LabTestResult {
	id: number;
	test_result: string;
	status: string;
	lab_master_id: {
		id: number;
		name: string;
		range: string;
		unit: string;
		amount: string;
	};
}

export default function LaboratoryTestResultList(props: any) {
	const { patient_id } = props;
	const { appointment_id } = props;

	const {
		data: labTestsResult,
		error,
		isLoading,
		isFetching,
	} = useQuery(
		[`labTestsResult${appointment_id}`, props.resultModal],
		() => getLaboratoryResult(appointment_id),
		{
			enabled: !props.resultModal,
		}
	);

	return (
		<Card className="widget-card todays-appointment">
			<CardBody className="widget-inner-wrap">
				<div className="widget-head-outer">
					<div className="widget-heading">
						<small>Finished</small>
						<h6>Laboratory Test Result</h6>
					</div>
				</div>

				<div className="table-outer">
					<Table striped>
						<thead>
							<tr>
								<th>
									<b>Test Name</b>
								</th>
								<th>
									<b>Unit</b>
								</th>
								<th>
									<b>Range</b>
								</th>
								<th>
									<b>Result</b>
								</th>
							</tr>
						</thead>
						<tbody>

							{labTestsResult?.map((test: any) => (
								<tr key={test?.id}>
									<td>{test?.lab_master.name}</td>
									<td>{test?.lab_master.unit}</td>
									<td>{test?.lab_master.range}</td>
									<td>{test?.test_result}</td>
								</tr>
							))}

						</tbody>

					</Table>
				</div>
			</CardBody>
		</Card>
	);
}
