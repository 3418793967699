import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";
import {
	AuthChecker,
	setValidationErrors,
} from "../../../../Utils/helpers/helpers";

export const LoginSubmitHandler = (
	values: any,
	actions: any,
	navigate: any
) => {
	api.post("/auth", values, false)
		.then(async function ([success, response]: any) {
			if (response?.token) {
				const TOKEN = response?.token;
				const ROLE = response?.userType;
				localStorage?.setItem("AUTH_TOKEN", TOKEN);
				localStorage?.setItem("ROLE", ROLE);
				if (values?.remember_me) {
					localStorage?.setItem("USERNAME", values?.email);
					localStorage?.setItem("PASSWORD", values?.password);
				}
				AuthChecker(navigate, "/");
			}
			if (response?.errors) {
				actions.setSubmitting(false);
				setValidationErrors(response?.errors, actions);
			}
		})
		.catch((err) => {
			actions.setSubmitting(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message, {
					toastId: "Invalid-login-details",
				});
			} else {
				toast?.error(err.message || "Unexpected error occurred", {
					toastId: "error-occurred",
				});
			}
		});
};
