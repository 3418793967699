import React from "react";
import { CustomIconProps } from "../../../Type";
import CustomSpinner from "../Spinner/SpinnerCustom";

const CustomIcon = ({
  label,
  isLoading,
  className,
  color,
  disabled,
  handleClick,
}: CustomIconProps) => {
  return (
    <i
    style={{ color: color, cursor: disabled ? "not-allowed" : "pointer" }}
    onClick={disabled ? undefined : handleClick}
    >
      {isLoading ? <CustomSpinner /> : label}
    </i>
  );
};

export default CustomIcon;
