import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
	PatientProfileAppoinmentDetails,
	PatientProfileDetails,
	getLaboratoryResult,
} from "../../../../../Api/AdminApis";
import { convertISODateToDateTime } from "../../../../../Utils/helpers/helpers";
import ConsultationCardContainer from "./ConsultationCardContainer";
import PatientProfileCardContainer from "./PatientProfileCardContainer";
import LaboratoryTestResultList from "../../Appointment/LaboratoryTestResultList";

const PatientProfile = () => {
	const { id } = useParams();
	const [selectedItem, setSelectedItem] = useState(null);
	const navigate = useNavigate();

	const {
		data: labTestsResult,
		error,
		isLoading,
		isFetching,
	} = useQuery([`labTestsResult${id}`], () => getLaboratoryResult(id));

	const { data: patient }: any = useQuery(
		[`PatientProfile${id}`, id],
		() => PatientProfileDetails(id),
		{
			keepPreviousData: true,
			enabled: id !== undefined,
		}
	);

	const { data: patientlist }: any = useQuery(
		[`PatientProfileAppointment${id}`, id],
		() => PatientProfileAppoinmentDetails(id),
		{
			keepPreviousData: true,
			enabled: id !== undefined,
		}
	);

	return (
		<div className="main">
			{/* Patient Profile */}
			<section>
				<Row>
					<Col xl={12}>
						<div className="search-bar-full-wrap name-top-widget-wrap">
							<div className="name-top-wrap">
								<h4>{patient?.name}</h4>
							</div>
							<span>
								<i
									className="icon icon-close xl"
									onClick={() => {
										navigate("/admin/patients");
									}}
									style={{ cursor: "pointer" }}
								></i>
							</span>
						</div>
					</Col>
					{/* Consultation Timeline */}
					<Col lg={3} md={3} sm={12}>
						<Card className="widget-card">
							<CardBody>
								<div className="widget-head-outer">
									<div className="widget-heading">
										<small>Consultation</small>
										<h6>Timeline</h6>
									</div>
									{/* <div className="icon-container">
										<span>
											<i className="icon xl icon-options"></i>
										</span>
									</div> */}
								</div>

								<Row className="gy-4">
									<Col lg={12}>
										<Card className="timeline-card active">
											{patientlist?.data?.map(
												(item: any, index: any) => (
													<span>
														<CardBody
															className="timeline-flex-wrap"
															key={index}
															onClick={() =>
																setSelectedItem(
																	item?.appointment_id
																)
															}
														>
															<div className="timeline-card-details">
																<small>
																	{
																		item?.doctor_name
																	}
																</small>
																<small>
																	{convertISODateToDateTime(
																		item?.appointment_date_time
																	)}
																</small>
															</div>
															<div className="see-more-btn">
																<i className="icon xl icon-next-arrow"></i>
															</div>
														</CardBody>
													</span>
												)
											)}
										</Card>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col lg={6} md={5} sm={12}>
						{patientlist?.data?.[0]?.appointment_id && (
							<>
								<ConsultationCardContainer
									item={selectedItem}
									appointment_id={
										patientlist?.data?.[0]?.appointment_id
									}
									patient_id={patientlist?.data?.[0]?.id}
								/>
							</>
						)}
					</Col>

					{/* Patient Profile */}
					<Col lg={3} md={4} sm={12}>
						<PatientProfileCardContainer patient={patient} />
					</Col>
				</Row>
			</section>
		</div>
	);
};

export default PatientProfile;
