import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../Components/FormikError/TextError";
import {
	AddLaboratorySubmitHandler,
	EditLaboratorySubmitHandler,
	addModalInitialValuesHandler,
} from "./AddLaboratoryMethods/AddLaboratoryModalMethods";
import {
	AddLaboratoryInitialValues,
	ForgotValidationSchema,
} from "./AddLaboratoryMethods/FormikValidation";
import { AddLaboratoryProps } from "../../../../../Type";

const AddLaboratory = ({
	setIsOpen,
	modalEditData,
	refetch,
}: AddLaboratoryProps) => {
	const [addModalInitialValues, setAddModalInitialValues] = useState(
		AddLaboratoryInitialValues
	);
	const [editMode, setEditMode] = useState<boolean>(false);
    const [isLoading,setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		addModalInitialValuesHandler(
			modalEditData,
			setAddModalInitialValues,
			setEditMode
		);
	}, [modalEditData]);
	return (
		<Formik
			validationSchema={ForgotValidationSchema}
			initialValues={addModalInitialValues}
			enableReinitialize
			onSubmit={(values, actions) => {
				editMode
					? EditLaboratorySubmitHandler(
							values,
							actions,
							setIsOpen,
							refetch,
							modalEditData?.[0]?.id,setIsLoading
					  )
					: AddLaboratorySubmitHandler(
							values,
							actions,
							setIsOpen,
							refetch, setIsLoading
					  );
			}}
		>
			{({ handleSubmit, handleChange, isSubmitting, values }) => {
				
				return (

					<div className="form-wrap">
						<Form onSubmit={handleSubmit}>
							<Row className="gy-4">
								<Col lg={6}>
									<Label>Test Name</Label>
									<Input
										type="text"
										value={values?.name}
										onChange={handleChange}
										name="name"
										placeholder="Test Name"
									/>
									<ErrorMessage
										name="name"
										component={TextError}
									/>
								</Col>
								<Col lg={6}>
									<Label>Unit</Label>
									<Input
										type="text"
										value={values?.unit}
										onChange={handleChange}
										name="unit"
										placeholder="Unit"
									/>
									<ErrorMessage
										name="unit"
										component={TextError}
									/>
								</Col>
								<Col lg={6}>
									<Label>Range</Label>
									<Input
										type="text"
										value={values?.range}
										onChange={handleChange}
										name="range"
										placeholder="Range"
									/>
									<ErrorMessage
										name="range"
										component={TextError}
									/>
								</Col>
								<Col lg={6}>
									<Label>Amount</Label>
									<Input
										type="text"
										className="amount-input"
										value={values?.amount}
										onChange={handleChange}
										name="amount"
										placeholder="Amount"
									/>
									<ErrorMessage
										name="amount"
										component={TextError}
									/>
								</Col>
								<Col lg={12}>
									<CustomButton
										isLoading={isLoading}
										handleSubmit={handleSubmit}
										label="Submit"
										color="secondary"
										className="sm submit-btn"
									/>
								</Col>
							</Row>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default AddLaboratory;
