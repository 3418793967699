import { useState, useRef } from "react";
import { Button, Col, Card, CardBody } from "reactstrap";
import { useQuery } from "react-query";
import AddAward from "./AddAwards";
import { getAwardsList } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";
import {
  StatusClickHandler,
  deleteClickHandler,
  modalHandler,
  onEditClickHandler,
} from "./AddAwards/AddAwardsMethods/AddAwardsMethods";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";

const AwardList = () => {
  const myDivRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState<string>("1");
  const [isOpen, setIsOpen] = useState(false);
  const [modalEditData, setModalEditData] = useState<any>([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedId, setSelectedId] = useState<string | number>("");

  const {
    data: award,
    error: awardError,
    isLoading: awardLoading,
    refetch: awardListRefetch,
  }: any = useQuery(
    [`award${currentPage}`, currentPage],
    () => getAwardsList(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>Awards & Achievements</h6>
              </div>
              <div className="button-container">
                <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="secondary"
                  className="sm"
                  outline
                  title="Add Award"
                >
                  <i className="icon icon-plus xl"></i> Add
                </Button>
              </div>
            </div>
            <CardsWithIcon
              type={"awards"}
              data={award?.data}
              tableTitle="Awards & Achievements"
              Arraykeys={["title", "created_at", "description"]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={["edit", "delete"]}
              isLoading={awardLoading}
              error={awardError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  award
                )
              }
              onDeleteClick={(selectedId: string | number) =>
                handleDeleteConfirmation(selectedId)
              }
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(selectedId, awardListRefetch, newStatus)
              }
            />
          </CardBody>
        </Card>
      </Col>
      {award?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={award?.meta?.total}
            itemsPerPage={award?.meta?.per_page}
            activePage={award?.meta?.current_page}
            disableScrollAfterPageChange
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef);
              setCurrentPage(pageNo);
            }}
          />
        </div>
      )}
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length === 0 ? "Add Awards" : "Edit Awards"}
      >
        <AddAward
          setIsOpen={setIsOpen}
          refetch={awardListRefetch}
          modalEditData={modalEditData}
          awardError={awardError}
        />
      </ModalContainer>

      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, awardListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default AwardList;
