import { submitEventDropClickHandler } from "../../Pages/Private/Doctor/Appoinment/Prescription/PrescriptionMethods/AddPrescriptionMethods";
import { calendarEventsType } from "../../Type";
import { ISODateToDateConverter } from "../../Utils/helpers/helpers";

export const eventClick = (eventClick: any) => {


  // Swal.fire({
  //     title: eventClick.event.title,
  //     html:
  //         `<div class="table-responsive">
  //   <table class="table">
  //   <tbody>
  //   <tr >
  //   <td>Title</td>
  //   <td><strong>` +
  //         eventClick.event.title +
  //         `</strong></td>
  //   </tr>
  //   <tr >
  //   <td>Start Time</td>
  //   <td><strong>
  //   ` +
  //         eventClick.event.start.toLocaleString() +
  //         `
  //   </strong></td>
  //   </tr>
  //   </tbody>
  //   </table>
  //   </div>`,

  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Remove Event",
  //     cancelButtonText: "Close"
  // }).then((result: any) => {
  //     if (result.value) {
  //         eventClick.event.remove();
  //         Swal.fire("Deleted!", "Your Event has been deleted.", "success");
  //     }
  // });
};

// Function to replace an event by ID with a new event
export const replaceEvent = (
  eventId: any,
  newEvent: any,
  calendarEvents: any
) => {
  const eventIndex = calendarEvents.findIndex(
    (event: any) => event.id === eventId
  );

  if (eventIndex !== -1) {
    const updatedEvents = [...calendarEvents];
    updatedEvents[eventIndex] = newEvent;
  }
};

// drag and drop
export const handleEventDrop = (
  info: any,
  calendarEvents: calendarEventsType,
  refetch: Function
) => {
  // date changed ogject
  const newObj = {
    title: info.event.title,
    start: new Date(info.event.start.toISOString()),
    id: info.event.id,
    appointment_id: info?.event?._def?.extendedProps?.appointment_id,
  };

  replaceEvent(newObj.id, newObj, calendarEvents);
  submitEventDropClickHandler(newObj, refetch);
};

// Sets date range of current view
export const handleDateRangeChange = (
  dateInfo: any,
  setViewDates: Function
) => {
  setViewDates({
    start: ISODateToDateConverter(dateInfo?.start),
    end: ISODateToDateConverter(dateInfo?.end),
  });
};

export const handleCustomEntry = (
  e: any,
  setNewTask: Function,
  setModalOpen: Function,
  newTask: calendarEventsType
) => {
  setNewTask({
    id: 0,
    title: "",
    start: "",
  });
  setNewTask({ ...newTask, start: e.date });
  setModalOpen(true);
};

export const handleSubmit = (
  setModalOpen: Function,
  setCalendarEvents: Function,
  calendarEvents: any,
  newTask: any
) => {
  setModalOpen(false);
  setCalendarEvents([
    ...calendarEvents,
    {
      ...newTask,
      id: calendarEvents?.length,
    },
  ]);
};

export const dummyEvents = [
  {
    id: 99999998,
    title: "Albin",
    doctor: "Paul",
    start: new Date("2023-10-01 00:00"),
  },
  {
    id: 99999999,
    title: "Akshai",
    doctor: "Arjun",
    start: new Date("2023-10-18 00:00"),
  },
];

export const doctor_appinment_Data = (data: any) => {
  const convertedAppointments = [];
  for (const date in data) {
    const timeSlots = data[date];

    for (const time in timeSlots) {
      const appointments = timeSlots[time];

      // Check if appointments is an array or an object
      if (Array.isArray(appointments)) {
        for (const appointment of appointments) {
          const appointmentInfo = {
            id: appointment?.patient?.id,
            title: appointment?.patient?.name
              ? appointment?.patient?.name
              : "No",
            doctor: appointment?.doctor?.name,
            doctor_id: appointment?.doctor?.id,
            start: new Date(appointment?.date_time),
            appointment_id: appointment?.id,
            appointment_status: appointment?.status?.value,
          };

          convertedAppointments.push(appointmentInfo);
        }
      } else if (typeof appointments === "object") {
        for (const key in appointments) {
          const nestedAppointments = appointments[key];
          for (const nestedAppointment of nestedAppointments) {
            const appointmentInfo = {
              id: nestedAppointment?.patient?.id,
              title: nestedAppointment?.patient?.name
                ? nestedAppointment?.patient?.name
                : "No",
              doctor: nestedAppointment?.doctor?.name,
              doctor_id: nestedAppointment?.doctor?.id,
              start: new Date(nestedAppointment?.date_time),
              appointment_id: nestedAppointment?.id,
              appointment_status: nestedAppointment?.status?.value,
            };

            convertedAppointments.push(appointmentInfo);
          }
        }
      }
    }
  }

  return convertedAppointments;
};

export const staff_appinment_Data = (data: any) => {
  const convertedAppointments = [];
  for (const date in data) {
    const timeSlots = data[date];

    for (const time in timeSlots) {
      const appointments = timeSlots[time];

      // Check if appointments is an array or an object
      if (Array.isArray(appointments)) {
        for (const appointment of appointments) {
          const appointmentInfo = {
            id: appointment?.patient_id,
            title: appointment?.patient_name ? appointment?.patient_name : "No",
            doctor: appointment?.doctor?.user?.name,
            start: new Date(appointment?.date_time),
            appointment_id: appointment?.id,
            appointment_status: appointment?.status?.value,
          };

          convertedAppointments.push(appointmentInfo);
        }
      } else if (typeof appointments === "object") {
        for (const key in appointments) {
          const nestedAppointments = appointments[key];
          for (const nestedAppointment of nestedAppointments) {
            const appointmentInfo = {
              id: nestedAppointment?.patient_id,
              title: nestedAppointment?.patient_name
                ? nestedAppointment?.patient_name
                : "No",
              doctor: nestedAppointment?.doctor?.name,
              start: new Date(nestedAppointment?.date_time),
              appointment_id: nestedAppointment?.id,
              appointment_status: nestedAppointment?.status?.value,
            };

            convertedAppointments.push(appointmentInfo);
          }
        }
      }
    }
  }

  return convertedAppointments;
};

export const staff_all_appinment_Data = (data: any) => {
  const convertedAppointments: any = [];

  data?.map((item: any, index: any) => {
    convertedAppointments?.push({
      id: item?.patient_id,
      title: item?.patient_name ? item?.patient_name : "No",
      doctor: item?.doctor?.user?.name,
      start: new Date(item?.date_time),
      appointment_id: item?.id,
      appointment_status: item?.status?.value,
    });
  });

  return convertedAppointments;
};
