import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { AddLaboratoryInitialValues } from "./FormikValidation";

type AddInitialValues = {
	name: string;
	unit: string;
	range: string;
	amount: string;
};

// Submit Add
export const AddLaboratorySubmitHandler = (
	values: AddInitialValues,
	actions: FormikHelpers<AddInitialValues>,
	setIsOpen: Function,
	refetch: Function,
	setIsLoading: Function
) => {
	setIsLoading(true);
	api.post("/Labmaster", values, true)
		.then(async function ([success, response]: any) {
			toast.success("Laboratory Added ");
			setIsLoading(false);
			setIsOpen(false);
			refetch();
		})
		.catch((err) => {
			actions?.setSubmitting(false);
			if (err?.response?.data?.errors) {
				setValidationErrors(err?.response?.data?.errors, actions);
			} else {
				toast.error("Unexpected Error Occurred");
			}
		});
	actions?.setSubmitting(false);
};
// Submit edit
export const EditLaboratorySubmitHandler = (
	values: AddInitialValues,
	actions: FormikHelpers<AddInitialValues>,
	setIsOpen: Function,
	refetch: Function,
	id: string | number,
	setIsLoading: Function
) => {
	setIsLoading(true);
	api.put(`/Labmaster/${id}`, values, true)
		.then(async function ([success, response]: any) {
			toast.success("Laboratory Details Updated ");
			setIsLoading(false);
			setIsOpen(false);
			refetch();
		})
		.catch((err) => {
			if (err?.response?.data?.errors) {
				setValidationErrors(err?.response?.data?.errors, actions);
			} else {
				toast.error("Unexpected Error Occurred");
			}
		});
	actions?.setSubmitting(false);
};

// extract Laboratorydata for table
export const extractLabPatientData = (data: any) => {
	const res = data?.data?.map((item: any,  i: number) => {
		const slno = data.meta.from + i;
		return {
			slno: slno,
			id: item?.patient_id?.id,
			name: item?.patient_id?.name,
			mobile: item?.patient_id?.mobile,
			email: item?.patient_id?.email,
			amount: item?.lab_master_id.name,
			result: item?.test_result,
		};
	});
	return res;
};

// initializing add Laboratory form - Edit / add
export const addModalInitialValuesHandler = (
	modalEditData: any,
	setAddModalInitialValues: Function,
	setEditMode: Function
) => {
	if (modalEditData?.length !== 0) {
		setEditMode(true);
		setAddModalInitialValues({
			name: modalEditData?.[0]?.name,
			range: modalEditData?.[0]?.range,
			unit: modalEditData?.[0]?.unit,
			amount: modalEditData?.[0]?.amount,
		});
	} else {
		setEditMode(false);
		setAddModalInitialValues(AddLaboratoryInitialValues);
	}
};

// Laboratory table edit onclick trigger
export const onEditClickHandler = (
	selectedId: any,
	setModalEditData: Function,
	setIsOpen: Function,
	doctorData: any
) => {
	setModalEditData([]);
	setModalEditData(
		doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
	);
	setIsOpen(true);
};

// add Laboratory open / close
export const modalHandler = (
	setModalEditData: Function,
	setIsOpen: Function
) => {
	setModalEditData([]);
	setIsOpen(true);
};

// Laboratory delete
export const deleteClickHandler = (
	selectedId: string | number,
	refetch: Function
) => {
	api.delete(`/Labmaster/${selectedId}`, {}, true)
		.then(async function ([success, response]: any) {
			refetch();
			toast.success("Deleted");
		})
		.catch((err) => {
			toast.error("Unexpected Error Occurred");
		});
};
