import { useState } from "react";
import {
  Button,
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import FullCalendar from "../../../../Components/FullCalendar";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddAppointment from "./AddAppointment";
import {
  getAdminAppointmentFilter,
  getAdminAppointmentList,
  handleDateClick,
} from "./AppointmentMethods";
import { useMutation, useQuery } from "react-query";
import {
  getDepartmentFullList,
  getDoctorFullListBooking,
} from "../../../../Api/AdminApis";

const AppointmentIndex: React.FC = ({ ...args }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [viewDates, setViewDates] = useState({ start: "", end: "" });
  const [value, setValue] = useState({ start: "", end: "" });
  const [values, setValues] = useState({ start: "", end: "" });
  const [appointment_data, setAppoinmentData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: adminAppointmentList,
    isLoading: isAdminAppointmentLoading,
    refetch: adminAppointmentListRefetch,
  }: any = useQuery(
    [
      `adminAppointment${viewDates?.start + viewDates?.end || values?.start + values?.end
      }`,
      values?.start && values?.end,
    ],
    () =>
      getAdminAppointmentList(values?.start && values?.end ? value : viewDates),
    {
      keepPreviousData: true,
      enabled:
        (viewDates?.start !== "" && viewDates?.end !== "") ||
        (values?.start !== "" && values?.end !== ""),
    }
  );

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "department",
    () => getDepartmentFullList(),
    { staleTime: 15000 }
  );

  // Doctor List
  const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
    "doctorFullData",
    () => getDoctorFullListBooking(),
    { staleTime: 15000 }
  );
  const handleChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const createAdminAppointment: any = useMutation(
    (requestData) => getAdminAppointmentFilter(value), // Replace with your actual postAdminAppointment function
    {
      onSuccess: (data: any) => {
        // Handle successful mutation
        setAppoinmentData(data);
        // You can refetch the data or perform other actions if needed
        adminAppointmentListRefetch();
      },
      onError: (error) => {
        // Handle error
        console.error("Error creating admin appointment:", error);
      },
    }
  );

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="search-bar-full-wrap end">
        <div className="filter-wrap">
          <div className="button-wrap">
            <div className="filter-collapse-group">
              <Button
                onClick={toggle}
                color=""
                className={"default squared sm"}
                outline
                title="Filter"
              >
                <i className=" icon icon-filter xl "></i>
              </Button>
              {/* collapse filter */}

              <Collapse isOpen={isOpen} {...args} className="filter-collapse">
                <Card>
                  <CardHeader>
                    <div className="filter-head">
                      <h5 className="head">Filter</h5>
                      <Button
                        color="none"
                        onClick={() =>
                          setValue({
                            ...value,
                            end: "",
                            start: "",
                          })
                        }
                        disabled={!value?.start && !value?.end ? true : false}
                        className="sm clear-all-btn"
                      >
                        Clear All
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="form-wrap">
                      <Row className="gy-3">
                        <Col xl={12}>
                          <Label>Start Date</Label>
                          <Input
                            type="date"
                            name="start"
                            value={value?.start}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xl={12}>
                          <Label>End Date</Label>
                          <Input
                            type="date"
                            name="end"
                            value={value?.end}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xl={12}>
                          <div className="filter-btn">
                            <Button
                            title="Filter"
                              color="secondary"
                              className="sm"
                              onClick={() => {
                                adminAppointmentListRefetch();
                                setIsOpen(false);
                                setValues({
                                  ...values,
                                  start: value?.start,
                                  end: value?.end,
                                });
                              }}
                              disabled={createAdminAppointment.isLoading}
                            >
                              Filter
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Collapse>
            </div>

            <Button
              title="Add Appointment"
              color="secondary"
              onClick={() => {
                setIsModalOpen(true)
                setSelectedDate('')
              }}
              className="sm"
              outline
            >
              <i className="icon icon-plus xl"></i>Appointment
            </Button>
          </div>
        </div>
      </div>
      <div className="table-outer-wrap">
        <div className="table-wrap appointment-table-wrap">
        <FullCalendar
          onDateClick={(e: any) => {
            const cellDate = new Date(e.date);
            const today = new Date();
            const isToday =
            cellDate.getFullYear() === today.getFullYear() &&
            cellDate.getMonth() === today.getMonth() &&
            cellDate.getDate() === today.getDate();
            const isAfterToday = cellDate > today;
            if (isToday || isAfterToday) {
              handleDateClick(e, setSelectedDate, setIsModalOpen);
            }
            }}
          setViewDates={setViewDates}
          data={adminAppointmentList || appointment_data}
          isLoading={isAdminAppointmentLoading}
          refetch={adminAppointmentListRefetch}
          urlTo="/admin/patient-profile"
        />

        </div>

        <ModalContainer
          isOpen={isModalOpen}
          setIsopen={setIsModalOpen}
          title="Add Appointment"
        >
          <AddAppointment
            setIsModalOpen={setIsModalOpen}
            selectedDate={selectedDate}
            refetch={adminAppointmentListRefetch}
            doctorList={doctorList}
            doctorLoading={doctorLoading}
            departmentList={departmentList}
            departmentLoading={departmentLoading}
          />
        </ModalContainer>
      </div>
    </>
  );
};

export default AppointmentIndex;
