import React from 'react'
import { Col } from 'reactstrap'
import Header from '../Header/Header'
import LabSidebar from './LabSidebar'
import useHamburgerMenu from '../../../Hooks/useHamburgerMenu'

const LabLayout = ({ children, ROLE }: any) => {
    const { isOpen, toggleMenu } = useHamburgerMenu();

    return (
        <>
            <LabSidebar isOpen={isOpen} />
            <Col className={` ${isOpen ? 'full-width-col' : ' minimise offset-md-3 offset-lg-2 offset-md-sm-3'}`}>
                <Header ROLE={ROLE} toggleMenu={toggleMenu} />
                <div className='content-wrapper'>
                    {children}
                </div>
            </Col>
        </>
    )
}

export default LabLayout