import { useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import AddLaboratory from "../AddLaboratory";
import { useQuery } from "react-query";
import { getLaboratory } from "../../../../../Api/AdminApis";
import {
  modalHandler,
  onEditClickHandler,
  deleteClickHandler,
} from "../AddLaboratory/AddLaboratoryMethods/AddLaboratoryModalMethods";
import { handleSettingsDesignationDepartmentSearch } from "../../Designation/SettingsDesignationMethods";

const LaboratoryMasterIndex = () => {
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    data: labData,
    error: laboratoryError,
    isLoading: laboratoryLoading,
    refetch: laboratoryListRefetch,
  }: any = useQuery(
    ["lab", currentPage, searchQuery],
    () => getLaboratory(currentPage, searchQuery),
    {
      keepPreviousData: true,
    }
  );

  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search By Name"
                  onChange={(e) =>
                    handleSettingsDesignationDepartmentSearch(
                      e,
                      setSearchQuery,
                      setCurrentPage
                    )
                  }
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                    title="Add Laboratory"
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="team-list-outer-wrap">
          <CardsWithTable
            data={labData?.data}
            tableTitle="Laboratory Details"
            tableFields={["Id", "Test Name", "Unit", "Range", "Amount"]}
            Arraykeys={["id", "name", "unit", "range", "amount"]}
            viewType="table"
            toURL={"/admin/patient-profile/:id"}
            actions={["edit", "delete"]}
            isLoading={laboratoryLoading}
            error={laboratoryError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                labData
              )
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, laboratoryListRefetch)
            }
            paginationData={labData?.meta}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={modalEditData?.length === 0 ? "Add Test" : "Edit Test"}
      >
        <AddLaboratory
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={laboratoryListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default LaboratoryMasterIndex;
