import { api } from "../../../../Api/Api";
import { doctor_appinment_Data } from "../../../../Components/FullCalendar/FullCalendarMethods";
import { viewDatesType } from "../../../../Type";

// Admin appointment list
export const getAdminAppointmentList = async (viewDates: viewDatesType) => {
  const [, data] = await api.get(
    `/appointments/calendar?start=${viewDates?.start}&end=${viewDates?.end}`,
    true
  );
  return doctor_appinment_Data(data?.data);
};
export const getAdminAppointmentFinishedList = async (params: any,  searchQuery?: string) => {
  const [, data] = await api.get(`/appointments?${params}&search=${searchQuery || ""}`, true);
  return data
};
export const getAdminAppointmentFilter = async (viewDates: viewDatesType) => {
  const [, data] = await api.get(
    `/appointments/calendar?start=${viewDates?.start}&end=${viewDates?.end}`,
    true
  );
  return doctor_appinment_Data(data?.data);
};
export const handleDateClick = (
  e: any,
  setSelectedDate: Function,
  setIsModalOpen: Function
) => {
  setSelectedDate(e?.date);
  setIsModalOpen(true);
};
