import axios from "axios";
import { api } from "../../../../../../Api/Api";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

export function handleDiseaseHistoryClick(
	id: any,
	name: any,
	refetchDiseaseData: any,
	setIsLoading:Function
) {
	setIsLoading(true);
	api.post(
		"/diseasehistory",
		{
			patient_id: id,
			name: name,
		},
		true
	)
		.then(async function ([success, response]: any) {
			
			if (success) {
				toast.success("Disease Has Been Added.");
				refetchDiseaseData();
				setIsLoading(false);
			} else {
				toast.error("Failed To Add Disease.");
			}
		})
		.catch((err) => {
			if (err.response && err.response.data && err.response.data.errors) {
				toast.error(
					"An Error Occurred While Adding Disease: " +
						err.response.data.errors
				);
			} else {
				toast.error(
					"An Unexpected Error Occurred While Adding Disease."
				);
			}
		});
}

export async function handleDocumentsClick(
	id: any,
	file_name: any,
	file_path: string,
	refetchDocumentData: any
) {
	try {
		// Create a FormData object to send in the request
		const formData = new FormData();
		formData.append("patient_id", id);
		formData.append("file_name", file_name);
		formData.append("file_path", file_path);

		const res = await axios.post(`${BaseUrl}/documents`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
			},
		});

		if (res) {
			toast.success("Document Uploaded");
			refetchDocumentData();
		}
	} catch (error: any) {
		toast.error("Unexpected Error Occurred");
	}
}

export async function handleOptometryClick(
	id: any,
	file_name: any,
	file_path: string,
	refetchDocumentData: any
) {
	try {
		// Create a FormData object to send in the request
		const formData = new FormData();
		formData.append("patient_id", id);
		formData.append("file_name", file_name);
		formData.append("file_path", file_path);

		const res = await axios.post(`${BaseUrl}/optometry`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
			},
		});

		if (res) {
			toast.success("Document Uploaded");
			refetchDocumentData();
		}
	} catch (error: any) {
		toast.error("Unexpected Error Occurred");
	}
}
export function handleDiseaseDeleteClick(id: any, refetchDiseaseData: any,setIsLoading:Function) {
	setIsLoading(true);
	api.delete(`/diseasehistory/${id}`, {}, true)
		.then(async function ([success, response]) {
			if (success) {
				toast.success("Disease Has Been Deleted.");
				setIsLoading(false);
				refetchDiseaseData();
			} else {
				toast.error("Failed To Delete Disease.");
			}
		})
		.catch((err) => {
			if (err.response && err.response.data && err.response.data.errors) {
				toast.error(
					"An Error Occurred While Deleting Disease: " +
						err.response.data.errors
				);
			} else {
				toast.error(
					"An Unexpected Error Occurred While Deleting Disease."
				);
			}
		});
}

export function handleDiseaseUpdateClick(
	id: any,
	updatename: any,
	refetchDiseaseData: any
) {
	const name = updatename;
	api.put(`/diseasehistory/${id}`, { name: name }, true)
		.then(async function ([success, response]) {
			if (success) {
				toast.success("Disease Has Been Updated.");
				refetchDiseaseData();
			} else {
				toast.error("Failed To Update Disease.");
			}
		})
		.catch((err) => {
			if (err.response && err.response.data && err.response.data.errors) {
				toast.error(
					"An Error Occurred While Updating Disease: " +
						err.response.data.errors
				);
			} else {
				toast.error(
					"An Unexpected Error Occurred While Updating Disease."
				);
			}
		});
}
