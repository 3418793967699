import * as Yup from "yup";
import {
  dobSchema,
  emailSchema,
  phoneSchema,
} from "../../../../../Utils/Formik/FormikCommonValidationSchemas";
import { DateFormatter } from "../../../../../Utils/helpers/helpers";

export const AddAppointmentValidationSchema = Yup.object().shape({
  patient_name: Yup.string(),
  patient_mobile: phoneSchema.required("Patient mobile number is required"),
  doctor_id: Yup.string().required("Doctor is required"),
  department_id: Yup.string().required("Department is required"),
  patient_address: Yup.string().required("Patient address is required"),
  // patient_email: emailSchema.required("Patient email is required"),
  patient_email: Yup.string().email('Invalid email format'),
  booking_date: Yup.date().min(DateFormatter(new Date()), 'Booking date cannot be in the past').required('Booking date is required').typeError('Booking date is required'),
  booking_time: Yup.string().required("Booking Time is required"), 
  payment_confirm: Yup.boolean().oneOf([true], 'Please confirm the appointment').required('Please confirm the appointment'),
  patient_dob: dobSchema,
  patient_gender: Yup.string().required("Patient gender is required"),
  patient_id: Yup.string()
}).test('at-least-one-field', 'At least one field is required', function (values) {
  const { patient_name, patient_id } = values;
  if (!patient_name && !patient_id) {
    return this.createError({ path: 'patient_name', message: 'Patient name is required' });
  }
  return true;
});

