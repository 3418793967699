/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { InputGroup, Input, Nav, NavItem, NavLink } from "reactstrap";
import TableData from "../../../../Components/Containers/TableData";
import { setCurrentPageStaff } from "../../Admin/Staff/AddStaff/AddStaffMethods/AddStaffModalMethods";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDashboardAppoinment } from "../../../../Api/AdminApis";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import { handleDoctorDashboardTableSearch } from "./methods";

export default function DashboardDoctorAppoinmentTab() {
  const navigate = useNavigate();
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('appoinment') || 'today';
  const { pageNo } = useParams();
  const [tab_name, setTabName] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchVal, setSearchVal] = useState<string>("");
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
}, []);

  const {
    data: adminAppointmentList,
    isLoading: isAdminAppointmentLoading,
    isFetching: isAdminAppointmentFetching,
    error: adminAppointmentError,
  }: any = useQuery(
    [`Appointment+${tab}${pageNo}${searchQuery}`, tab, pageNo, searchQuery],
    () =>
      getDashboardAppoinment(tab, pageNo, searchQuery),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <section className="team">
        <div className="team-home-outer-wrap">
          <div className="search-bar-full-wrap">
            <div className="top-widget-tabs">
              <span>
                Dashboard / {tab_name ? tab_name : "Today Appointments"}{" "}
              </span>
              <Nav pills>
                <NavItem>
                  <NavLink
                    className={`tab-button`}
                    active={tab === 'upcoming'}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchQuery('')
                      setSearchVal('')
                      navigate("/doctor/dashboard/appointment?appoinment=upcoming")
                      setTabName(" Upcoming Appoinment");
                    }}
                  >
                    Upcoming Appointments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`tab-button`}
                    active={tab === 'today'}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchQuery('')
                      setSearchVal('')
                      navigate("/doctor/dashboard/appointment?appoinment=today")
                      setTabName("Today Appointments");
                    }}
                  >
                    Today Appointments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`tab-button`}
                    active={tab === 'previous'}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchQuery('')
                      setSearchVal('')
                      navigate("/doctor/dashboard/appointment?appoinment=previous")
                      setTabName("Previous Appointments");
                    }}
                  >
                    Previous Appointments
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  value={searchVal}
                  onChange={(e: any) => handleDoctorDashboardTableSearch(e, setSearchQuery, navigate, tab, setSearchVal)}
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
          </div>
          <div className="team-list-outer-wrap">
            <TableData
              data={adminAppointmentList?.data}
              tableTitle={`${tab_name}`}
              tableFields={["Date & Time", "Name", "Gender", "Age", "Mobile"]}
              Arraykeys={["date_time", "name", "gender", "dob", "mobile"]}
              actions={["edit", "delete"]}
              isLoading={isAdminAppointmentLoading || isAdminAppointmentFetching}
              error={adminAppointmentError}
              paginationData={adminAppointmentList?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              tab={tab}
            />
          </div>
          {adminAppointmentList?.meta?.total !== 0 && (
            <div className="pagination">
              <CustomPagination
                totalItems={adminAppointmentList?.meta?.total}
                itemsPerPage={adminAppointmentList?.meta?.per_page}
                activePage={adminAppointmentList?.meta?.current_page}
                setCurrentPage={(page: string) => navigate(`/doctor/dashboard/appointment/${tab}/${page}`)}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
