import React, { useState } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Form,
	Input,
	InputGroup,
} from "reactstrap";

import PatientBasicData from "./PatientBasicData";
// import DiseaseHitory from "./DiseaseHistory";
// import PrescriptionDocument from "./PrescriptionDocument";
// import Prescriptionadd from "./Prescriptionadd";
// import PrescriptionNoteadd from "./PrescriptionNoteadd";
// import PrescriptionDocumentadd from "./PrescriptionDocumentadd";
import ConsultaionTimeLine from "./ConsultaionTimeLine";
import { useQuery } from "react-query";
import { getPatientDetiles } from "../../../../Api/DoctorApis";
import { useLocation } from "react-router-dom";
import {
	getDiseaseHistory,
	getDoctorNotes,
	getLaboratoryResult,
	getPatientDocuments,
} from "../../../../Api/AdminApis";
import { BaseUrl } from "../../../../Api/BaseUrl";
// import PatientPayment from "./PatientPayment";
// import LaboratoryTestAdd from "./LaboratoryTestAdd";
// import LaboratoryTestResultList from "./LaboratoryTestResultList";
// import PrescriptionOptometryDocument from "./PrescriptionOptometryDocument";
import LaboratoryTestResultAdd from "./LaboratoryTestResultAdd";
import LaboratoryTestResultList from "./LaboratoryTestResultList";

function LabPatientPrescription() {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const patientID: any = searchParams.get("patientID");
	const appoinemnetID = searchParams?.get("appoinemnetID");
	const docterId = searchParams?.get("doctorId");

	const {
		data: labTestsResult,
		error,
		isLoading,
		isFetching,
	} = useQuery([`labTestsResult${appoinemnetID}`, isOpen], () =>
		getLaboratoryResult(appoinemnetID)
	);

	const {
		data: patient,
		error: patientError,
		isLoading: patientLoading,
	}: any = useQuery(
		["patientData", patientID],
		() => getPatientDetiles(patientID),
		{
			staleTime: 20000,
		}
	);

	const {
		data: diseaseData,
		error: diseaseError,
		isLoading: diseaseLoading,
	}: any = useQuery(
		["diseasehistory", patientID],
		() => getDiseaseHistory(patientID),
		{
			staleTime: 20000,
		}
	);
	const {
		data: documentsList,
		error: documentsError,
		isLoading: documentsLoading,
	}: any = useQuery(
		[["prescription_documents", patientID]],
		() => getPatientDocuments(patientID),
		{ staleTime: 20000 }
	);
	const {
		data: notesList,
		error: notesError,
		isLoading: notesLoading,
		refetch: noteReftech,
	}: any = useQuery(
		[["notes", appoinemnetID]],
		() => getDoctorNotes(appoinemnetID),
		{ staleTime: 20000 }
	);
	const pdf_dwonload = () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				responseType: "blob",
				Authorization:
					"Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			},
			body: JSON.stringify({
				id: appoinemnetID,
			}),
		};
		fetch(`${BaseUrl}/prescription-download`, requestOptions)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `${patient?.data?.name}.pdf`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			})
			.catch((e) => {
				console.log(e, ">>>");
			});
	};
	return (
		<>
			<section className="doc-profile">
				<div className="">
					<Row className="row-reverse">
						{/* Patient Profile */}

						<Col lg={3} md={4} sm={12}>
							<Row className="gy-4">
								{/* <Col lg={3} md={4} sm={12}>
									<Button
										type="submit"
										color="success"
										className="sm"
										onClick={() => {
											pdf_dwonload();
										}}
										disabled={
											notesList?.data?.length !== 0
												? false
												: true
										}
									>
										<span style={{ color: "white" }}>
											Download Prescription
										</span>
									</Button>
								</Col> */}

								<PatientBasicData data={patient?.data} />
								{/* <DiseaseHitory diseaseData={diseaseData} /> */}
								{/* <PrescriptionDocument
									patientID={patientID}
									appoinemnetID={appoinemnetID}
									documentList={documentsList}
								/> */}
								{/* <PrescriptionOptometryDocument
									patientID={patientID}
									appoinemnetID={appoinemnetID}
									documentList={documentsList}
								/> */}
							</Row>
						</Col>

						{/* Consultaion */}
						<Col lg={6} md={5} sm={12}>
							<Row className="gy-4">
								{/* <PrescriptionNoteadd
									patientID={patientID}
									appoinemnetID={appoinemnetID}
									noteReftech={noteReftech}
									notes={notesList?.data?.[0]}
								/> */}
								{/* <Prescriptionadd
									appoinemnetID={appoinemnetID}
								/> */}
								{/* <PrescriptionDocumentadd
									parts={patientID}
									appoinemnetID={appoinemnetID}
								/> */}

								{/* New Sections - Patient Payment */}
								{/* <PatientPayment /> */}

								{/* New Sections - Patient Payment */}
								{/* <LaboratoryTestAdd
									appointment_id={appoinemnetID}
									doctor_id={docterId}
									patient_id={patientID}
								/> */}
								<LaboratoryTestResultAdd
									appointment_id={appoinemnetID}
									doctor_id={docterId}
									patient_id={patientID}
									isOpen={isOpen}
									setIsOpen={setIsOpen}
								/>
								
								{/* {labTestsResult?.[0]?.test_result && (
									<>
										<LaboratoryTestResultList
											patient_id={patientID}
											appointment_id={appoinemnetID}
											
										/>
									</>
								)} */}
							</Row>
						</Col>

						{/* Consultation Timeline */}
						<ConsultaionTimeLine patientID={patientID} />
					</Row>
				</div>
			</section>
		</>
	);
}

export default LabPatientPrescription;
