import React from "react";
import { Col, Table, Card, CardBody, Badge } from "reactstrap";
import { Link } from "react-router-dom";

const DashboardWidgetCard = ({
  url,
  title,
  type,
  subtitle,
  count,
  data = [],
  tableData = [],
  headerData,
  ...classNames
}: any) => {

  return (
    <Col lg={12} md={12} sm={12}>
      <Card className="widget-card">
        <CardBody className="widget-inner-wrap">
          <div className="widget-head-outer left-attached">
            <div className="widget-heading ">
              <small>{subtitle}</small>
              <h6>{title}</h6>
            </div>
            <span className="count-squared">{count ? count : tableData?.length}</span>
            {/* <div className="icon-container">
              <span>
                <i className="icon svg-icon icon-options"></i>
              </span>
            </div> */}
          </div>

          {/* Appoinments  */}
          <div className="table-outer">
            <Table striped>
              <thead>
                <tr>
                  {headerData?.map((header: string[], index: number) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.length === 0 && headerData?.length !== 0 && (
                  <tr>
                    <td className="empty-data" colSpan={headerData?.length}>No data</td>
                  </tr>
                )}
                {tableData?.map((item: string[], index: number) => (
                  <tr key={index}>
                    {Object.values(item).map((value, index) => (
                      <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Payments  */}
          {/* {data?.length !== 0
            ? data?.map((itm: any, i: number) => {
                return (
                  <Link to={"#"} className="widget-list-outer">
                    <div className="profile-outer"> */}
          {/* doctors  */}
          {/* {itm?.img && (
                        <div>
                          <img src={itm?.img} alt="img" />
                        </div>
                      )} */}

          {/* <div className="profile"> */}
          {/* All widgets */}
          {/* <h6>{itm?.name}</h6> */}

          {/* payment */}
          {/* {itm?.gender && (
                          <small>
                            {itm?.gender}, {itm?.age} Years
                          </small>
                        )} */}

          {/* Appoinments  */}
          {/* <small>{itm?.date} </small> */}

          {/* doctor | Appoinments  */}
          {/* <small>{itm?.department}</small>
                      </div> */}

          {/* payment */}
          {/* {itm?.paid && (
                        <Badge color={"primary-100"} className="paid-amount">
                          {itm?.paid}
                        </Badge>
                      )}
                      {itm?.payment_method && (
                        <span className="payment-type">
                          {itm?.payment_method}
                        </span>
                      )} */}
          {/* </div> */}
          {/* <div>
                      <i className="icon svg-icon icon-next-arrow"></i>
                    </div>
                  </Link>
                );
              })
            : headerData?.length === 0 && <>No Data</>} */}

          {(data?.length !== 0 || tableData?.length !== 0) && (
            <div className="view-link">
              <Link to={url}>
                View All <i className="icon icon-arrow-right"></i>
              </Link>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default DashboardWidgetCard;
