import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
	InputGroup,
	Input,
	Table,
	Badge,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Collapse,
	Button,
	Label,
	Form,
} from "reactstrap";
import {
	getAdminLaboratoryResult,
	getAdminOptometryDashboardAppoinment,
} from "../../../../Api/AdminApis";
import { extractLabPatientData } from "../Appoinments/AppoinmentMethods/AppoinmentSubmitMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
const OptometryReports = ({ ...args }) => {
	// Filter collapse
	const [isOpen, setIsOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState("1");
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [start_date, setStartDate] = useState("");

	const {
		data: dashboardDataappoinment,
		refetch: DashboardAppointments,
	}: any = useQuery(
		[
			`admindashboardview${currentPage + searchQuery + start_date}`,
			currentPage,
			searchQuery,
			start_date,
		],
		() => getAdminOptometryDashboardAppoinment(currentPage, searchQuery, start_date),
		{
			staleTime: 20000,
		}
	);
	const patientData = useMemo(
		() => extractLabPatientData(dashboardDataappoinment),
		[dashboardDataappoinment]
	);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<div className="search-bar-full-wrap align-end">
										{/* search */}
				<div className="search-bar">
					<InputGroup>
						<Input
							placeholder="Search By Name / Mobile"
							value={searchQuery}
							onChange={(e) =>
								setSearchQuery(e.target.value)
							}
						/>
						<span className="icon-container">
							<i className="icon svg-icon icon-search"></i>
						</span>
					</InputGroup>
				</div>
				<div className="right-side-wrap">
					<div className="filter-wrap">
							<div className="appointement-date-wrap">
							<Label>Appointment Date</Label>
							<Input
								type="date"
								name="start_date"
								onChange={(e: any) => {
									setCurrentPage("1");
									setStartDate(e.target.value);
								}}
							/>
							</div>
				

					</div>
				</div>
			</div>
			<div className="table-outer-wrap">
				<div className="table-wrap">
					<Table striped>
						<CardsWithTable
							data={patientData}
							tableTitle="Observation Details"
							tableFields={[
								"Sl No",
								"OP ID",
								"Patient Name",
								"Date",
								"Email",
								"Mobile",
								"File Name",
							]}
							Arraykeys={[
								"slno",
								"id",
								"name",
								"date",
								"email",
								"mobile",
								"file",
							]}
							viewType="table"
							toURL={`/optometry/patient-profile`}
							actions={[]}
							// isLoading={laboratoryLoading}
							// error={laboratoryError}
							// onEditClick={(selectedId: any) =>
							// 	onEditClickHandler(
							// 		selectedId,
							// 		setModalEditData,
							// 		setIsOpen,
							// 		labData
							// 	)
							// }
							// onDeleteClick={(selectedId: string | number) =>
							// 	deleteClickHandler(
							// 		selectedId,
							// 		laboratoryListRefetch
							// 	)
							// }
							paginationData={dashboardDataappoinment?.meta}
							setCurrentPage={setCurrentPage}
							isLoading={false}
							error={undefined}
							onEditClick={() => {}}
							onDeleteClick={() => {}}
							ActionsLabel=""
						/>

						{/* <thead>
                  <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Date & Time</th>
                      <th>Optometry</th>
                      <th>Status</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>#001</td>
                      <td>Polly Paul</td>
                      <td>10/07/ 2023 <span className="time">8:00AM</span></td>
                      <td>Optometry test 1</td>
                      <td><Badge color='success-100'>Upcoming</Badge></td>
                      <td>
                        <div className="action-flex-wrap start">
                            <Link to=""><i className='icon icon-download xl'></i></Link>
                        </div>
                      </td>
                  </tr>
                  <tr>
                      <td>#002</td>
                      <td>Rahul</td>
                      <td>10/07/ 2023 <span className="time">8:00AM</span></td>
                      <td>Optometry test 2</td>
                      <td><Badge color='gray-60'>Finished</Badge></td>
                      <td>
                        <div className="action-flex-wrap start">
                            <Link to=""><i className='icon icon-download xl'></i></Link>
                        </div>
                      </td>
                  </tr>
                  <tr>
                      <td>#003</td>
                      <td>Abhishek</td>
                      <td>10/07/ 2023 <span className="time">8:00AM</span></td>
                      <td>Optometry test 3</td>
                      <td><Badge color='primary-100'>Pending</Badge></td>
                      <td>
                        <div className="action-flex-wrap start">
                            <Link to=""><i className='icon icon-download xl'></i></Link>
                        </div>
                      </td>
                  </tr>
                  <tr>
                      <td>#004</td>
                      <td>Manu</td>
                      <td>10/07/ 2023 <span className="time">8:00AM</span></td>
                      <td>Optometry test 3</td>
                      <td><Badge color='danger-100'>Cancelled</Badge></td>
                      <td>
                        <div className="action-flex-wrap start">
                            <Link to=""><i className='icon icon-download xl'></i></Link>
                        </div>
                      </td>
                  </tr>
                  </tbody> */}
					</Table>
				</div>
			</div>
		</>
	);
};

export default OptometryReports;
