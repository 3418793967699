import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input, Label } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddLaboratory from "./AddLaboratory";
import { useQuery } from "react-query";
import {
	getLaboratory,
	getLaboratoryResult,
	getAdminLaboratoryResult,
} from "../../../../Api/AdminApis";
import {
	modalHandler,
	onEditClickHandler,
	deleteClickHandler,
	extractLabPatientData,
} from "./AddLaboratory/AddLaboratoryMethods/AddLaboratoryModalMethods";
import { handleSettingsDesignationDepartmentSearch } from "../Designation/SettingsDesignationMethods";

const LaboratoryMasterIndex = () => {
	const [isOpen, setIsOpen] = useState(false); // Add doctor modal
	const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
	const [currentPage, setCurrentPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [start_date, setStartDate] = useState("");
	const [searchLabTest, setSearchLabTest] = useState<string>("");

	const { data: labAdminpatients, refetch: DashboardAppointments }: any =
		useQuery(
			[
				`adminlabview${currentPage + searchQuery + start_date + searchLabTest}`,
				currentPage,
				searchQuery,
				start_date,
				searchLabTest
			],
			() =>
				getAdminLaboratoryResult(currentPage, searchQuery, start_date, searchLabTest),
			{
				staleTime: 20000,
				keepPreviousData: true,
			}
		);
	const patientData = useMemo(
		() => extractLabPatientData(labAdminpatients),
		[labAdminpatients]
	);
	const {
		data: labTests,

		refetch,
	} = useQuery(["labTests"], () => getLaboratory(1, ""), {
		refetchOnWindowFocus: false,
	});

	return (
		<section className="team">
			<div className="team-home-outer-wrap">

				<div className="search-bar-full-wrap align-end">
					<div className="search-bar">
						<InputGroup>
							<Input
								placeholder="Search By Name / Mobile"
								onChange={(e) =>
									handleSettingsDesignationDepartmentSearch(
										e,
										setSearchQuery,
										setCurrentPage
									)
								}
							/>
							<span className="icon-container">
								<i className="icon svg-icon icon-search"></i>
							</span>
						</InputGroup>
					</div>
					<div className="input-select-wrapper">
						<div className="input-select-wrap">
							<InputGroup>
								<Input type="select" value={searchLabTest} onChange={(e) => setSearchLabTest(e.target.value)}>
									<option value="">Select a lab test</option>
									{labTests && labTests.data.map((test: any) => (
										<option key={test.id} value={test.name}>{test.name}</option>
									))}
								</Input>
							</InputGroup>
						</div>
						<div className="right-side-wrap">
							<div className="appointment-input-wrap">
								<Label>Appointment Date</Label>
								<Input
									type="date"
									name="start_date"
									onChange={(e: any) => {
										setCurrentPage(1);
										setStartDate(e.target.value);
									}}
								/>
							</div>
						</div>
					</div>

				</div>
				{/* cards section */}
				<div className="team-list-outer-wrap">
					<CardsWithTable
						data={patientData}
						tableTitle="Laboratory Details"
						tableFields={[
							"Sl No",
							"Patient OP",
							"Patient Name",
							"Mobile",
							"Email",
							"Test Name",
							"Result",
						]}
						Arraykeys={[
							"slno",
							"id",
							"name",
							"mobile",
							"email",
							"amount",
							"result",
						]}
						viewType="table"
						toURL={`/admin/patient-profile`}
						actions={[]}
						// isLoading={laboratoryLoading}
						// error={laboratoryError}
						// onEditClick={(selectedId: any) =>
						// 	onEditClickHandler(
						// 		selectedId,
						// 		setModalEditData,
						// 		setIsOpen,
						// 		labData
						// 	)
						// }
						// onDeleteClick={(selectedId: string | number) =>
						// 	deleteClickHandler(
						// 		selectedId,
						// 		laboratoryListRefetch
						// 	)
						// }
						paginationData={labAdminpatients?.meta}
						setCurrentPage={setCurrentPage}
						isLoading={false}
						error={undefined}
						onEditClick={() => { }}
						onDeleteClick={() => { }}
						ActionsLabel=""
					/>
				</div>
			</div>

			{/* <ModalContainer
				isOpen={isOpen}
				setIsopen={setIsOpen}
				title={modalEditData?.length === 0 ? "Add Test" : "Edit Test"}
			>
				<AddLaboratory
					setIsOpen={setIsOpen}
					modalEditData={modalEditData}
					refetch={laboratoryListRefetch}
				/>
			</ModalContainer> */}
		</section>
	);
};

export default LaboratoryMasterIndex;
