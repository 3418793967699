import { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Form, Input, Label, Table } from "reactstrap";
import TextError from "../../../../../../Components/FormikError/TextError";
import { AddSkillType } from "../../../../../../Type";
import {
	SkillSubmitHandler,
	editModalInitialValuesHandler,
} from "../../DoctorProfile/Skill/AddSkillMethods";
import { AddSkillValidationSchema } from "../../DoctorProfile/Skill/AddSkillValidationSchema";
import CustomIcon from "../../../../../../Components/CustomElements/FormElements/CustomIcon";

const AddSkillForm = ({
	setIsOpen,
	doctor_id,
	refetch,
	data,
}: AddSkillType) => {
	const [editModalInitialValues, setEditModalInitialValues] = useState({
		skill: "",
		doctor_id: doctor_id,
	});
	const [editMode, setEditMode] = useState<boolean>(doctor_id !== undefined);

	useEffect(() => {
		editModalInitialValuesHandler(
			data,
			setEditModalInitialValues,
			setEditMode,
			doctor_id
		);
	}, [data]);

	return (
		<Formik
			validationSchema={AddSkillValidationSchema}
			initialValues={editModalInitialValues}
			enableReinitialize
			onSubmit={(values, actions) => {
				SkillSubmitHandler(
					values,
					actions,
					setIsOpen,
					refetch,
					editMode,
					data?.id
				);
			}}
		>
			{({ handleSubmit, handleChange, isSubmitting, values }) => {
				return (
					<div className="education-wrap time-slot-wrap">
						<Form className="form-wrap" onSubmit={handleSubmit}>
							<div className="form-scroll-wrap">
								<div className="day-time-wrap">
									<Table>
										<tbody>
											<tr>
												<td>
													<div className="input-wrap">
														<Label>Skill</Label>
														<Input
															type="text"
															name="skill"
															onChange={
																handleChange
															}
															value={
																values?.skill
															}
															placeholder="Enter Skills"
														/>
														<ErrorMessage
															name="skill"
															component={
																TextError
															}
														/>
													</div>
												</td>
												<td>
													<div className="action-flex-status-btn centered">
														<i
															className="icon xl icon-close-red"
															title="Cancel"
															onClick={() =>
																setIsOpen(false)
															}
														></i>

														<CustomIcon
															disabled={
																isSubmitting
															}
															isLoading={
																isSubmitting
															}
															handleClick={
																handleSubmit
															}
															label={
																<i className="icon xl icon-check-green"></i>
															}
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default AddSkillForm;
