import AdminLayout from './Admin';
import { childrenType } from '../../Type';
import DoctorLayout from './Doctor';
import StaffLayout from './Staff';
import LabLayout from './Lab';
import OptometryLayout from './Optometry'

const LayoutContainer = ({ children }: childrenType) => {
    const ROLE: any = localStorage?.getItem("ROLE")
    const Layout: any = {
        admin: <AdminLayout ROLE={ROLE}>{children} </AdminLayout>,
        doctor: <DoctorLayout ROLE={ROLE}>{children} </DoctorLayout>,
        staff: <StaffLayout ROLE={ROLE}>{children} </StaffLayout>,
        lab: <LabLayout ROLE={ROLE}>{children} </LabLayout>,
        optometry: <OptometryLayout ROLE={ROLE}>{children} </OptometryLayout>
    }

    return Layout?.[ROLE]
}

export default LayoutContainer