import React, { useState } from "react";
import { Card, CardBody, Input, Button } from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ErrorMessage } from "formik";
import TextError from "../../FormikError/TextError";
import CustomButton from "../FormElements/CustomButton";
import Select from "react-select";
import { getDisease } from "../../../Api/AdminApis";
import { useQuery } from "react-query";
const PatientProfileCard = ({
	title,
	subtitle,
	children,
	id,
	onclickhandler,
	refetchDiseaseData,
	refetchDocumentData,
}: any) => {
	const [showAddForm, setShowAddForm] = useState(false);
	const [name, setDiseaseName] = useState("");
	const [fileName, setFileName] = useState("");
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [nameError, setNameError] = useState("");
	const [fileError, setFileError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const toggleAddForm = () => {
		setShowAddForm(!showAddForm);
		// Clear errors when the form is toggled
		setNameError("");
		setFileError("");
	};

	const handleDiseaseNameChange = (selectedOption: any) => {
		setDiseaseName(selectedOption ? selectedOption.value : "");
	};

	const handleFileNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setFileName(event.target.value);
	};

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const handleAddDisease = () => {
		if (title === "Documents" || title === "Optometry") {
			if (selectedFile && fileName.trim() !== "") {
				// Call the onclickhandler for adding a document
				onclickhandler(
					id,
					fileName,
					selectedFile,
					refetchDocumentData,
					setIsLoading
				);
				// Clear the file inputs and close the form after adding the document
				setFileName("");
				setSelectedFile(null);
				toggleAddForm();
			} else {
				setFileError("Please provide a file and file name.");
			}
		} else {
			// Handle adding a disease here based on title being 'Disease History'
			if (name.trim() !== "") {
				onclickhandler(id, name, refetchDiseaseData, setIsLoading);
				// Clear the name input and close the form after adding the disease
				setDiseaseName("");
				toggleAddForm();
			} else {
				setNameError("Disease name is required.");
			}
		}
	};

	const { data: diseaseData, error: diseaseError, isLoading: diseaseLoading, refetch: diseaseListRefetch } = useQuery(
		["disease", currentPage, searchQuery],
		() => getDisease(currentPage, searchQuery),
		{
			keepPreviousData: true,
		}
	);

	const handleInputChange = (inputValue: string) => {
		setSearchQuery(inputValue);
		setCurrentPage(1); // Reset to first page on new search query
	};
	const diseaseOptions = diseaseData && diseaseData.data
		? diseaseData.data.map((disease: any) => ({ value: disease.name, label: disease.name }))
		: [];

	return (
		<Card className="widget-card patient-details-full-wrap">
			<CardBody>
				<div className="widget-head-outer">
					<div className="widget-heading">
						<small>{subtitle}</small>
						<h6>{title}</h6>
					</div>
					<div className="icon-container">
						<Link to="" onClick={toggleAddForm}>
							{showAddForm ? (
								<i className="icon icon-close xl" title="Cancel"></i>
							) : (
								<i className="icon icon-plus-gray xl" title="Add"></i>
							)}
						</Link>
					</div>
				</div>
				{showAddForm && (
					<div className="add-form">
						{title === "Documents" || title === "Optometry" ? (
							<div>
								<div className="upload-text">
									<small><i className="icon icon-warning-validation"></i>&nbsp;Upload PDF / DOC / DOCX Files.</small>
								</div>
								<Input
									type="text"
									id="fileName"
									value={fileName}
									onChange={handleFileNameChange}
									placeholder="Enter file name"
								/>
								<Input
									type="file"
									id="file"
									accept=".pdf,.doc,.docx"
									onChange={handleFileSelect}
								/>

								<div className="error-outer">
									<div className="errors validation-danger">
										{fileError}
									</div>
								</div>
							</div>
						) : (
							<div>
								<Select
									id="name"
									value={diseaseOptions.find((option: any) => option.value === name) || null}
									onChange={handleDiseaseNameChange}
									options={diseaseOptions}
									placeholder="Enter disease name"
									isLoading={diseaseLoading}
									onInputChange={handleInputChange}
								/>
								<div className="error-outer">
									<div className="errors validation-danger">
										{nameError}
									</div>
								</div>
							</div>
						)}
						<CustomButton
							color="secondary"
							label={
								title === "Documents" || title === "Optometry"
									? "Add Document"
									: "Add Disease"
							}
							className="sm mt-2"
							// outline
							handleSubmit={handleAddDisease}
							isLoading={false}
						/>
						{/* {title === "Documents" || title === "Optometry"? "Add Document" : "Add Disease"} */}
					</div>
				)}
				{children}
			</CardBody>
		</Card>
	);
};

export default PatientProfileCard;