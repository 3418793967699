import { ErrorMessage, Formik } from "formik";
import { useState, useRef } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { getDoctorListBooking, getPatientListByPhone } from "../../../../../Api/AdminApis";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../Components/FormikError/TextError";
import { AddAppointmentType } from "../../../../../Type";
import { gender_data } from "../../../../../Utils/Constants/selection_options";
import { AddAppointmentValidationSchema } from "./AddAppointmentFormikMethods";
import {
  AddAppointmentInitializer,
  appointmentSubmitHandler,
  handlePatientExistSelect,
  handlePhoneChange,
  handleShowPatientNameInput
} from "./AddAppointmentMethods";
import useFocusOnLoad from "../../../../../Hooks/useFocusOnLoad";

const AddAppointment = ({
  selectedDate = "",
  setIsModalOpen,
  refetch,
  departmentList,
  departmentLoading,
}: AddAppointmentType) => {

  const patientPhoneInputRef: any = useFocusOnLoad()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPatientNameInput, setShowPatientNameInput] = useState<boolean>(false)
  const [depart, SetDepart] = useState("")
  const initialValues = AddAppointmentInitializer(selectedDate);

  const { data: patientList = { selectData: [], fullData: [] }, isLoading: patientLoading }: any = useQuery([`PatientList${phoneNumber}`, phoneNumber],
    () => getPatientListByPhone(phoneNumber),
    {
      keepPreviousData: true,
      enabled: phoneNumber.length === 10 || phoneNumber.length === 12,
    }
  );

  // Doctor List
  const { data: doctorList, isLoading: doctorLoading, refetch: refetchDoctorList }: any = useQuery(
    [`doctorListDocBooking${depart}`, depart],
    () => getDoctorListBooking(depart),
    {
      staleTime: 15000,
      keepPreviousData: true
    }

  );

  return (
    <Formik
      validationSchema={AddAppointmentValidationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        appointmentSubmitHandler(values, actions, setIsModalOpen, refetch);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldTouched,
        setValues,
        resetForm
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col md={6}>
                  <Label>Patient mobile</Label>
                  <Input
                    type="text"
                    onChange={(e: any) => handlePhoneChange(e, handleChange, setPhoneNumber, setFieldTouched, resetForm, setShowPatientNameInput)}
                    name="patient_mobile"
                    value={values?.patient_mobile}
                    placeholder="Phone Number"
                    innerRef={patientPhoneInputRef}
                  />
                  <ErrorMessage name="patient_mobile" component={TextError} />
                </Col>

                <Col lg={6}>
                  <Label>Patient name</Label>
                  {(showPatientNameInput || patientList?.selectData?.length === 0) ? (
                    <Input
                      type="text"
                      disabled={phoneNumber?.length !== 10 && phoneNumber?.length !== 12}
                      onChange={handleChange}
                      value={values?.patient_name}
                      name="patient_name"
                      placeholder="Full Name"
                    />
                  ) : (
                    <Select
                      placeholder="Select Patient"
                      isDisabled={phoneNumber?.length !== 10 && phoneNumber?.length !== 12}
                      name="patient_id"
                      options={[
                        ...patientList?.selectData,
                        {
                          values: null,
                          label: <span onClick={() => handleShowPatientNameInput(setShowPatientNameInput)}>Add new patient +</span>
                        }
                      ]}
                      value={patientList?.selectData?.filter(
                        (itm: any) => itm?.value === values?.patient_id
                      )}
                      onChange={(e: any) => handlePatientExistSelect(setFieldValue, e, setValues, patientList)}
                      isLoading={patientLoading}
                      loadingMessage={() => "Loading"}
                      noOptionsMessage={() => "Patients appears here"}
                      isClearable
                      isSearchable
                    />
                  )}
                  <ErrorMessage name="patient_name" component={TextError} />
                </Col>

                <Col lg={6}>
                  <Label>Patient date of birth</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    value={values?.patient_dob}
                    name="patient_dob"
                    placeholder="Date of Birth"
                  />
                  <ErrorMessage name="patient_dob" component={TextError} />
                </Col>

                <Col lg={6}>
                  <Label>Patient email</Label>
                  <Input
                    type="email"
                    onChange={handleChange}
                    value={values?.patient_email}
                    name="patient_email"
                    placeholder="Email"
                  />
                  <ErrorMessage name="patient_email" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Patient gender</Label>
                  <Select
                    placeholder="Select Gender"
                    name="patient_gender"
                    options={gender_data}
                    value={
                      gender_data?.filter(
                        (itm: any) => itm?.value === values?.patient_gender
                      ) || ""
                    }
                    onChange={(e: any) =>
                      setFieldValue("patient_gender", e?.value)
                    }
                    isLoading={false}
                    loadingMessage={() => "Fetching Gender"}
                    noOptionsMessage={() => "Gender appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="patient_gender" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Patient address</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    name="patient_address"
                    value={values?.patient_address}
                    placeholder="Address"
                  />
                  <ErrorMessage name="patient_address" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Department</Label>
                  <Select
                    placeholder="Select Department"
                    name="department_id"
                    options={departmentList}
                    value={departmentList?.filter(
                      (itm: any) => itm?.value === values?.department_id
                    )}
                    onChange={(e: any) => {
                      setFieldValue("department_id", e?.value);
                      SetDepart(e?.value);
                      refetchDoctorList();
                    }}
                    isLoading={departmentLoading}
                    loadingMessage={() => "Loading"}
                    noOptionsMessage={() => "Departments appears here"}
                    isSearchable
                  />
                  <ErrorMessage name="department_id" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Doctor</Label>
                  <Select
                    placeholder="Select Doctor"
                    name="doctor_id"
                    options={doctorList}
                    value={doctorList?.filter(
                      (itm: any) => itm?.value === values?.doctor_id
                    )}
                    onChange={(e: any) => setFieldValue("doctor_id", e?.value)}
                    isLoading={doctorLoading}
                    loadingMessage={() => "Loading"}
                    noOptionsMessage={() => "Currently no doctors are available in this department"}
                    isSearchable
                  />
                  <ErrorMessage name="doctor_id" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Booking date</Label>
                  <Input
                    type="date"
                    onChange={(e) => {
                      setFieldTouched('booking_date')
                      handleChange(e)
                    }}
                    name="booking_date"
                    value={values?.booking_date}
                    placeholder="Booking Date"
                  />
                  <ErrorMessage name="booking_date" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Booking time</Label>
                  <Input
                    type="time"
                    onChange={handleChange}
                    name="booking_time"
                    value={values?.booking_time}
                    placeholder="Booking Time"
                  />
                  <ErrorMessage name="booking_time" component={TextError} />
                </Col>
                <Col md={6}>
                  <div className="check-flex-outer">
                    <div className="check-flex-wrap">
                      <Input
                        type="checkbox"
                        onChange={handleChange}
                        name="payment_confirm"
                        value={values?.payment_confirm}
                        id="check_payment"
                      />
                      <label htmlFor="check_payment">Appointment Confirm</label>
                    </div>
                  </div>
                  <ErrorMessage name="payment_confirm" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Confirm"
                    color="secondary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddAppointment;
