const constants = {
  USER_ROLE: {
    ADMIN: 1,
    USER: 2,
    DOCTOR_USER: 3,
    STAFF_USER: 4,
    LAB_USER:5,
    OPTOMETRY_USER:6,
  },

  STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },

  STATUS_APPOINMENTS: {
    CONFIRM: "confirmed",
    PENDING: "pending",
    CANCELLED: "cancelled",
    FINISHED: "finished",
    LWBS: "lwbs",
  },

  DOCTOR_PROFILE_TILE_TYPES: {
    EDUCATION: "EDUCATION",
    SKILL: "SKILL",
    EXPERIENCE: "EXPERIENCE",
  },
  DEFAULT_IMAGE: {
    IMAGE:
      "/images/patient-avatar.webp",
  },
  META_STATUS: {
    ACTIVE: "1",
    INACTIVE: "2",
  },
};

export default constants;
