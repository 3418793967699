import React from "react";
import { Row, Col } from "reactstrap";
import ProfileCard from "./Cards/ProfileCard";
import PatientProfileCard from "../../../../../Components/CustomElements/Cards/PatientProfileCard";
import DiseasehistoryTile from "../../../../../Components/CustomElements/DiseasehistoryTile";
import DocumentsTile from "../../../../../Components/CustomElements/DocumentsTile/DocumentsTile";
import { PatientProfileProps } from "../../../../../Type";
import {
  getDiseaseHistory,
  getPatientDocuments,
  getPatientOptometry,
} from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { handleDiseaseHistoryClick } from "./AddDiseaseMethods/AddDeleteMethods";
import { handleDocumentsClick } from "./AddDiseaseMethods/AddDeleteMethods";
import { handleOptometryClick } from "./AddDiseaseMethods/AddDeleteMethods";
import { Link } from "react-router-dom";

const PatientProfileCardContainer = ({ patient }: PatientProfileProps) => {
  // Fetch the disease history
  const {
    data: diseaseList,
    isLoading: diseaseLoading,
    refetch: refetchDiseaseData,
  } = useQuery("disease", () => getDiseaseHistory(patient?.id));

  // Fetch the documents
  const {
    data: documentList,
    isLoading: documentLoading,
    refetch: refetchDocumentData,
  } = useQuery("document", () => getPatientDocuments(patient?.id));
  const {
    data: optometryList,
    isLoading: optometryLoading,
    refetch: refetchOptometryData,
  } = useQuery("optometry", () => getPatientOptometry(patient?.id));

  return (
    <div>
      <Row className="gy-4">
        <Col xxl={12}>
          <ProfileCard data={patient} />
        </Col>

        <Col xxl={12}>
          <PatientProfileCard
            title="Disease History"
            subtitle="Previous"
            id={patient?.id}
            onclickhandler={handleDiseaseHistoryClick}
            refetchDiseaseData={refetchDiseaseData}
          >
            <div className="disease-card-outer">
              {diseaseLoading
                ? "Loading disease history..."
                : diseaseList &&
                  diseaseList?.data?.map((item: { id: any; name: any }) => (
                    <DiseasehistoryTile
                      id={item?.id}
                      name={item?.name}
                      refetchDiseaseData={refetchDiseaseData}
                    />
                  ))}
              {/* {diseaseList?.data?.length > 4 ? (
                <Link to="#">Show more</Link>
              ) : (
                ""
              )} */}
            </div>
          </PatientProfileCard>
        </Col>

        <Col xxl={12}>
          <PatientProfileCard
            title="Documents"
            subtitle="Previous"
            id={patient?.id}
            onclickhandler={handleDocumentsClick}
            refetchDocumentData={refetchDocumentData}
          >
            <div className="disease-card-outer">
              {documentLoading
                ? "Loading documents..."
                : documentList &&
                  documentList?.data?.map(
                    (item: {
                      id: any;
                      file_name: any;
                      updated_at: any;
                      file_path: any;
                    }) => (
                      <DocumentsTile
                        key={item?.id}
                        name={item?.file_name}
                        file_url={item?.file_path}
                      />
                    )
                  )}
            </div>
          </PatientProfileCard>
        </Col>
        <Col xxl={12}>
          <PatientProfileCard
            title="Optometry"
            subtitle="Previous"
            id={patient?.id}
            onclickhandler={handleOptometryClick}
            refetchDocumentData={refetchOptometryData}
          >
            <div className="disease-card-outer">
              {optometryLoading
                ? "Loading documents..."
                : optometryList &&
                optometryList?.data?.map(
                    (item: {
                      id: any;
                      file_name: any;
                      updated_at: any;
                      file_path: any;
                    }) => (
                      <DocumentsTile
                        key={item?.id}
                        name={item?.file_name}
                        file_url={item?.file_path}
                      />
                    )
                  )}
            </div>
          </PatientProfileCard>
        </Col>
      </Row>
      
    </div>
  );
};

export default PatientProfileCardContainer;
