import { useMemo, useState } from "react";
import { Button, Container, Input, InputGroup } from "reactstrap";
import ModalContainer from "../../../Components/Containers/ModalContainer/ModalContainer";
import AddPermission from "./AddPermission";
import DataList from "../../../Components/Lists/PatientList";

const PermissionList = () => {
  let headers = useMemo(
    () => [
      { label: "Id", value: "id" },
      { label: "Permission Name", value: "name" },
    ],
    []
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <section className="team">
        <div className="team-home-outer-wrap">
          <Container>
            <div className="search-bar-full-wrap">
              <div className="search-bar">
                <InputGroup>
                  <Input
                    placeholder="Search here..."
                    // value={searchQuery}
                    // onChange={handleInputChange}
                  />
                  <span className="icon-container">
                    <i className="icon svg-icon icon-search"></i>
                  </span>
                </InputGroup>
              </div>
              <div className="right-side-wrap">
                <div className="filter-wrap">
                  <Button color="secondary" outline className="default squared" title="Filter">
                    <i className="icon icon-filter xl"></i>
                  </Button>
                  <Button color="secondary" outline className="default squared">
                    <i className=" icon icon-sort xl "></i>
                  </Button>

                  <div className="button-wrap">
                    <Button
                      onClick={() => setIsOpen(true)}
                      color="secondary"
                      className="sm"
                      outline
                    >
                      <i className="icon icon-plus xl"></i>Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <div className="team-list-outer-wrap">
            <DataList
              table_headers={headers}
              api_name="/permission"
              edit={true}
              delete_data={true}
              page_from={"staffList"}
              title={"Permission"}
            />
          </div>
        </div>
        <ModalContainer
          isOpen={isOpen}
          setIsopen={setIsOpen}
          title="Add Permission"
        >
          <AddPermission setIsOpen={setIsOpen} />
        </ModalContainer>
      </section>
    </>
  );
};

export default PermissionList;
