/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "react-query";
import { InputGroup, Input, Nav, NavItem, NavLink } from "reactstrap";
import FinishedAppoinment from "./AddAppointment/FinishedAppoinment";
import { getAdminAppointmentFinishedList } from "./AppointmentMethods";
import AppointmentIndex from ".";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

export default function AppoinmentTab() {
  const [searchQuery, setSearchQuery] = useState("");
  const { tab = '1', page_no = 1 } = useParams()
  const navigate = useNavigate()
  const {
    data: adminAppointmentList,
    isLoading: isAdminAppointmentLoading,
    refetch: adminAppointmentListRefetch,
    error: adminAppointmentError,
  }: any = useQuery(
    [`adminAppointment`, tab+ searchQuery],
    () => getAdminAppointmentFinishedList(tab === '2' ? `status=finished&page=${page_no}` : "",searchQuery),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <div className="search-bar-full-wrap">
        <div className="top-widget-tabs">
          <Nav pills>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === '1' ? "active" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/admin/appointment/1")}
              >
                Confirmed Appointment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === '2' ? "active" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/admin/appointment/2")}
              >
                Finished Appointment
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {tab === '2' && (
          <>
            <div className="search-bar">
              <InputGroup>
                <Input placeholder="Search By Name / Mobile" 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}/>
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
          </>
        )}
      </div>
      {tab === '1' ? <AppointmentIndex />
        :
        <FinishedAppoinment
          appoinmetData={adminAppointmentList}
          appoinmetLoading={isAdminAppointmentLoading}
          appoinmetRefetch={adminAppointmentListRefetch}
          appoinmetError={adminAppointmentError}
        />
      }
    </>
  );
}
