import React from "react";
import { Button, Spinner } from "reactstrap";
import { CustomButtonProps } from "../../../Type";
import CustomSpinner from "../Spinner/SpinnerCustom";

const CustomButton = ({ label, isLoading, className, color,disabled, handleSubmit }: CustomButtonProps) => {

  return (
    <Button
      type="submit"
      className={className}
      color={color}
      disabled={isLoading|| disabled}
      onClick={handleSubmit}
    >
      {isLoading ? <CustomSpinner /> : label}
    </Button>
  );
};

export default CustomButton;
