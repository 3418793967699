import { FormikHelpers } from "formik";
import { api } from "../../../../Api/Api";
import { toast } from "react-toastify";
// import { AddPatientInitialValues } from "./FormikValidation";
import { capitalizeWord, setValidationErrors } from "../../../../Utils/helpers/helpers";

type AddInitialValues = {
  patient_id: any;
  name: string,
  dob: string,
  mobile: string,
  email: string,
  gender: string,
  address: string,
  department: string,
  doctor: string,

};

// export const PatientSubmitHandler = (values: AddInitialValues, actions: FormikHelpers<AddInitialValues>, setIsOpen: Function, refetch: Function, editMode: boolean) => {

//   api.put(`/patient/${values?.patient_id}`, {
//     name: values?.name,
//     dob: values?.dob,
//     address: values?.address,
//     mobile: values?.mobile,
//     gender: values?.gender,
//     email: values?.email,

//     status: 1,
//   }, true)
//     .then(async function ([success, response]: any) {
//       toast.success('Patient Details Updated ')
//       setIsOpen(false)
//       refetch()
//     })
//     .catch((err) => {
//       actions?.setSubmitting(false)
//       if (err?.response?.data?.errors) {
//         setValidationErrors(err?.response?.data?.errors, actions)
//       }
//       else {
//         toast.error('Unexpected Error Occurred')
//       }
//     });

  // Add 
  // else {
  //     api.post("/patient", values, true)
  //         .then(async function ([success, response]: any) {
  //             toast.success('Patient Added ')
  //             setIsOpen(false)
  //             refetch()
  //         })
  //         .catch((err) => {
  //             actions?.setSubmitting(false)
  //             if (err?.response?.data?.errors) {
  //                 setValidationErrors(err?.response?.data?.errors, actions)
  //             }
  //             else {
  //                 toast.error('Unexpected Error Occurred')
  //             }
  //         });
  // }
// }

// initializing add patient form - Edit / add 
// export const addModalInitialValuesHandler = (modalEditData: any, setAddModalInitialValues: Function, setEditMode: Function) => {
//   if (modalEditData?.length !== 0) {
//     setEditMode(true)

//     setAddModalInitialValues({
//       name: modalEditData?.[0]?.name,
//       mobile: modalEditData?.[0]?.mobile,
//       dob: modalEditData?.[0]?.dob,
//       email: modalEditData?.[0]?.email,
//       gender: modalEditData?.[0]?.gender,
//       doctor: modalEditData?.[0]?.doctor_name,
//       address: modalEditData?.[0]?.address,
//       department: modalEditData?.[0]?.department_name,
//       patient_id: modalEditData?.[0]?.id,
//     })
//   }
//   else {
//     setEditMode(false)
//     setAddModalInitialValues(AddPatientInitialValues)
//   }
// }


// extract patient data for table
export const extractOptoPatientData = ( data: any) => {
  const res = data?.data?.map((item: any,  i: number) => {
    const slno = data.meta.from + i;
    return {
      "slno": slno,
      "id": item?.patient?.id,
      "name": item?.patient?.name,
      "email": item?.patient?.email,
      "mobile": item?.patient?.mobile,
      "file_name":item?.file_name,
      // "doctor_name": item?.doctor_name,
      // "address": item?.address,
      // "department_name": item?.department_name,
      // "appoinment_id": item?.appoinment_id,
      // "appointment_date_time": item?.appointment_date_time,
    };
  })
  return res
}

// patient table edit onclick trigger 
// export const onEditClickHandler = (selectedId: any, setModalEditData: Function, setIsOpen: Function, patientData: any) => {

//   setModalEditData([])
//   setModalEditData(patientData?.data?.filter((itm: any) => itm?.id == selectedId))
//   setIsOpen(true)

// }

// add patient open / close 
// export const modalHandler = (setModalEditData: Function, setIsOpen: Function) => {
//   setModalEditData([])
//   setIsOpen(true)
// }
// export { AddPatientInitialValues };

