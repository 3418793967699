import React, { useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import {
	handleDiseaseDeleteClick,
	handleDiseaseUpdateClick,
} from "../../../Pages/Private/Admin/Patient/PatientProfile/AddDiseaseMethods/AddDeleteMethods";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	DiseasehistoryTileProps,
	FormValues,
	validate,
} from "./Formikvalidation";
import CustomButton from "../FormElements/CustomButton";

const DiseasehistoryTile: React.FC<DiseasehistoryTileProps> = ({
	id,
	name,
	refetchDiseaseData,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const initialValues: FormValues = {
		updatedName: name,
	};

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleUpdateClick = (values: FormValues) => {
		handleDiseaseUpdateClick(id, values.updatedName, refetchDiseaseData);
		setIsEditing(false);
	};

	return (
		<Card className="disease-card purple">
			<div className="disease-card-flex-wrap-outer">
				{isEditing ? (
					<>
						<div className="disease-card-details edit">
							<Formik<FormValues>
								initialValues={initialValues}
								validate={validate}
								onSubmit={handleUpdateClick}
							>
								<Form>
									<Field
										type="text"
										className="form-control"
										name="updatedName"
									/>
									<ErrorMessage
										name="updatedName"
										component="div"
										className="error-message"
									/>
									<div className="action-btn-wrap submit">
										<Button
											type="submit"
											color="none"
											className="action-btn"
											title="Submit"
										>
											<i className="icon icon-check-green xl"></i>
										</Button>
									</div>
								</Form>
							</Formik>
						</div>
					</>
				) : (
					<>
						<CardBody className="disease-card-flex-wrap">
							<div className="icon-wrap">
								<i className="icon xl icon-doctor-white"></i>
							</div>
							<div className="disease-card-details saved">
								<p title={name}>{name}</p>
							</div>
						</CardBody>
					</>
				)}
				{isEditing ? (
					<div className="action-btn-wrap none">
						<Button
							color="none"
							className="action-btn"
							title="Submit"
							type="button"
							onClick={() => {}}
						>
							<i className="icon icon-check-green xl"></i>
						</Button>
					</div>
				) : (
					<>
						<div className="action-btn-wrap">
							<Button
								color="none"
								className="action-btn"
								title="Edit"
								type="button"
								onClick={handleEditClick}
							>
								<i className="icon icon-edit-blue xl"></i>
							</Button>
							<CustomButton
								color="none"
								className="action-btn"
								label={
									<i className="icon icon-delete-red xl" title="Delete"></i>
								}
								isLoading={isLoading}
								handleSubmit={() =>
									handleDiseaseDeleteClick(
										id,
										refetchDiseaseData,
										setIsLoading
									)
								}
							/>
						</div>
					</>
				)}
			</div>
		</Card>
	);
};
export default DiseasehistoryTile;
