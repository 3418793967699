import { useRef, useState } from "react";
import {
  Button, Col,
  Card,
  CardBody
} from "reactstrap";
import { useQuery } from "react-query";

import { getBannerList } from "../../../../../Api/AdminApis";
import CardsWithIcon from "../../../../../Components/Containers/CardWithIcon";

import ModalContainer from "../../../../../Components/Containers/ModalContainer/ModalContainer";
import {
  StatusClickHandler,
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddBanner/AddBannerMethods/AddBannerMethods";
import AddBanner from "./AddBanner";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import DeleteConfirmation from "../../../../../Components/Containers/AlertModal/webistedelete";
import { ScrollToContainerTop } from "../../../../../Utils/helpers/ScrollToContainerTop";

const BannerList = () => {
  const myDivRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const {
    data: banner,
    error: bannerError,
    isLoading: bannerLoading,
    refetch: bannerListRefetch,
  }: any = useQuery(
    [`banner${currentPage}`, currentPage],
    () => getBannerList(currentPage),
    { keepPreviousData: true }
  );

  const handleDeleteConfirmation = (selectedId: string | number) => {
    setSelectedId(selectedId);
    setIsDeleteConfirmationOpen(true);
  };
  return (
    <>
      <Col lg={12}>
        <Card className="widget-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading" ref={myDivRef}>
                <h6>Banner</h6>
              </div>
              <div className="button-container">
                <Button
                  onClick={() => modalHandler(setModalEditData, setIsOpen)}
                  color="secondary"
                  className="sm"
                  outline
                  title="Add Banner"
                >
                  <i className="icon icon-plus xl"></i> Add
                </Button>
              </div>
            </div>
            <CardsWithIcon
              type={"banner"}
              data={banner?.data}
              tableTitle="Banner"
              tableFields={["Heading", "Sub heading", "Description", "Button"]}
              Arraykeys={[
                "heading",
                "image_url",
                "sub_heading",
                "content",
                "button",
                "created_at",
              ]}
              viewType="table"
              toURL={"/admin/designation-profile"}
              actions={["edit", "delete"]}
              isLoading={bannerLoading}
              error={bannerError}
              onEditClick={(selectedId: any) =>
                onEditClickHandler(
                  selectedId,
                  setModalEditData,
                  setIsOpen,
                  banner
                )
              }
              onDeleteClick={(selectedId: string | number) => handleDeleteConfirmation(selectedId)}
              onStatusClick={(selectedId: string | number, newStatus: any) =>
                StatusClickHandler(selectedId, bannerListRefetch, newStatus)
              }
            />
          </CardBody>
        </Card>
      </Col>
      {banner?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={banner?.meta?.total}
            itemsPerPage={banner?.meta?.per_page}
            activePage={banner?.meta?.current_page}
            setCurrentPage={(pageNo: string) => {
              ScrollToContainerTop(myDivRef)
              setCurrentPage(pageNo)
            }}
          />
        </div>
      )}
      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title={modalEditData?.length === 0 ? "Add  Banner" : "Edit Banner"}>
        <AddBanner
          setIsOpen={setIsOpen}
          refetch={bannerListRefetch}
          modalEditData={modalEditData}
          bannerError={bannerError}
        />
      </ModalContainer>
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        onDelete={() => {
          deleteClickHandler(selectedId, bannerListRefetch);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default BannerList;
