import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

// D
export const AssignDoctorSubmitHandler = (
	selectedId: any,
	refetch: Function
) => {
	api.put(
		`/feedback/${selectedId?.id}`,
		{
			status: selectedId?.status,
		},
		true
	)
		.then(async function ([success, response]: any) {
			toast.success("Published");
			refetch();
		})
		.catch((err) => {
			toast.error("Unexpected Error Occurred");
		});
};

export const extractLabPatientData = (data: any) => {
	const res = data?.data?.map((item: any,  i: number) => {
		const slno = data.meta.from + i;
		return {
			slno: slno,
			id: item?.patient?.id,
			name: item?.patient?.name,
			date: item?.patient?.updated_at,
			email: item?.patient?.email,
			mobile: item?.patient?.mobile,
			file:item?.file_name,
		};
	});
	return res;
};
