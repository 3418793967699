import { ErrorMessage, Formik } from 'formik'
import { useState, useEffect } from 'react'
import { Col, Form, Input, Label, Row } from 'reactstrap'
import TextError from '../../../../../../Components/FormikError/TextError'
import { AddExperienceType } from '../../../../../../Type'
import { ExperienceModalInitialValuesHandler, ExperienceSubmitHandler } from '../../DoctorProfile/Experience/AddExperienceMethods'
import { AddExperienceValidationSchema, AddExperienceInitialValues } from '../../DoctorProfile/Experience/AddExperienceFormikMethods'
import CustomIcon from '../../../../../../Components/CustomElements/FormElements/CustomIcon'

const AddExperienceForm = ({ setIsOpen, doctor_id, refetch, data }: AddExperienceType) => {

    const [editModalInitialValues, setEditModalInitialValues] = useState({ doctor_id: doctor_id, ...AddExperienceInitialValues })
    const [editMode, setEditMode] = useState<boolean>(false)

    useEffect(() => {
        ExperienceModalInitialValuesHandler(data, setEditModalInitialValues, setEditMode, doctor_id)
    }, [data])

    return (
        <Formik
            validationSchema={AddExperienceValidationSchema}
            initialValues={editModalInitialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
                ExperienceSubmitHandler(values, actions, setIsOpen, refetch, editMode, data?.id)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values
            }) => {

                return (

                    <div className="general-info-wrap">
                        <Form className="form-wrap">
                            <div className="form-scroll-wrap">
                                <Row className="gy-3">
                                    <Col lg={12}>
                                        <Label>Hospital Name</Label>
                                        <Input type='text' name='institution' onChange={handleChange} value={values?.institution} placeholder='Hospital Name' />
                                        <ErrorMessage name="institution" component={TextError} />                                                            </Col>
                                    <Col lg={12}>
                                        <div className="check-flex-outer">
                                            <div className="check-flex-wrap">
                                                <Input type="checkbox" ></Input>
                                                <Label>I am currently working in this role</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <Label>Start date</Label>
                                        <Input type='date' className='form-control' name='fromdate' value={values?.fromdate} placeholderText='Start Date' onChange={handleChange} />
                                        <ErrorMessage name="fromdate" component={TextError} />                                                            </Col>
                                    <Col lg={5}>
                                        <Label>End date</Label>
                                        <Input type='date' className='form-control' name='todate' value={values?.todate} placeholderText='End Date' onChange={handleChange} />
                                        <ErrorMessage name="todate" component={TextError} /> 
                                    </Col>
                                    <Col lg={2}>
                                    <div className="action-flex-status-btn centered action-btn-group">
                                        <i className="icon xl icon-close-red" title='Cancel' onClick={() => setIsOpen(false)}></i>
                                        <CustomIcon
															disabled={
																isSubmitting
															}
															isLoading={
																isSubmitting
															}
															handleClick={
																handleSubmit
															}
															label={
																<i className="icon xl icon-check-green"></i>
															}
														/>                                    </div>
                                </Col>
                                </Row>
                            </div>
                            <Row >

                            </Row>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddExperienceForm