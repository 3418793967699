import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../../../Components/FormikError/TextError";
import { AddStaffProps } from "../../../../../Type";

import {
  StaffSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddStaffMethods/AddStaffModalMethods";
import Select from "react-select";
import {
  AddStaffInitialValues,
  StaffValidationSchema,
} from "./AddStaffMethods/FormikValidation";
import { gender_data,user_role } from "../../../../../Utils/Constants/selection_options";

const AddStaff = ({
  setIsOpen,
  departmentList,
  designationList,
  modalEditData,
  refetch,
}: AddStaffProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddStaffInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  return (
    <Formik
      validationSchema={StaffValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        StaffSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={12}>
                  <Label>Full name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.name}
                    name="name"
                    placeholder="Full Name"
                  />
                  <ErrorMessage name="name" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Phone number</Label>
                  <Input
                    type="tel"
                    onChange={handleChange}
                    name="mobile"
                    value={values?.mobile}
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="mobile" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={values?.email}
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Gender</Label>
                  <Select
                    placeholder="Select Gender"
                    name="gender"
                    options={gender_data}
                    value={
                      gender_data?.filter(
                        (itm: any) => itm?.value === values?.gender
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("gender", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Gender"}
                    noOptionsMessage={() => "Gender appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="gender" component={TextError} />
                </Col>




                <Col md={6}>
                  <Label>User Role</Label>
                  <Select
                    placeholder="Select User Role"
                    name="type"
                    options={user_role}
                    value={
                      user_role?.filter(
                        (itm: any) => itm?.value === values?.type
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("type", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching User Role"}
                    noOptionsMessage={() => "User Role appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="type" component={TextError} />
                </Col>





                <Col md={6}>
                  <Label>Date of joining</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    name="joinedAt"
                    value={values?.joinedAt}
                    placeholder="Date of joining"
                  />
                  <ErrorMessage name="joinedAt" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Date of birth</Label>
                  <Input
                    type="date"
                    onChange={handleChange}
                    name="dob"
                    value={values?.dob}
                    placeholder="Date of Birth"
                  />
                  <ErrorMessage name="dob" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Department</Label>
                  <Select
                    placeholder="Select Department"
                    name="departmentId"
                    options={departmentList}
                    value={
                      departmentList?.filter(
                        (itm: any) => itm?.value === values?.departmentId
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("departmentId", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Department"}
                    noOptionsMessage={() => "Department appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="departmentId" component={TextError} />
                </Col>
                <Col md={6}>
                  <Label>Designation</Label>
                  <Select
                    name="designationId"
                    placeholder="Select Desigantion"
                    options={designationList}
                    value={
                      designationList?.filter(
                        (itm: any) => itm?.value === values?.designationId
                      ) || ""
                    }
                    onChange={(e: any) => {
                      setFieldValue("designationId", e?.value);
                    }}
                    isLoading={false}
                    loadingMessage={() => "Fetching Designation"}
                    noOptionsMessage={() => "Desigantion appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="designationId" component={TextError} />
                </Col>
                <Col lg={12}>
                  <CustomButton
                    isLoading={isSubmitting}
                    label="Submit"
                    color="secondary"
                    className="sm submit-btn"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddStaff;
