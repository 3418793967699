import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

// D
export const AssignDoctorSubmitHandler = (selectedId: any, refetch: Function) => {
  api
    .put(
      `/feedback/${selectedId?.id}`,
      {
        status: selectedId?.status,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Published");
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const extractLabPatientData = (data: any) => {
	
	const res = data?.data?.map((item: any, i: number) => {
    const slno = data.meta.from + i;
		return {
      slno: slno,
			id: item?.patient_id?.id,
			name: item?.patient_id?.name,
			date: item?.lab_test_id?.updatedAt,
			optometry: item?.lab_master_id?.name,
			status: item?.status,
		};
	});
	return res;
};
export const deleteConfirm = (id: number, setDeleteModal: Function, setDeleteId: Function) => {
  setDeleteModal(true);
  setDeleteId(id);
};