import { SidebarMenuItemType } from "../../../Type";

const OptometrySidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className="icon svg-icon icon-dashboard"></i>,
    label: "Dashboard",
    path: "/optometry/dashboard",
    // AllPaths: ['/staff/dashboard/appointment/upcoming', '/staff/dashboard/appointment/today', '/staff/dashboard/appointment/previous']
  },
  {
    icon: <i className="icon svg-icon icon-bookings"></i>,
    label: "Booking",
    path: "/optometry/booking",
  },
  {
    icon: <i className="icon svg-icon icon-patient"></i>,
    label: "Patients",
    path: "/optometry/optometrytest",
  },
  {
    icon: <i className="icon svg-icon icon-documents"></i>,
    label: "Observations",
    path: "/optometry/optometryreports",
  },
  {
    icon: <i className="icon svg-icon icon-settings"></i>,
    label: "Settings",
    path: "/optometry/settings/1",
  },
];

export { OptometrySidebarMenuItems };
