import * as Yup from "yup";

export const ForgotValidationSchema = Yup.object().shape({
  name: Yup.string().required("Laboratory name is required"),
  unit: Yup.string().required("Laboratory unit is required"),
  range: Yup.string().required("Laboratory range is required"),
  amount: Yup.number().required("Laboratory amount is required"),
});

export const AddLaboratoryInitialValues = {
  name: "",
  unit: "",
  range: "",
  amount: "",
};
