import { useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { useQuery } from "react-query";
import { getDesignation } from "../../../../Api/AdminApis";
import {
  deleteClickHandler, modalHandler,
  onEditClickHandler
} from "./AddDesignation/AddDesignationMethods/AddDesigantionModalMethods";
import AddDesigantion from "./AddDesignation";
import { handleSettingsDesignationDepartmentSearch } from "./SettingsDesignationMethods";

const DesignationIndex = () => {
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: designation,
    error: designationError,
    isLoading: designationLoading,
    refetch: designationListRefetch,
  }: any = useQuery(["designation", currentPage, searchQuery], () => getDesignation(currentPage, searchQuery), {
    keepPreviousData: true
  });

  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search By Name"
                  onChange={(e) => handleSettingsDesignationDepartmentSearch(e, setSearchQuery, setCurrentPage)}
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                    title="Add Designation"
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="team-list-outer-wrap">
          <CardsWithTable
            data={designation?.data}
            tableTitle="Designation Details"
            tableFields={["Id", " Designation Name"]}
            Arraykeys={["id", "name"]}
            viewType={'table'}
            toURL={"/admin/designation-profile"}
            actions={["edit", "delete"]}
            isLoading={designationLoading}
            error={designationError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                designation
              )
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, designationListRefetch)
            }
            paginationData={designation?.meta}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length === 0
            ? "Add Designation"
            : "Edit Designation"
        }
      >
        <AddDesigantion
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={designationListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default DesignationIndex;
