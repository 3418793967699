import { useState, useEffect } from "react";
// import DatePicker from 'react-datepicker'
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import Fullcalender from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useRef } from "react";
import {
	eventClick,
	handleDateRangeChange,
	handleEventDrop,
} from "./FullCalendarMethods";
import { Link } from "react-router-dom";
import {
	capitalizeFirst,
	customDayHeaders,
	getAppointmentCardStatusStyle,
} from "../../Utils/helpers/helpers";
import { USER_ROLES } from "../../Utils/AuthenticationConstants";
import dayjs from 'dayjs';

const FullCalendar = ({
	onDateClick,
	data,
	urlTo,
	setViewDates,
	isLoading,
	refetch,
}: any) => {
	const calendarComponentRef: any = useRef(null);
	const [eventCounts, setEventCounts] = useState<any>({}); // Counts of events for each day

	useEffect(() => {
		const counts: any = {};
		data?.forEach((event: any) => {
			// Use dayjs to format the date correctly
			const eventDate = dayjs(event.start).format("YYYY-MM-DD");
			counts[eventDate] = (counts[eventDate] || 0) + 1;
		});
		setEventCounts(counts);
	}, [data]);

	// const today = new Date();
	// const validRange = {
	// 	start: today.toISOString().split("T")[0], // Use today's date as the start date
	// 	end: "2099-12-31", // Use a far future date as the end date or limit it to a specific date range
	// };
	const eventContent = ({ event }: any) => {
		return (
			<Link
				to={
					localStorage.getItem("ROLE") === USER_ROLES?.DOCTOR ||
					localStorage.getItem("ROLE") === USER_ROLES?.LAB 
						? `${urlTo}?patientID=${event?._def?.publicId}&appoinemnetID=${event?.extendedProps?.appointment_id}&doctorId=${event?.extendedProps?.doctor_id}`
						: `${urlTo}/${event?._def?.publicId}`
				}
			>
				<Card
					className={`appointment-card ${getAppointmentCardStatusStyle(
						event?.extendedProps?.appointment_status
					)}`}
					title={capitalizeFirst(
						getAppointmentCardStatusStyle(
							event?.extendedProps?.appointment_status
						)
					)}
				>
					<CardHeader>
						<small>{event?.title}</small>
						<small className="status">
							{capitalizeFirst(
								getAppointmentCardStatusStyle(
									event?.extendedProps?.appointment_status
								)
							)}
						</small>
					</CardHeader>
					<CardBody>
						<div className="doctor-name">
							<small>{event?.extendedProps?.doctor}</small>
							<small className="time">
								{event.start.toLocaleTimeString()}
							</small>
						</div>
					</CardBody>
				</Card>
			</Link>
		);
	};
	const CustomDayCell = ({ date }: any) => {
		const cellDate = date;

		// Use `cellDate` to determine how to render the cell
		// For example, you can compare `cellDate` with today's date
		const today = new Date();
		const isToday =
			cellDate.getFullYear() === today.getFullYear() &&
			cellDate.getMonth() === today.getMonth() &&
			cellDate.getDate() === today.getDate();
			const isAfterToday = cellDate > today;
			const formattedDate = new Intl.DateTimeFormat("en-US", {
				day: "numeric"
			  }).format(cellDate);
		if (isToday || isAfterToday) {
			return (
				<div>
					<span> {date.getDate()}</span>
					{localStorage.getItem("ROLE") ===
					USER_ROLES?.DOCTOR ? null : (
						localStorage.getItem("ROLE") === USER_ROLES?.LAB ? null
						: (
							localStorage.getItem("ROLE") === USER_ROLES?.OPTOMETRY ? null : (
						<Card className="appointment-card add-appointment-btn-card new mt-5">
							<CardBody>
								<Button
									color="secondary"
									className="sm add-btn"
									title="Add Appointment"
									outline
									onClick={eventClick}
								>
									<i className="icon icon-plus-gray xl"></i>
								</Button>
							</CardBody>
						</Card>
						)
					)
					)}
				</div>
			);
		} else {
			return (
				<div>
				  <span>{formattedDate}</span>
				</div>
			  );
		}
	};

	const dayCellContent = ({ date }: any) => {
		return <CustomDayCell date={date} />;
	};

	const dayHeaderContentHandler = (date: Date) => {
		const formattedDate =
			customDayHeaders[date.toLocaleString("en-US", { weekday: "long" })];
		const viewable: boolean =
			calendarComponentRef?.current?.getApi()?.view?.type !==
			"dayGridMonth";
		const eventCount = eventCounts[dayjs(date).format("YYYY-MM-DD")] || 0;
		

		return (
			<div>
				<div>{formattedDate}</div>
				{viewable && (
					<div>
						{eventCount || 0}{" "}
						{eventCount === 1 ? "Patient" : "Patients"}
					</div>
				)}
			</div>
		);
	};

	return (
		<Fullcalender
			plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
			initialView={"dayGridMonth"}
			dayHeaderContent={({ date }) => dayHeaderContentHandler(date)}
			headerToolbar={
				{
					// start: "prev today next", // will normally be on the left. if RTL, will be on the right
					// center: "title",
					// end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
					// end: "dayGridMonth",
				}
			}
			eventContent={eventContent}
			height={"90vh"}
			rerenderDelay={10}
			eventDurationEditable={false}
			editable={true}
			dateClick={onDateClick}
			dayCellContent={dayCellContent}
			datesSet={(dateInfo) =>
				handleDateRangeChange(dateInfo, setViewDates)
			}
			droppable={true}
			ref={calendarComponentRef}
			events={data}
			loading={isLoading}
			eventClick={eventClick}
			eventDrop={(info: any) => {
				handleEventDrop(info, data, refetch);
			}}
			slotMinTime="08:00:00" // Replace with your desired minimum time
			slotMaxTime="21:00:00" // Replace with your desired maximum time
			allDaySlot={false}
			// validRange={validRange}
		/>
	);
};

export default FullCalendar;
