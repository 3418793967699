import { toast } from "react-toastify";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { api } from "../../../../../../Api/Api";

export const ExperienceSubmitHandler = async (values: any, actions: any, setIsOpen: Function, refetch: Function, editMode: boolean, id: string) => {
    // try {
    //     const [success] = await api.post("/experience", values, true);

    //     if (success) {
    //         toast.success('Experience Added');
    //         setIsOpen(false);
    //         refetch();
    //     } else {
    //         toast.error('Failed to Add Experience');
    //     }
    // } catch (err: any) {
    //     actions?.setSubmitting(false);

    //     if (err?.response?.data?.errors) {
    //         setValidationErrors(err?.response?.data?.errors, actions);
    //     } else {
    //         toast.error('Unexpected Error Occurred');
    //     }
    // }

    if (editMode) {
        // Edit 
        api.put(`/experience/${id}`, values, true)
            .then(async function ([success, response]: any) {
                toast.success('Experience Updated ')
                setIsOpen(false)
                refetch()
            })
            .catch((err) => {
                actions?.setSubmitting(false)
                if (err?.response?.data?.errors) {
                    setValidationErrors(err?.response?.data?.errors, actions)
                }
                else {
                    toast.error('Unexpected Error Occurred')
                }
            });
    }
    else {
        // Add
        try {
            const [success] = await api.post("/experience", values, true);
            if (success) {
                toast.success('Experience Added');
                setIsOpen(false);
                refetch();
            } else {
                toast.error('Failed To Add Experience');
            }
        } catch (err: any) {
            actions?.setSubmitting(false);

            if (err?.response?.data?.errors) {
                setValidationErrors(err?.response?.data?.errors, actions);
            } else {
                toast.error('Unexpected Error Occurred');
            }
        }
    }
};

export const ExperienceModalInitialValuesHandler = (data: any, setEditModalInitialValues: Function, setEditMode: Function, doctor_id: string | undefined) => {

    if (data?.length !== 0) {
        setEditMode(true)
        setEditModalInitialValues({
            doctor_id: doctor_id,
            fromdate: data?.fromdate || '',
            todate: data?.todate || '',
            institution: data?.institution || ''
        })
    }
    else {
        setEditMode(false)
        setEditModalInitialValues({
            doctor_id: doctor_id,
            fromdate: '',
            todate: '',
            institution: ''
        })
    }
}