import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  InputGroup,
  Input,
  Table,
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Button,
  Label,
  Form,
} from "reactstrap";
import {
  getAdminLaboratoryResult,
  getLaboratory,
} from "../../../../Api/AdminApis";
import { extractLabPatientData } from "../Appoinments/AppoinmentMethods/AppoinmentSubmitMethods";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
const LabReports = ({ ...args }) => {
  // Filter collapse
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [start_date, setStartDate] = useState("");
  const [searchLabTest, setSearchLabTest] = useState<string>("");
  const { data: labAdminpatients, refetch: DashboardAppointments }: any =
    useQuery(
      [
        `adminlabview${currentPage + searchQuery + start_date + searchLabTest}`,
        currentPage,
        searchQuery,
        start_date,
        searchLabTest,
      ],
      () =>
        getAdminLaboratoryResult(
          currentPage,
          searchQuery,
          start_date,
          searchLabTest
        ),
      {
        staleTime: 20000,
        keepPreviousData: true,
      }
    );
  const patientData = useMemo(
    () => extractLabPatientData(labAdminpatients),
    [labAdminpatients]
  );
  const toggle = () => setIsOpen(!isOpen);

  const {
    data: labTests,

    refetch,
  } = useQuery(["labTests"], () => getLaboratory(1, ""), {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="search-bar-full-wrap align-end">
        <div className="search-bar">
          <InputGroup>
            <Input
              placeholder="Search By Patient Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="icon-container">
              <i className="icon svg-icon icon-search"></i>
            </span>
          </InputGroup>
        </div>
        <div className="input-select-wrapper">
          <div className="input-select-wrap">
            <InputGroup>
              <Input
                type="select"
                value={searchLabTest}
                onChange={(e) => setSearchLabTest(e.target.value)}
              >
                <option value="">Select a lab test</option>
                {labTests &&
                  labTests.data.map((test: any) => (
                    <option key={test.id} value={test.name}>
                      {test.name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </div>
          <div className="form-wrap">
            <Label>Appointment Date</Label>
            <Input
              type="date"
              name="start_date"
              onChange={(e: any) => {
                setCurrentPage(1);
                setStartDate(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="table-outer-wrap">
        <div className="table-wrap">
          <Table striped>
            <CardsWithTable
              data={patientData}
              tableTitle="Laboratory Results"
              tableFields={[
                "Sl No",
                "Patient Id",
                "Patient Name",
                "Date",
                "Test Name",
                "Status",
              ]}
              Arraykeys={["slno", "id", "name", "date", "optometry", "status"]}
              viewType="table"
              toURL={`/lab/patient-profile`}
              actions={["confirm"]}
              paginationData={labAdminpatients?.meta}
              setCurrentPage={setCurrentPage}
              isLoading={false}
              error={undefined}
              onEditClick={() => {}}
              onDeleteClick={() => {}}
              ActionsLabel=""
            />
          </Table>
        </div>
      </div>
    </>
  );
};

export default LabReports;
