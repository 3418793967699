import React, { useState } from 'react';
import { Button , List} from 'reactstrap';
import DeleteAlertModal from '../../../../../../Components/Containers/AlertModal/DeleteAlertModal';
import ModalContainer from '../../../../../../Components/Containers/ModalContainer/ModalContainer';
import { AddSkillTabType } from '../../../../../../Type';
import constants from '../../../../../../Utils/Constants/Constants';
import { deleteClickHandler } from '../../DoctorProfile/Education/EducationMethods';
import { deleteConfirm } from '../../DoctorProfile/ProfileTiles/DetailsTimeMethods';
import { handleAddEdu, handleEdit } from '../AddDoctorMethods/AddDoctorModalMethods';
import AddSkillForm from './AddSkillForm';

const AddSkillTab: React.FC<AddSkillTabType> = ({ data, doctor_id, refetch, setActiveTab }) => {
    const [editData, setEditData] = useState<any>([])
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false)
    const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();

    return (
        <div className='add-doctor-form-wrap'>
            <div className='scroller'>
                <div>
                    <List className='doc-list'>
                    {data?.skills?.map((sk: any, i: number) => {
                        return (
                            <li key={i}>
                                <p title={sk?.skill}>{sk?.skill}</p>
                                <div  className='action-flex-wrap right-absolute'>
                                    <span onClick={() => handleEdit(sk, setEditData, setIsAddOpen)} title='Edit'><i className='icon icon-edit-blue xl'></i></span>
                                    <span onClick={() => deleteConfirm(sk?.id, setDeleteModal, setDeleteId)} title='Delete'><i className='icon icon-delete-red xl'></i></span>
                                </div>
                            </li>
                        )
                    })}
                    </List>
                </div>
                {isAddOpen &&
                <AddSkillForm
                    data={editData}
                    doctor_id={doctor_id}
                    refetch={refetch}
                    setIsOpen={() => setIsAddOpen(false)}
                />}
                {!isAddOpen &&
                    <div className="add-more-outer">
                        <Button className="add-more-button" color="none" onClick={() => handleAddEdu(setEditData, setIsAddOpen)} ><i className='icon icon-plus-gray xl' title='Add More'></i></Button>
                    </div>}
            </div>


            <div>
                <Button className="sm next-btn" color="secondary" onClick={() => setActiveTab((prev: number) => prev + 1)}>Next</Button>
            </div>

            <ModalContainer isOpen={openDeleteModal} setIsopen={setDeleteModal} title="">
                <DeleteAlertModal
                    setModal={setDeleteModal}
                    onDeleteClick={(id: string) => deleteClickHandler(constants?.DOCTOR_PROFILE_TILE_TYPES?.SKILL, id, refetch)}
                    delete_id={deleteId}
                />
            </ModalContainer>
        </div>
    )
}

export default AddSkillTab
