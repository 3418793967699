import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Input, InputGroup } from "reactstrap";
import {
  getDepartmentFullList,
  getDesignationFullList,
  getDoctorList,
} from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddDoctor from "./AddDoctor";
import {
  deleteClickHandler,
  doctorTableFields,
  doctorTableKeys,
  extractDoctorsData,
  modalHandler,
  onEditClickHandler,
} from "./AddDoctor/AddDoctorMethods/AddDoctorModalMethods";
import { handleDoctorSearch, setCurrentPageAdminDoctor } from "./DoctorMethods";

const DoctorsIndex: React.FC = () => {
  const navigate = useNavigate();
  const { viewType = "table", currentPage = "1" } = useParams();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [search, setSearch] = useState<string>("");

  const {
    data: doctorData,
    isError: doctorError,
    isLoading: doctorLoading,
    isFetching: doctorIsFetching,
    refetch: doctorListRefetch,
  }: any = useQuery(
    [`doctorData${currentPage + search}`, currentPage, search],
    () => getDoctorList(currentPage, search),
    { keepPreviousData: true }
  );

  const doctData = useMemo(
    () => extractDoctorsData(doctorData || []),
    [doctorData]
  );

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "department",
    () => getDepartmentFullList(),
    { staleTime: 15000 }
  );

  // Designation List
  const {
    data: designationList,
    error: designationError,
    isLoading: designationLoading,
  }: any = useQuery("designation", () => getDesignationFullList(), {
    staleTime: 15000,
  });

  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search By Name / Mobile"
                  onChange={(e: any) => handleDoctorSearch(e, setSearch, navigate, viewType)}
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <Link
                  to={`/admin/doctors/table/${currentPage}`}
                  color="secondary"
                  title="List"
                  className={`default squared ${viewType === 'table' ? 'active' : ''}`}
                >
                  <i className=" icon icon-list xl "></i>
                </Link>
                <Link
                  to={`/admin/doctors/card/${currentPage}`}
                  color="secondary"
                  title="Grid"
                  className={`default squared ${viewType === 'card' ? 'active' : ''}`}
                >
                  <i className=" icon icon-menu-2 xl "></i>
                </Link>
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                    title="Add Doctor"
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="team-list-outer-wrap">
          <CardsWithTable
            key="doctorDataTable"
            data={doctData}
            paginationData={doctorData?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageAdminDoctor(pageNo, navigate, viewType)
            }
            tableTitle="Doctor Details"
            tableFields={doctorTableFields}
            Arraykeys={doctorTableKeys}
            viewType={viewType || "table"}
            toURL={"/admin/doctors-profile"}
            actions={["edit", "delete"]}
            isLoading={
              doctorLoading || (doctData?.length === 0 && doctorIsFetching)
            }
            error={doctorError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                doctorData
              )
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, doctorListRefetch)
            }
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add Doctor"
        className="add-doctor-modal"
      >
        <AddDoctor
          setIsOpen={setIsOpen}
          departmentList={departmentList}
          departmentLoading={departmentLoading}
          designationList={designationList}
          designationLoading={designationLoading}
          designationError={designationError}
          modalEditId={modalEditData?.id}
          refetch={doctorListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default DoctorsIndex;
