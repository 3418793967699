import { useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddDepartment from "./AddDepartment";
import { useQuery } from "react-query";
import { getDepartment } from "../../../../Api/AdminApis";
import {
  modalHandler,
  onEditClickHandler,
  deleteClickHandler,
} from "./AddDepartment/AddDepartmentMethods/AddDepartmentModalMethods";
import { handleSettingsDesignationDepartmentSearch } from "../Designation/SettingsDesignationMethods";

const DepartmentIndex = () => {
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: depData,
    error: departmentError,
    isLoading: departmentLoading,
    refetch: departmentListRefetch,
  }: any = useQuery(["dept", currentPage, searchQuery], () => getDepartment(currentPage, searchQuery), {
    keepPreviousData: true
  });
  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search By Name"
                  onChange={(e) => handleSettingsDesignationDepartmentSearch(e, setSearchQuery, setCurrentPage)}
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                    title="Add Department"
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="team-list-outer-wrap">
          <CardsWithTable
            data={depData?.data}
            tableTitle="Department Details"
            tableFields={["Id", " Department Name"]}
            Arraykeys={["id", "name"]}
            viewType='table'
            toURL={"/admin/department-profile"}
            actions={["edit", "delete"]}
            isLoading={departmentLoading}
            error={departmentError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                depData
              )
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, departmentListRefetch)
            }
            paginationData={depData?.meta}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={
          modalEditData?.length === 0
            ? "Add Department"
            : "Edit Department"
        }
      >
        <AddDepartment
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={departmentListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default DepartmentIndex;
