import { SidebarMenuItemType } from "../../../Type";

const LabSidebarMenuItems: SidebarMenuItemType[] = [
	{
		icon: <i className="icon svg-icon icon-dashboard"></i>,
		label: "Dashboard",
		path: "/lab/dashboard",
		// AllPaths: ['/staff/dashboard/appointment/upcoming', '/staff/dashboard/appointment/today', '/staff/dashboard/appointment/previous']
	},
	{
		icon: <i className="icon svg-icon icon-bookings"></i>,
		label: "Booking",
		path: "/lab/booking",
	},
	{
		icon: <i className="icon svg-icon icon-patient"></i>,
		label: "Patients",
		path: "/lab/Labmaster",
	},
	{
		icon: <i className="icon svg-icon icon-documents"></i>,
		label: "Lab Result",
		path: "/lab/labreports",
	},
	{
		icon: <i className="icon svg-icon icon-settings"></i>,
		label: "Settings",
		path: "/lab/settings/1",
	},
];

export { LabSidebarMenuItems };
