import React, { useEffect, useState } from "react";
import {
	Table,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Form,
	Input,
	InputGroup,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
} from "reactstrap";
import {
	fetchLabTests,
	getLaboratory,
	getLaboratoryResult,
} from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { api } from "../../../../Api/Api";
import { setValidationErrors } from "../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import LaboratoryTestResultList from "./LaboratoryTestResultList";
import { deleteConfirm } from "./AppoinmentMethods/AppoinmentSubmitMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../../../Components/Containers/AlertModal/DeleteAlertModal";
import CustomButton from "../../../../Components/CustomElements/FormElements/CustomButton";

export default function LaboratoryTestResultAdd(props: any) {
	const { appointment_id, doctor_id, patient_id } = props;
	const [selectedTest, setSelectedTest] = useState<any>("");
	const [tests, setTests] = useState<string[]>([]);
	const [selectedTestDetails, setSelectedTestDetails] = useState<any>({});
	const [result, setResult] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [modal, setModal] = useState(false);
	const [editTestId, setTestEditID] = useState<number | undefined>();
	const [resultModal, setResultModal] = useState(false);
	const [hasExistingResult, setHasExistingResult] = useState(false);
	const [resultError, setResultError] = useState("");
	const [deleteId, setDeleteId] = useState();
	const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deletedResultList,setdeletedResultList] = useState(false);
	const {
		data: labTests,

		refetch,
	} = useQuery(["labTests"], () => getLaboratory(1, ""), {
		refetchOnWindowFocus: false,
	});
	const [labTestsData, setLabTestsData] = useState<any[]>([]);

	const { data: labTestsResult } = useQuery(
		[`labTestsResult${appointment_id}`, resultModal],
		() => getLaboratoryResult(appointment_id)
	);

	useEffect(() => {
		const getLabTests = async () => {
			try {
				const response = await fetchLabTests(appointment_id);
				if (
					response &&
					response?.Labtest &&
					Array?.isArray(response?.Labtest)
				) {
					const labTestsData = response?.Labtest;

					const testNames = labTestsData?.map((test: any) => test);
					setTests(testNames);
					setLabTestsData(labTestsData);
				}
			} catch (error) {
				console.error("Failed to fetch lab tests", error);
			}
		};
		getLabTests();
		setSelectedTest("");
	}, [modal, appointment_id, isOpen]);

	const toggle = () => setModal(!modal);
	const toggleResultModal = () => setResultModal(!resultModal);
	const handleRowClick = (testName: string) => {
		setResultError("");
		const selectedTest = labTestsData.find(
			(test: any) => test.labMaster.name === testName
		);

		setSelectedTestDetails(selectedTest);
		const existingResult = labTestsResult?.find(
			(result: any) =>
				result?.lab_master?.id === selectedTest?.labMaster?.id
		);

		setHasExistingResult(!!existingResult);
		setResult(existingResult ? existingResult.test_result : "");
		setTestEditID(existingResult?.id);
		toggleResultModal();
	};

	const handleSubmit = (
		selectedTestId: number[],
		appointment_id: number,

		patient_id: number,
		setIsOpen: Function,
		refetch: Function
	) => {
		setIsLoading(true);
		api.post(
			"/Labtest",
			{
				lab_master_id: selectedTestId,
				appointment_id,

				patient_id,
			},
			true
		)
			.then(function (response: any) {
				toast.success("Tests Submitted Successfully");
				setModal(false);
				refetch();
				setSelectedTest("");
				setIsLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					toast.error(
						"Failed To Submit Tests: " + err.response.data.errors
					);
				} else {
					toast.error("Unexpected Error Occurred");
				}
			});
	};

	const handleDeleteTest = (id: number) => {
		api.delete(`/Labtest/${id}`, {}, true)
			.then(async function ([success, response]: any) {
				toast.success("Test Deleted Successfully");
				setIsOpen(!isOpen);
				setdeletedResultList(!deletedResultList)
			})
			.catch((err) => {
				toast.error("Unexpected Error Occurred");
			});
	};

	const handleResultSubmit = (
		editId: number | undefined,
		selectedTestId: number,
		lab_master_id: number,
		appointment_id: number,
		doctor_id: number,
		patient_id: number,
		setIsOpen: Function,
		refetch: Function,
		testResult: string
	) => {
		if (!testResult) {
			setResultError("Result is required");
			return;
		}

		if (testResult.length > 10) {
			setResultError("Result should not exceed 10 characters");
			return;
		}
		setIsLoading(true);
		const apiMethod = hasExistingResult ? "put" : "post";
		const apiUrl = hasExistingResult
			? `/Labresult/${editId}`
			: "/Labresult";
		api[apiMethod](
			apiUrl,
			{
				lab_test_id: selectedTestId,
				lab_master_id,
				appointment_id,
				doctor_id,
				patient_id,
				test_result: testResult,
				status: "success",
			},
			true
		)
			.then(function (response: any) {
				const toastMessage = hasExistingResult
					? "Tests Result Updated Successfully"
					: "Tests Result Submitted Successfully";
				toast.success(toastMessage);
				setIsOpen(false);
				refetch();
				setResultError("");
				toggleResultModal();
				setIsLoading(false);
			})
			.catch((err) => {
				if (err?.response?.data?.message) {
					toast.error(
						"Failed To Submit Tests: " + err.response.data.message
					);
				} else {
					toast.error("Unexpected Error Occurred");
				}
			});
	};

	return (
		<>
			{" "}
			<Col xxl={12}>
				<Card className="widget-card todays-appointment">
					<CardBody className="widget-inner-wrap">
						<div className="widget-head-outer">
							<div className="widget-heading">
								<small>Add</small>
								<h6>Laboratory Test</h6>
							</div>
							<div className="icon-container">
								<span>
									<Button
										color="none"
										outline
										class="sm prescription-add-btn"
										onClick={toggle}
									>
										<i className="icon icon-plus-squared xl"></i>
									</Button>
								</span>
							</div>
						</div>

						<div className="table-outer">
							<Table striped>
								<thead>
									<tr>
										<th>
											<b>Test Name</b>
										</th>
										<th className="text-end">
											<b>Action</b>
										</th>
									</tr>
								</thead>
								<tbody>
									{tests.map((test: any) => (
										<tr key={test.id}>
											<td>{test?.labMaster?.name}</td>
											<td className="text-end">
												<i
													className="xl icon icon-edit"
													title="Edit"
													onClick={() =>
														handleRowClick(
															test?.labMaster
																?.name
														)
													}
												></i>

												<i
													className="xl icon icon-delete"
													title='Delete'
													onClick={() =>
														deleteConfirm(
															test.id,
															setDeleteModal,
															setDeleteId
														)
													}
												></i>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			</Col>
			<ModalContainer
				isOpen={openDeleteModal}
				setIsopen={setDeleteModal}
				title=""
			>
				<DeleteAlertModal
					setModal={setDeleteModal}
					onDeleteClick={(id: number) => handleDeleteTest(id)}
					delete_id={deleteId}
				/>
			</ModalContainer>
			<div>
				<Modal isOpen={modal} toggle={toggle} className="custom-modal">
					<ModalHeader toggle={toggle}>
						<span className="sub-head">Add</span>New test
					</ModalHeader>
					<ModalBody>
						<div className="form-wrap">
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit(
										[selectedTest.id],
										appointment_id,

										patient_id,
										setIsOpen,
										refetch
									);
								}}
							>
								<Row className="gy-4">
									<Col md={6}>
										<Label>Test Name</Label>
										<Input
											type="select"
											placeholder="Test name"
											onChange={(e) => {
												const selectedTestId =
													e.target.value;
												const selectedTest =
													labTests?.data?.find(
														(test: any) =>
															test.id ===
															Number(
																selectedTestId
															)
													);
												setSelectedTest(selectedTest);
											}}
										>
											<option value="">
												Select Test
											</option>
											{labTests?.data
												?.filter(
													(test: any) =>
														!tests
															.map(
																(tst: any) =>
																	tst
																		?.labMaster
																		?.name
															)
															.includes(
																test?.name
															)
												)
												.map((test: any) => (
													<option
														key={test.id}
														value={test.id}
													>
														{test.name}
													</option>
												))}
										</Input>
									</Col>
									<Col md={6}>
										<Label>Unit</Label>
										<Input
											type="text"
											placeholder="Unit"
											value={selectedTest?.unit || ""}
											readOnly
										/>
									</Col>
									<Col md={6}>
										<Label>Range</Label>
										<Input
											type="text"
											placeholder="Range"
											value={selectedTest?.range || ""}
											readOnly
										/>
									</Col>
									<Col md={6}>
										<Label>Amount</Label>
										<Input
											type="text"
											placeholder="Amount"
											value={selectedTest?.amount || ""}
											readOnly
										/>
									</Col>

									<Col md={12}>
										<CustomButton
											label="Submit"
											isLoading={isLoading}
											className="submit-btn sm"
											disabled={
												selectedTest?.length === 0
											}
										/>
									</Col>
								</Row>
							</Form>
						</div>
					</ModalBody>
				</Modal>
			</div>
			<div>
				<Modal
					isOpen={resultModal}
					toggle={toggleResultModal}
					className="custom-modal"
				>
					<ModalHeader toggle={toggleResultModal}>
						<span className="sub-head">
							{hasExistingResult ? "Edit" : "Add"}
						</span>{" "}
						Result
					</ModalHeader>

					<ModalBody>
						<div className="form-wrap">
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									const testResult = result;
									handleResultSubmit(
										editTestId,
										selectedTestDetails.id,
										selectedTestDetails?.labMaster?.id,
										appointment_id,
										doctor_id,
										patient_id,
										setIsOpen,
										refetch,
										testResult
									);
								}}
							>

								<Row className="gy-4">
									<Col md={6}>
										<Label>Test Name</Label>
										<Input
											type="text"
											placeholder="Test name"
											value={
												selectedTestDetails?.labMaster
													?.name || ""
											}
											readOnly
										/>
									</Col>
									<Col md={6}>
										<Label>Unit</Label>
										<Input
											type="text"
											placeholder="Unit"
											value={
												selectedTestDetails?.labMaster
													?.unit || ""
											}
											readOnly
										/>
									</Col>

									<Col md={6}>
										<Label>Range</Label>
										<Input
											type="text"
											placeholder="Range"
											value={
												selectedTestDetails?.labMaster
													?.range || ""
											}
											readOnly
										/>
									</Col>
									<Col md={6}>
										<Label>Result</Label>
										<Input
											type="text"
											placeholder="Result"
											value={result}
											onChange={(e) =>
												setResult(e.target.value)
											}
										/>
										{resultError && (
											<small className="text-danger">
												{resultError}
											</small>
										)}
									</Col>
									<Col md={12}>
										<CustomButton
											label="Submit"
											isLoading={isLoading}

											className="submit-btn sm"
										/>
									</Col>
								</Row>
							</Form>
						</div>
					</ModalBody>
				</Modal>
			</div>
			{labTestsResult?.[0]?.test_result && (
				<LaboratoryTestResultList
					patient_id={patient_id}
					appointment_id={appointment_id}
					resultModal={resultModal}
					deletedResultList={deletedResultList}
				/>
			)}
		</>
	);
}
